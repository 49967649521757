import React, { useEffect, useState, useContext } from "react"
import { clubIDContext } from "../Admin";
import { useHistory, useParams } from "react-router-dom";
import { Link } from "react-router-dom"
import { Col, Modal, Button } from "react-bootstrap";
import axios from "axios";

// Multi language property
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import common_en from "../../translations/en/common.json"
import common_de from "../../translations/de/common.json"
import common_nl from "../../translations/nl/common.json"

import BasicContent from "../../components/Basic/Content/partikel/BasicContent"
import { CardWithoutHead, Card } from '../../components/Basic/Content/molekul/Card'
import { InputWithErrors, Input } from "../../components/Basic/Input/InputWithErrors";
import SelectWithErrors from "../../components/Basic/Select/SelectWithErrors";

// Prime React Property
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import "primereact/resources/themes/lara-light-indigo/theme.css";  //theme
import "primereact/resources/primereact.min.css";                  //core css
import "primeicons/primeicons.css";

// React Toast Property
import { toast, ToastContainer } from "react-toastify";

i18n
    .use(initReactI18next)
    .init({
        interpolation: { escapeValue: false },  // React already does escaping
        lng: 'en',                              // language to use
        resources: {
            en: {
                common: common_en
            },
            de: {
                common: common_de
            },
            nl: {
                common: common_nl
            }
        },
    });

const ChangeClub = () => {
    // Get User info from local storage
    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    // Get jsonwebtoken from browser storage
    const token = userInfo ? userInfo.token : null
    // Standard Headers Config
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    }

    const { t } = useTranslation('common')
    const history = useHistory()
    const { id } = useParams()
    const [isLoading, setIsLoading] = useState(false)
    const [errors, setErrors] = useState([])

    

    // Function to call toast after success
    const toastSuccess = () => {
        toast.success('Add New User Success !', {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 200,
            onClose: () => {
                history.push("/users/list");
            }
        })
    }

    // Start Group Function to confirm PrimeReact Confirmation Box
    const confirm2 = () => {
        confirmDialog({
            message: 'Do you want to delete this record?',
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept,
            reject
        });
    };
    const accept = () => {

    }
    const reject = () => {

    }
    // End Group Function to confirm PrimeReact Confirmation Box

    // Start Additional Variabel And Function
    const [clubs, setClubs] = useState([])
    const [club, setClub] = useState('')
    const [selectedClub, setSelectedClub] = useState('')
    const [originalClub, setOriginalClub] = useState('') 
    const [adminClub, setAdminClub] = useContext(clubIDContext)

    const onChange = (e) => {
        setClub(e.target.value)
    }

    const saveClub = () => {
        setAdminClub({...adminClub, spy: club})
        history.push("/club")
    }

    useEffect(() => {
        const getClub = async () => {
            const club = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/club?page=1&limit=1000&search=`, config)
            club.data.data.clubs.map(club => {
                if(club.club_id === adminClub.spy){
                    setSelectedClub(club.name)
                }
                if(club.club_id === adminClub.original){
                    setOriginalClub(club.name)
                }
            })
            setClubs(club.data.data.clubs)
        }
        getClub()
    }, [])

    return (
        <BasicContent
            title="Groups"
        >
            <ToastContainer />
            <Col sm={12}>
                <Card title="Change Club">
                    <Col sm={8}>
                        <div className="form-group row">
                            <label className="col-md-4" htmlFor="selectedClub">Your Original Club </label>
                            <Input
                                value={originalClub}
                                name="selectedClub"
                                className="col-md-5 mb-2"
                            />
                        </div>
                        <div className="form-group row">
                            <label className="col-md-4" htmlFor="selectedClub">Your Current Club </label>
                            <Input
                                value={selectedClub}
                                name="selectedClub"
                                className="col-md-5 mb-2"
                            />
                        </div>
                        <div className="form-group row">
                            <label htmlFor="club" className="col-md-4">Clubs</label>
                            <SelectWithErrors
                                name="club"
                                onChange={onChange}
                                value={club}
                                errors={errors}
                                className="col-md-5"
                                data={clubs}
                                keyPair={{ key: "club_id", val: "name" }}
                                intialSelect={{ key: "0", val: "--- Select Club ---" }}
                            />
                        </div>
                    </Col>
                    <Button onClick={saveClub} variant="success" size="md"><i className="fa fa-save mr-1"></i> Save</Button>
                </Card>
            </Col>
            <ConfirmDialog />
        </BasicContent>
    )
}

export default ChangeClub