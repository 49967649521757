import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";
import moment from 'moment'
import { Modal, Button } from "react-bootstrap";
import DatePicker from 'react-datepicker'
import { useDispatch } from "react-redux";
import { loginStatus } from "../../../actions/userActions";
import "react-datepicker/dist/react-datepicker.css";
import "./index.css"

const UserActivityList = () => {
  // Get User info from local storage
  const userInfo = JSON.parse(localStorage.getItem('userInfo')) 
  // Get jsonwebtoken from browser storage
  const token = userInfo ? userInfo.token : null
  // State for how many page on the table list
  const [pageNumber, setPageNumber] = useState([])
  // State to show which page of table we are now
  const [currentPage, setCurrentPage] = useState(1)
  // State for activate which page is active and will get blue background
  const [isActive, setIsActive] = useState({ ods_1: true })
  // Pagination setting will send to backend as url query parameter to get club data
  const [pagination, setPagination] = useState({
    search: '',
    page: 1,
    limit: 20
  })

  const { search, page, limit } = pagination

  const history = useHistory()
  const dispatch = useDispatch()

  // State as container or variable to receipt the club data from backend
  const [users, setUsers] = useState([])

  const [showModal, setShowModal] = useState(false);

  const [startDate, setStartDate] = useState(new Date())

  const [endDate, setEndDate] = useState(new Date())

  const [uid, setUid] = useState()

  // Config headers for send to backend
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
  useEffect(() => {
    dispatch(loginStatus())
  }, [])
  useEffect(() => {
    const getUsers = async () => {
      const users = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/auth/user?search=${search}&page=${page}&limit=${limit}`, config)
      setPageNumber(Array(Math.ceil(users.data.count / limit)).fill(0))
      setUsers(users.data.rows)
    }
    getUsers()
  }, [search, currentPage])

  const pageOnClick = (val) => {
    setCurrentPage(val)
    setPagination({ ...pagination, page: val })
    setIsActive({ [`ods_${val}`]: true })
  }

  // This function is for move forward when we click Next on pagination
  const onNextPage = () => {
    if (currentPage === pageNumber.length) {
      return
    }
    setCurrentPage(currentPage + 1)
    setPagination({ ...pagination, page: currentPage + 1 })
    setIsActive({ [`ods_${currentPage + 1}`]: true })
  }

  // This function is for move backforward when we click Previous on pagination
  const onPrevPage = () => {
    if (currentPage === 1) {
      return
    }
    setCurrentPage(currentPage - 1)
    setPagination({ ...pagination, page: currentPage - 1 })
    setIsActive({ [`ods_${currentPage - 1}`]: true })
  }

  const onChange = (e) => {
    setCurrentPage(1);
    setIsActive({ "ods_1": true })
    setPagination({ ...pagination, page: 1, search: e.target.value })
  }

  const handleCloseModal = () => {
    setShowModal(false);
  }

  const userActivity = async (id) => {
    setUid(id)
    setShowModal(true)
  }

  const trackUser = async () => {
    history.push(`/users/activity/list?uid=${uid}&startDate=${moment(startDate).format("yyyy-MM-DD")}&endDate=${moment(endDate).format("yyyy-MM-DD")}`)
  }

  return (
    <div className="container-fluid loaded" style={{ display: "block" }}>
      <div className="row">
        <div className="col-12">
          <div className="page-title-box">
            <div className="page-title-right">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Dashboard</Link>
                </li>
                <li className="breadcrumb-item">User Activity</li>
                <li className="breadcrumb-item active">Users</li>
              </ol>
            </div>
            <h4 className="page-title">Users List</h4>
          </div>
        </div>
      </div>
      <div className="form-messages"></div>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="app-search search-form float-right">
                <div className="form-group position-relative">
                  <input
                    type="text"
                    name="edit[q]"
                    className="form-control submittable"
                    onChange={onChange}
                    value={search}
                    placeholder="Search by name..."
                  />
                  <span className="fa fa-search search-icon"></span>
                </div>
                <input type="hidden" name="op" value="" />
              </div>
              <h4 className="header-title mb-3 mt-1">Users List</h4>
              <table id="tbl0" className="table">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Group</th>
                    <th width="36">Activity</th>
                  </tr>
                </thead>
                <tbody>
                  {users.map(user => (
                    <tr key={user.uid}>
                      <td>{user.name}</td>
                      <td>{user.email}</td>
                      <td>{user.permission_group.group ? user.permission_group.group : ""}</td>
                      <td>
                        <a
                          title="Edit"
                          style={{ cursor: 'pointer' }}
                          onClick={() => userActivity(user.uid)}
                        >
                          <i className="icon-button fa fa-border fa-info pencil-alt color-green-bg"></i>
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <ul className="pagination">
                <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
                  <a style={{ cursor: 'pointer' }} onClick={onPrevPage} className="page-link" tabIndex="-1">Previous</a>
                </li>
                {pageNumber.map((item, index) => (
                  <li key={index} className={`page-item ${isActive[`ods_${index + 1}`] ? "active" : ""}`}><a style={{ cursor: 'pointer' }} className="page-link" onClick={() => pageOnClick(index + 1)} >{index + 1}</a></li>
                ))}
                <li className={`page-item ${currentPage === pageNumber.length ? "disabled" : ""}`}>
                  <a style={{ cursor: 'pointer' }} onClick={onNextPage} className="page-link">Next</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>User Activity Tracking</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group row">
            <label className="col-4 col-form-label">Start Date:</label>
            <div className="col-8">
              <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-4 col-form-label">End Date:</label>
            <div className="col-8">
              <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn button button-cancel"
            data-dismiss="modal"
            onClick={handleCloseModal}
          >
            <i className="fa fa-fw fa-ban"></i> Cancel
          </button>
          <button
            type="button"
            className="btn button"
            onClick={trackUser}
          >
            <i className="fa fa-fw fa-floppy-o"></i> Track
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default UserActivityList;
