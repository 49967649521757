import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useParams, useHistory } from "react-router-dom";
import axios from "axios";

// TO DO Next
// -- Create React Select Component Based on React Select
// -- Filter React select team member based on member already in team

const EditClubTeam = () => {
  const { id } = useParams();

  const history = useHistory()

  const token = JSON.parse(localStorage.getItem("userInfo")).token

  const [teamMember, setTeamMember] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false)
  const [members, setMembers] = useState([]);
  const [newTeamMember, setNewTeamMember] = useState({
    team_id:id,
    uid: ''
  })

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };
 
  useEffect(() => {
    const getTeams = async () => {
      const team = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/club/team/member/${id}`, config);
      setMembers(team.data.clubMember)
      setTeamMember(team.data.clubTeamMember);
    };

    getTeams();
  }, [isUpdate]);

  const onChange = (e) => {
    setNewTeamMember({...newTeamMember, [e.target.name]: e.target.value})
  }

  const addTeam = async (e) => {
    e.preventDefault();
    const team = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/club/team/member`, newTeamMember, config);
    setNewTeamMember({
      team_id:id,
      uid: ''
    })
    setIsUpdate(!isUpdate)
  };

  const deleteTeam = async (e) => {
    e.preventDefault()
    if(window.confirm('Are you sure want to delete this team')){
      await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/v1/club/team/${id}`, config)
      history.push('/club')
    }
    setIsUpdate(!isUpdate)
  }

  const deleteTeamMember = async (uid, username) => {
    setNewTeamMember({ team_id:id, uid})
    if(window.confirm(`Are you sure want to remove ${username} from this team ?`)){
      const res = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/v1/club/team/member/${id}/${uid}`,config)
    }
    setNewTeamMember({ team_id:id, uid:''})
    setIsUpdate(!isUpdate)
  }
  return (
    <div className="container-fluid loaded" style={{ display: "block" }}>
      <div className="row">
        <div className="col-12">
          <div className="page-title-box">
            <div className="page-title-right">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Dashboard</Link>
                </li>
                <li className="breadcrumb-item active">Club</li>
              </ol>
            </div>
            <h4 className="page-title">Club</h4>
          </div>
        </div>
      </div>
      <div className="form-messages"></div>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <table id="tbl0" className="table">
                <thead>
                  <tr>
                    <th width="32"></th>
                    <th>Name</th>
                    <th>Email</th>
                    <th width="36"></th>
                  </tr>
                </thead>
                <tbody>
                  {teamMember.map((team) => (
                    <tr>
                      <td>
                        <img
                          src={process.env.REACT_APP_BACKEND_URL + "" + team.image}
                          alt="user-image"
                          style={{ width: "32px" }}
                        />
                      </td>
                      <td>{team.name}</td>
                      <td>{team.email}</td>
                      <td>
                        <button onClick={()=>deleteTeamMember(team.uid, team.name)} className="btn">
                          <i className="icon-button fa fa-fw fa-trash-o"></i>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <form
                name="form3"
                action="/en/club/team/3"
                method="post"
                className="form-horizontal"
              >
                <div className="form-group row">
                  <label className="col-4 col-form-label">User:</label>{" "}
                  <div className="col-8">
                    <select
                      className="custom-select select2 select2"
                      data-toggle="select2"
                      name="uid"
                      data-select2-id="1"
                      tabIndex="-1"
                      aria-hidden="true"
                      onChange={onChange}
                    >
                      <option value="0" selected="selected" data-select2-id="3">
                        Select player to add
                      </option>
                      {members.map(member => (
                        <option value={member.uid}>{member.name}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <input type="hidden" name="edit[team_id]" value="3" />
                <a
                  className="btn button button-cancel"
                  href="#!"
                  onClick={deleteTeam}
                >
                  <i className="fa fa-fw fa-trash-o"></i> Delete team
                </a>
                <a onClick={addTeam} className="btn button button-right">
                  <i className="fa fa-fw fa-plus"></i> Add
                </a>
                <input type="hidden" name="op" value="" />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditClubTeam;
