import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import axios from "axios";

const EditVideoTrackingServer = () => {
  const { id } = useParams();
  const history = useHistory();
  const token = JSON.parse(localStorage.getItem("userInfo")).token
  const [server, setServer] = useState({});

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  useEffect(() => {
    const getServer = async () => {
      const server = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/server/${id}`, config);
      setServer(server.data);
    };
    getServer();
  }, []);

  const onChange = (e) => {
    setServer({ ...server, [e.target.name]: e.target.value });
  };

  const changeServerActive = () => {
    setServer({ ...server, active: !server.active });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const updateServer = await axios.put(
      `${process.env.REACT_APP_BACKEND_URL}/api/v1/server/${id}`,
      server,
      config
    );
    history.push("/videotracking.admin/servers");
  };

  return (
    <div class="container-fluid loaded" style={{ display: "block" }}>
      <div class="row">
        <div class="col-12">
          <div class="page-title-box">
            <div class="page-title-right">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <Link to="/">Dashboard</Link>
                </li>
                <li class="breadcrumb-item">Video Tracking</li>
                <li class="breadcrumb-item active">Manage servers</li>
              </ol>
            </div>
            <h4 class="page-title">Manage servers</h4>
          </div>
        </div>
      </div>
      <div class="form-messages"></div>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <form onSubmit={onSubmit} method="post" class="form-horizontal">
                <fieldset>
                  <legend>Edit server</legend>
                  <div class="form-group row">
                    <label class="col-4 col-form-label">Active:</label>{" "}
                    <div class="col-8">
                      <input
                        class="form-control"
                        type="checkbox"
                        name="active"
                        onClick={changeServerActive}
                        value={server.active}
                        checked={server.active}
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-4 col-form-label">Name:</label>{" "}
                    <div class="col-8">
                      <input
                        class="form-control"
                        maxlength="64"
                        name="name"
                        onChange={onChange}
                        type="text"
                        value={server.name}
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-4 col-form-label">IP:</label>{" "}
                    <div class="col-8">
                      <input
                        class="form-control"
                        maxlength="64"
                        onChange={onChange}
                        name="ip"
                        type="text"
                        value={server.ip}
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-4 col-form-label">Role:</label>{" "}
                    <div class="col-8">
                      <select
                        onChange={onChange}
                        value={server.role}
                        class="custom-select select2"
                        name="role"
                      >
                        <option value="0">Live server</option>
                        <option value="1">Internal use</option>
                      </select>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-4 col-form-label">Weight:</label>{" "}
                    <div class="col-8">
                      <select
                        onChange={onChange}
                        value={server.weight}
                        class="custom-select select2"
                        name="weight"
                      >
                        <option value="-10">-10</option>
                        <option value="-9">-9</option>
                        <option value="-8">-8</option>
                        <option value="-7">-7</option>
                        <option value="-6">-6</option>
                        <option value="-5">-5</option>
                        <option value="-4">-4</option>
                        <option value="-3">-3</option>
                        <option value="-2">-2</option>
                        <option value="-1">-1</option>
                        <option value="0" selected="selected"></option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                      </select>
                    </div>
                  </div>
                  <input type="hidden" name="edit[server_id]" value="2" />
                </fieldset>
                <button type="submit" class="btn button">
                  <i class="fa fa-fw fa-floppy-o"></i> Save
                </button>
                <Link to="/videotracking.admin/servers" class="button-cancel">
                  <i class="fa fa-fw fa-step-backward"></i>&nbsp;Cancel
                </Link>
                <input type="hidden" name="op" value="" />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditVideoTrackingServer;
