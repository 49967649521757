import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom';
import useOutsideClick from '../../lib/clickOutsideComponentDetector';
import { LangContext } from '../../app';
import axios from 'axios';

const Header = ({logoutHandler, userInfo}) => {
  const [lango, setLango] = useContext(LangContext)
  
  const [openLang, setOpenLang] = useState(false);
  const [openUser, setOpenUser] = useState(false)
  const [user, setUser] = useState({})

  const uid = userInfo !== null ? userInfo.user_id : ''
  
  useEffect(() => {
    const getUser = async() => {
      const user = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/auth/${uid}`)
      setUser(user.data)
    }
    getUser()
  }, [])

  const openLangMenu = (e) => {
    e.preventDefault()
    setOpenLang(!openLang)
  }

  const openUserProfile = (e) => {
    e.preventDefault();
    setOpenUser(!openUser);
  }

  const chooseLanguage = (e, lang) => {
    e.preventDefault()
    setLango({title: e.target.innerText, flag: lang})
    setOpenLang(false)
  }

  const handleOutsideClick = () => {
    setOpenLang(false);
  }

  const handleUserProfileOutsideClick = () => {
    setOpenUser(false);
  }

  const toggleSidebar = () => {
    if(document.body.classList.contains('sidebar-enable')){
      document.body.classList.remove('sidebar-enable')
    }else{
      document.body.classList.add('sidebar-enable')
    }
  }

  const ref = useOutsideClick(handleOutsideClick)
  const ref2 = useOutsideClick(handleUserProfileOutsideClick)
  return (
    <div className="navbar-custom">
      <ul className="list-unstyled topbar-right-menu float-right mb-0">
        {/* <!-- search --> */}
        <li className="dropdown topbar-dropdown d-lg-none">
          <a
            className="nav-link dropdown-toggle arrow-none"
            data-toggle="dropdown"
            href="pages-profile.html#"
            role="button"
            aria-haspopup="false"
            aria-expanded="false"
          >
            <i className="fa fa-search search-icon align-middle"></i>
          </a>
          <div className="dropdown-menu dropdown-menu-animated dropdown-lg p-0">
            <form className="p-3">
              <input
                type="text"
                className="form-control"
                placeholder="Search..."
                aria-label=""
              />
            </form>
          </div>
        </li>

        {/* <!-- language menu --> */}
        <li className="dropdown topbar-dropdown">
          <a
            className="nav-link dropdown-toggle arrow-none"
            data-toggle="dropdown"
            href="#!"
            role="button"
            ref={ref}
            onClick={openLangMenu}
            aria-haspopup="false"
            aria-expanded="false"
          >
            <img
              src={`/images/flags/${lango.flag}.png`}
              alt="user-image"
              className="mr-0 mr-sm-1"
              height="12"
            />
            <span className="align-center d-none d-sm-inline-block">
              {lango.title}
            </span>{" "}
            <i className="fa fa-angle-down d-none d-sm-inline-block align-middle"></i>
          </a>
          <div className={`dropdown-menu dropdown-menu-right dropdown-menu-animated topbar-dropdown-menu ${openLang ? 'show' : ''}`}>
            {/* <!-- item--> */}
            <a
              href="#!"
              onClick={(e) => chooseLanguage(e, 'en')}
              className="dropdown-item notify-item"
            >
              <img
                src="/images/flags/en.png"
                alt="user-image"
                className="mr-1"
                height="12"
              />{" "}
              <span className="align-middle">English</span>
            </a>
            {/* <!-- item--> */}
            <a
              href="#!"
              onClick={(e) => chooseLanguage(e, 'de')}
              className="dropdown-item notify-item"
            >
              <img
                src="/images/flags/de.png"
                alt="user-image"
                className="mr-1"
                height="12"
              />{" "}
              <span className="align-middle">Deutsch</span>
            </a>
            {/* <!-- item--> */}
            <a
              href="#!"
              onClick={e => chooseLanguage(e, 'nl')}
              className="dropdown-item notify-item"
            >
              <img
                src="/images/flags/nl.png"
                alt="user-image"
                className="mr-1"
                height="12"
              />
              <span className="align-middle">Nederlands</span>
            </a>
          </div>
        </li>

        {/* <!-- user menu --> */}
        <li ref={ref2} className={`dropdown notification-list ${openUser ? 'show' : ''}`}>
          <a
            className="nav-link dropdown-toggle nav-user arrow-none mr-0"
            data-toggle="dropdown"
            href="#!"
            onClick={openUserProfile}
            role="button"
            aria-haspopup="false"
            aria-expanded="false"
          >
            <span className="account-user-avatar">
              <img
                src={`${process.env.REACT_APP_BACKEND_URL}${user.image}`}
                alt="user-image"
                className="rounded-circle"
              />
            </span>
            <span>
              <span className="account-user-name">{user.name}</span>
              <span className="account-position">User</span>
            </span>
          </a>
          <div className={`dropdown-menu dropdown-menu-right dropdown-menu-animated topbar-dropdown-menu profile-dropdown ${openUser ? 'show' : ''}`}>
            {/* <!-- item--> */}
            <div className="dropdown-header noti-title">
              <h6 className="text-overflow m-0">Welcome !</h6>
            </div>
            {/* <!-- item--> */}
            <Link
              to="/users/account"
              className="dropdown-item notify-item"
            >
              <i className="fa fa-user-circle mr-1"></i>
              <span className="text-capitalize">My account</span>
            </Link>
            {/* <!-- item--> */}
            <Link
              to="/users/settings"
              className="dropdown-item notify-item"
            >
              <i className="fa fa-cog mr-1"></i>
              <span className="text-capitalize">Settings</span>
            </Link>
            {/* <!-- item--> */}
            <a
              href="#!"
              onClick={logoutHandler}
              className="dropdown-item notify-item"
            >
              <i className="fa fa-sign-out mr-1"></i>
              <span>Logout</span>
            </a>
          </div>
        </li>
      </ul>
      <button onClick={toggleSidebar} className="button-menu-mobile open-left disable-btn">
        <i className="fa fa-bars"></i>
      </button>
      <div className="app-search dropdown d-none d-lg-block">
        <form>
          <div className="input-group">
            <input
              type="text"
              className="form-control dropdown-toggle"
              placeholder="Search..."
              id="top-search"
            />
            <span className="fa fa-search search-icon"></span>
            <div className="input-group-append">
              <button className="btn btn-primary" type="submit">
                Search
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default Header