import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";
import { useDispatch } from "react-redux";
import { loginStatus } from "../../../actions/userActions";


const EditTeam = () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo")) 
  const token = userInfo ? userInfo.token : null;
  const history = useHistory();
  const dispatch = useDispatch()
  const { id } = useParams()
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const [clubs, setClubs] = useState([])
  const [team, setTeam] = useState({
    club_id: "0",
    name: "",
  });
  const [errors, setErrors] = useState({ club_id: null, name: null });

  useEffect(() => {
    dispatch(loginStatus())
  },[])
  
  useEffect(() => {
    const getData = async () => {
      const data = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/club?search=&page=1&limit=1000`, config);
      setClubs(data.data.data.clubs)
    };
    const getTeamById = async() => {
      const team = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/club/team/${id}`)
      setTeam(team.data)
    }
    getData();
    getTeamById()
  }, []);
  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const save = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/v1/club/team/${id}`, team, config);

      history.push("/manageclub/teams");
    } catch (error) {
      setErrors(error.response.data);
    }
  };
  
  const onChange = (e) => {
    setErrors({ ...errors, [e.target.name]: null });
    setTeam({ ...team, [e.target.name]: e.target.value });
  };
  return (
    <div className="container-fluid loaded" style={{ display: "block" }}>
      <div className="row">
        <div className="col-12">
          <div className="page-title-box">
            <div className="page-title-right">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Dashboard</Link>
                </li>
                <li className="breadcrumb-item">Manage Club</li>
                <li className="breadcrumb-item active">Teams</li>
              </ol>
            </div>
            <h4 className="page-title">Teams</h4>
          </div>
        </div>
      </div>

      <div className="form-messages"></div>
      <div className="row" data-select2-id="10">
        <div className="col-12" data-select2-id="9">
          <div className="card" data-select2-id="8">
            <div className="card-body" data-select2-id="7">
              <form
                onSubmit={onSubmit}
              >
                <fieldset>
                  <legend>Edit</legend>
                  <div className="form-group row">
                    <label className="col-4 col-form-label">Club:</label>
                    <div className="col-8">
                      <select
                        className="custom-select"
                        name="club_id"
                        onChange={onChange}
                        value={team.club_id}
                        style={{ border: errors.club_id ? "solid 1px red" : "" }}
                      >
                        <option
                          value="0"
                        > --- Select Club ---</option>
                        {clubs.map(club => (
                          <option
                            key={club.club_id}
                            value={club.club_id}
                          >
                            {club.name}
                          </option>
                        ))}
                      </select>
                      {errors.club_id && <p style={{ color: "red", marginTop: "5px", fontWeight: 600 }}><i>{errors.club_id}</i></p>}
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-4 col-form-label">Name:</label>{" "}
                    <div className="col-8">
                      <input
                        className="form-control"
                        maxLength="64"
                        name="name"
                        onChange={onChange}
                        style={{ border: errors.name ? "solid 1px red" : "" }}
                        type="text"
                        value={team.name}
                      />
                      {errors.name && <p style={{ color: "red", marginTop: "5px", fontWeight: 600 }}><i>{errors.name}</i></p>}
                    </div>
                  </div>
                </fieldset>
                <button type="submit" className="btn button">
                  <i className="fa fa-fw fa-floppy-o"></i> Save
                </button>
                <Link to="/manageclub/teams" className="button-cancel">
                  <i className="fa fa-fw fa-step-backward"></i>&nbsp;Cancel
                </Link>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditTeam;
