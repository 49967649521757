export const view_club = "View club";
export const manage_club = "Manage club"
export const manage_club_teams = "Manage club teams"
export const admin_locale = "ADMIN_LOCALE"
export const manage_scenarios = "Manage scenarios"
export const saiva_lite_scenarios = "SAIVA Lite scenarios"
export const dev_tools_scenarios = "DevTool for scenarios"
export const access_admin = "ACCESS_ADMIN"
export const access_config = "ACCESS_CONFIG"
export const access_log = "ACCESS_LOG"
export const admin_user = "ADMIN_USERS"
export const admin_permission = "ADMIN_PERMISSIONS"
export const access_app_settings = "ACCESS_APP_SETTINGS"
export const allow_developer_mode = "Allow Developer mode"
export const manage_video_tracking = "Manage video tracking"
export const use_video_tracking = "Use VideoTracking"
export const saica = "saica"
export const change_club = "change_club"
export const special_permission = "special_permission"
export const upload_scenario = "upload_scenario"