import React, { useState, useEffect, useRef, useContext } from "react";
import { clubIDContext } from "../Admin";
import { useDispatch } from "react-redux";
import axios from "axios";
import { Link } from "react-router-dom";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import { Col } from 'react-bootstrap'
import i18n from "i18next";
import { ProgressBar } from 'react-bootstrap'
import { useTranslation, initReactI18next } from "react-i18next";
import common_en from "../../translations/en/common.json"
import common_de from "../../translations/de/common.json"
import common_nl from "../../translations/nl/common.json"
import { authFail, loginStatus } from "../../actions/userActions";
import { toast, ToastContainer } from "react-toastify";
import { Modal } from "react-bootstrap";
import BasicContent from "../../components/Basic/Content/partikel/BasicContent";
import { Card } from "../../components/Basic/Content/molekul/Card";
import dateFormat from "../../customFunction/dateFormat";
import Tooltip from '@mui/material/Tooltip';
import './scenario.css'

i18n
  .use(initReactI18next)
  .init({
    interpolation: { escapeValue: false },  // React already does escaping
    lng: 'en',                              // language to use
    resources: {
      en: {
        common: common_en
      },
      de: {
        common: common_de
      },
      nl: {
        common: common_nl
      }
    },
  });

const Scenarios = () => {
  const { t } = useTranslation('common')
  const userInfo = JSON.parse(localStorage.getItem("userInfo"))
  const uid = userInfo !== null ? userInfo.user_id : null;
  const token = userInfo !== null ? userInfo.token : null
  const accessLevel = userInfo !== null ? userInfo.access_level : null
  const [saicaStatus, setSaicaStatus] = useState(false)
  const inputRef = useRef(null)
  const dispatch = useDispatch()
  const [adminClub, setAdminClub] = useContext(clubIDContext)

  const config = {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  }
  const folderInit = { f_1: false, f_30: false, f_170: false };
  const [parentFolder, setParentFolder] = useState([]);
  const [coachFolder, setCoachFolder] = useState([])
  const [parentId, setParentId] = useState(0)
  const [listCoach, setListCoach] = useState([])
  const [childFolder, setChildFolder] = useState({
    premade: [],
    user: [],
    saiva_lite: [],
  });
  const [activeFolder, setActiveFolder] = useState(folderInit);
  const [scenarios, setScenarios] = useState([]);
  const [isAddFolder, setIsAddFolder] = useState(false)
  const [folderName, setFolderName] = useState('')
  const [activeParentFolder, setActiveParentFolder] = useState("")
  const [showAddFolderModal, setShowAddFolderModal] = useState(false)
  const [showEditFolderButton, setShowEditFolderButton] = useState(false)
  const [hasSpecialPermission, setHasSpecialPermission] = useState(false)
  const [newFolderName, setNewFolderName] = useState('')
  const [currentFolderName, setCurrentFolderName] = useState('')
  const [isUpdate, setIsUpdate] = useState(false)
  const [error, setError] = useState()
  const [listParentFolder, setListParentFolder] = useState({})
  const [activeChildFolder, setActiveChildFolder] = useState({})
  const [prevParentFolder, setPrevParentFolder] = useState('')
  const [prevChildFolder, setPrevChildFolder] = useState('')
  const [folderID, setFolderID] = useState(0)
  const [modalStatus, setModalStatus] = useState('Add')
  const [deleteConfirmation, setDeleteConfirmation] = useState(false)
  const [parentFolderName, setParentFolderName] = useState("")
  const [coachId, setCoachId] = useState(0)
  const folderNeedPermission = ['SAIVA Lite', 'Coach Training']
  const [isParentFolder, setIsParentFolder] = useState(true)
  const [showErrorMessage, setShowErrorMessage] = useState(false)

  const createLog = async (page) => {
    const log = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/log`, { userId: uid, name: userInfo.user_name, page })
  }

  useEffect(() => {
    createLog('Scenario Main Page')
  }, [])

  useEffect(() => {
    const getUserPermission = async () => {
      const permission = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/permission/${uid}`, config)

      if (permission.data !== null) {
        setHasSpecialPermission(true)
      }
    }

    getUserPermission()
  }, [])

  useEffect(() => {
    const getParentFolder = async () => {
      try {
        const folders = await axios
          .get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/scenarios/folder/parent/${uid}?coachId=${coachId}&parentId=${parentId}&st=${adminClub.original === adminClub.spy ? '1' : '0'}&clubId=${adminClub.spy}`, config);
        if (accessLevel === 0 || adminClub.original !== adminClub.spy) {
          setCoachFolder(folders.data)
        } else {
          const tmp = {}
          folders.data.map((folder, index) => {
            tmp[`id_${index}`] = false
          })
          setListParentFolder(tmp)

          setParentFolder(folders.data);
        }
      } catch (error) {
        if (error.response.status === 401) {
          dispatch(loginStatus())
        }
      }
    };
    getParentFolder();
  }, [isUpdate]);

  const getChildFolder = async (idx, e) => {
    e.preventDefault();
    setIsParentFolder(true)
    const id = e.target.name;

    setCurrentFolderName(e.target.getAttribute('tag'))

    setListParentFolder({ ...listParentFolder, [prevParentFolder]: false, [`id_${idx}`]: true })
    setFolderID(id)

    setShowEditFolderButton(false)
    if (folderName === id) {
      setIsAddFolder(!isAddFolder)
    } else {
      setIsAddFolder(true)
    }

    createLog(`Click On Scenario ${e.target.getAttribute('tag')} Main folder`)
    if (id != 1) {
      getScenariosByFolderId(id, 'parent', e);
    } else {
      setScenarios([])
    }
    setFolderName(id)
    setActiveFolder({ ...folderInit, [`f_${id}`]: !activeFolder[`f_${id}`] });
    setActiveParentFolder(id)
    setParentFolderName(parentFolder.find(folder => { return folder.folder_id == id }).name)
    setPrevParentFolder(`id_${idx}`)

  };

  const getScenariosByFolderId = async (id, folder, e) => {
    e.preventDefault()
    setFolderID(id)


    if (folder === 'child') {
      setListParentFolder({ ...listParentFolder, [prevParentFolder]: false })
      setShowEditFolderButton(true)
      setCurrentFolderName(e.target.name)
      setIsParentFolder(false)
    }
    setChildFolder({})
    setActiveChildFolder({ ...activeChildFolder, [`sub_f_${prevChildFolder}`]: false, [`sub_f_${id}`]: true })
    createLog(`Click On Scenario ${e.target.name} Sub folder`)
    const scenarios = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/scenarios/folder/${id}`, config);
    console.log(scenarios.data)
    setScenarios(scenarios.data.scenarios);
    setPrevChildFolder(id)
  };

  const onChange = async (e) => {
    setError('')
    setNewFolderName(e.target.value)
  }

  const addNewFolder = async () => {
    const data = {
      parent_id: folderName,
      uid,
      name: newFolderName
    }

    try {
      if (modalStatus === 'Add') {
        const folder = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/scenarios/folder`, data, config)
        toast.success("Add New Folder Success !", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1500
        });
      }
      else {
        const folder = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/v1/scenarios/folder/${folderID}`, data, config)
        toast.success("Update Folder Success !", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1500
        });
      }

      setNewFolderName('')
      setIsUpdate(!isUpdate)
      setShowAddFolderModal(false)
    } catch (error) {
      if (error.response.status === 422) {
        setError(error.response.data.msg)
      }
    }
  }

  const cancelAddNewFolder = async () => {
    setError('')
    setNewFolderName('')
    setShowAddFolderModal(false)
  }

  const showModal = async (status, e) => {
    e.preventDefault()
    if (status === 'edit') {
      setNewFolderName(currentFolderName)
      setModalStatus('Edit')
    } else {
      setModalStatus('Add')
    }
    setShowAddFolderModal(true)
  }

  const deleteFolder = async () => {
    const folder = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/v1/scenarios/folder/${folderID}`, config)
    setDeleteConfirmation(false)
    setScenarios([])
    setIsUpdate(!isUpdate)
  }

  const deleteFolderConfirmation = () => {
    setDeleteConfirmation(true)
  }

  const getCoachFolder = async (id, e) => {
    e.preventDefault()
    setParentId(0)
    setCoachId(id)
    setIsUpdate(!isUpdate)
  }

  const getCoachChildFolder = async (id, e) => {
    e.preventDefault()
    setParentId(id)
    getScenariosByFolderId(id, 'parent', e)
    setIsUpdate(!isUpdate)
  }

  const generateSaica = async (e, id) => {
    e.preventDefault()

    const scenario = scenarios.find(item => {
      return item.id === id
    })

    const saicaData = {
      name: scenario.name,
      fileName: 'generate',
      scenario_id: id,
      scenarioType: scenario.type,
      source: scenario.source
    }
    const saica = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/scenarios/saica`, saicaData, config)

    setSaicaStatus(true)

  }

  function Capitalize(words) {
    return words.charAt(0).toUpperCase() + words.slice(1)
  }

  Fancybox.bind("[data-fancybox]", {});

  console.log(parentFolderName)

  const [msg, setMsg] = useState({ status: 'ss', message: '' })

  const getErrorMessage = (e, id) => {
    e.preventDefault()
    console.log(id)
    const tt = scenarios.find(sc => {
      return sc.id === id
    })
    console.log(tt)
    setMsg({ status: JSON.parse(tt.result).status, message: JSON.parse(tt.result).message, })
    setShowErrorMessage(true)
  }

  return (
    <>
      <BasicContent
        breadcrumb={[
          { link: '/', name: t('ADMIN_HOME') },
          { link: '/scenarios', name: t('Scenarios') }
        ]}
        title={t('Add scenario')}
      >
        <ToastContainer />
        <Col sm={3}>
          <Card title={t('Folders')}>
            <Link
              to="/scenarios"
              className="font-weight-bold"
            >
              <i
                className="fa fa-lg fa-folder-open folder"
                aria-hidden="true"
              ></i>
              {accessLevel === 0 || adminClub.original !== adminClub.spy ? 'Coach\'s Name' : t('Rootfolder')}
            </Link>
            <br />
            <ul style={{ listStyleType: "none" }}>
              {(accessLevel === 0 || (adminClub.original !== adminClub.spy)) && coachFolder &&
                coachFolder.map(coach => (
                  <li>
                    <a
                      onClick={(e) => getCoachFolder(coach.uid, e)}
                      href="">
                      <i className="fa fa-lg fa-folder folder"></i>
                      {coach.coach}
                    </a>
                    <ul
                      style={{
                        listStyleType: "none",
                      }}
                    >
                      {coach.folder.length > 0 &&
                        coach.folder.map((fr, index) => (
                          <li key={index}>
                            <a
                              name={fr.name}
                              onClick={(e) =>
                                getCoachChildFolder(fr.folder_id, e)
                              }
                              className={activeChildFolder[`sub_f_${fr.folder_id}`] ? 'font-weight-bold' : ''}
                              href=""
                            >
                              <i
                                className="fa fa-lg fa-folder folder"
                                aria-hidden="true"
                              ></i>
                              {fr.name}
                            </a>
                            <ul
                              style={{
                                listStyleType: "none",
                              }}
                            >
                              {fr.folder.length > 0 &&
                                fr.folder.map((f, index) => (
                                  <li key={index}>
                                    <a
                                      name={f.name}
                                      onClick={(e) =>
                                        getScenariosByFolderId(f.folder_id, 'child', e)
                                      }
                                      className={activeChildFolder[`sub_f_${f.folder_id}`] ? 'font-weight-bold' : ''}
                                      href=""
                                    >
                                      <i
                                        className="fa fa-lg fa-folder folder"
                                        aria-hidden="true"
                                      ></i>
                                      {f.name}
                                    </a>
                                  </li>
                                ))}
                            </ul>
                          </li>
                        ))}
                    </ul>
                  </li>
                ))
              }
              {accessLevel !== 0 && parentFolder &&
                parentFolder.map((folder, index) => (
                  <li key={folder.folder_id}>
                    <a
                      name={folder.folder_id}
                      tag={folder.name}
                      href="#"
                      onClick={(e) => getChildFolder(index, e)}
                      className={listParentFolder[`id_${index}`] ? 'font-weight-bold' : ''}
                    >
                      <i
                        className="fa fa-lg fa-folder folder"
                        aria-hidden="true"
                      ></i>
                      {folder.name}
                    </a>
                    <ul
                      style={{
                        listStyleType: "none",
                        display: activeFolder[`f_${folder.folder_id}`]
                          ? "block"
                          : "none",
                      }}
                    >
                      {folder.sub.length > 0 &&
                        folder.sub.map((fr, index) => (
                          <li key={index}>
                            <a
                              name={fr.name}
                              onClick={(e) =>
                                getScenariosByFolderId(fr.folder_id, 'child', e)
                              }
                              className={activeChildFolder[`sub_f_${fr.folder_id}`] ? 'font-weight-bold' : ''}
                              href=""
                            >
                              <i
                                className="fa fa-lg fa-folder folder"
                                aria-hidden="true"
                              ></i>
                              {fr.name}
                            </a>
                          </li>
                        ))}
                    </ul>
                  </li>
                ))}
            </ul>

            {
              isParentFolder ? folderNeedPermission.includes(parentFolderName) ? hasSpecialPermission ? accessLevel !== 0 && <div className="mt-3">
                <a href="#" onClick={(e) => showModal('add', e)} className="button btn-sm">
                  <i className="fa fa-fw fa-plus"></i>&nbsp;Add folder
                </a>
              </div> : "" : accessLevel !== 0 && isAddFolder && <div className="mt-3">
                <a href="#" onClick={(e) => showModal('add', e)} className="button btn-sm">
                  <i className="fa fa-fw fa-plus"></i>&nbsp;Add folder
                </a>
              </div>
                : ""
            }
            {
              folderNeedPermission.includes(parentFolderName) ? hasSpecialPermission ? accessLevel !== 0 && showEditFolderButton && <a href="#" onClick={(e) => showModal('edit', e)} className="button btn-sm color-orange-bg">
                <i className="fa fa-fw fa-pencil pencil-alt"></i>&nbsp;Edit folder
              </a> : "" : accessLevel !== 0 && showEditFolderButton && <a href="#" onClick={(e) => showModal('edit', e)} className="button btn-sm color-orange-bg">
                <i className="fa fa-fw fa-pencil pencil-alt"></i>&nbsp;Edit folder
              </a>
            }
            {
              isParentFolder ? "" : folderNeedPermission.includes(parentFolderName) ? hasSpecialPermission ? accessLevel !== 0 &&
                <a href="#" onClick={deleteFolderConfirmation} className="button btn-sm button-cancel">
                  <i className="fa fa-fw fa-ban"></i>&nbsp;Delete folder
                </a>
                : ""
                :
                <a href="#" onClick={deleteFolderConfirmation} className="button btn-sm button-cancel">
                  <i className="fa fa-fw fa-ban"></i>&nbsp;Delete folder
                </a>
            }
          </Card>
        </Col>
        <Col sm={9}>
          <Card title={t('Scenarios')}>
            <table id="tbl0" className="table">
              <thead>
                <tr>
                  <th>Name</th>
                  {/* <th width="36">Preview</th> */}
                  <th width="150">Date</th>
                  {/* <th width="36">
                    <i className="fa fa-lg fa-star" aria-hidden="true"></i>
                  </th> */}
                  <th>Version</th>
                  <th>Scenario Type</th>
                  <th>Source</th>
                  <th>Positional Data</th>
                  <th>AI Coach Data</th>
                  {accessLevel !== 0 && <th width="36">Edit</th>}
                </tr>
              </thead>
              <tbody>
                {scenarios.length > 0 &&
                  scenarios.map((sc, index) => (
                    <tr key={index}>
                      <td>
                        {accessLevel !== 0 ? <Link to={`/scenarios/edit/${sc.id}`}>
                          {sc.name}
                        </Link> : sc.name}
                      </td>
                      {/* <td>
                        <a
                          data-fancybox=""
                          data-type="iframe"
                          href={`${process.env.REACT_APP_BACKEND_URL}/api/v1/scenarios/result/${sc.id}`}
                          title="Overview"
                        >
                          <i className="icon-button fa fa-border fa-image color-green-bg"></i>
                        </a>
                      </td> */}
                      <td className="text-muted" style={{ overflow: "none" }}>
                        {dateFormat(sc.datedt)}
                      </td>
                      <td>{sc.version === null ? '' : sc.version === 'legacy' ? 'Legacy' : `Composite v${sc.version}`}</td>
                      <td>
                        {sc.type === null ? 'Training' : Capitalize(sc.type)}
                      </td>
                      <td>{sc.source}</td>
                      <td className={sc.positionalData}>
                        {sc.positionalData
                          ?
                          sc.positionalData === 'processing'
                            ?
                            <span className="badge badge-primary">{Capitalize(sc.positionalData) + "..."}</span>
                            :
                            sc.positionalData === 'error'
                              ?
                              <a href="#" onClick={(e) => getErrorMessage(e, sc.id)}><span className="badge badge-danger">{Capitalize(sc.positionalData)}</span></a>
                              :
                              sc.positionalData === 'onvtserver'
                                ?
                                <span className="badge badge-warning">On VT Server</span>
                                :
                                <span className="badge badge-success">{Capitalize(sc.positionalData)}</span>
                          :
                          'N/A'}
                      </td>
                      <td className={`${sc.aiCoachData} text-center`}>
                        {sc.aiCoachData
                          ?
                          sc.aiCoachData === 'processing'
                            ?
                            <span className="badge badge-primary">{Capitalize(sc.aiCoachData) + "..."}</span>
                            :
                            sc.aiCoachData === 'error'
                              ?
                              <a href="#" onClick={(e) => getErrorMessage(e, sc.id)}><span className="badge badge-danger">{Capitalize(sc.aiCoachData)}</span></a>
                              :
                              sc.aiCoachData === 'generate'
                                ?
                                saicaStatus === false ? <a onClick={(e) => generateSaica(e, sc.id)} href="#" style={{ fontSize: '18px' }} className="text-center">
                                  <Tooltip title="Please generate saica file">
                                    <i className="fa fa-question-circle"></i>
                                  </Tooltip> 
                                </a> : <span className="badge badge-primary">Processing...</span>
                                :
                                <span className="badge badge-success">{Capitalize(sc.aiCoachData)}</span>
                          :
                          'N/A'}
                      </td>

                      {/* <td style={{ textAlign: "center", color: "#dd0" }}></td> */}
                      {folderNeedPermission.includes(parentFolderName) ? hasSpecialPermission ?  accessLevel !== 0 && <td>
                        <Link
                          to={`/scenarios/edit/${sc.id}`}
                          title="Edit"
                        >
                          <i className="icon-button fa fa-border fa-pencil pencil-alt color-green-bg"></i>
                        </Link>
                      </td> : 'NA' : <td>
                        <Link
                          to={`/scenarios/edit/${sc.id}`}
                          title="Edit"
                        >
                          <i className="icon-button fa fa-border fa-pencil pencil-alt color-green-bg"></i>
                        </Link>
                      </td>}
                    </tr>
                  ))}
              </tbody>
            </table>
            {folderNeedPermission.includes(parentFolderName) ? hasSpecialPermission ?  accessLevel !== 0 && <Link
              to={`/scenarios/add/${folderID}`}
              className="btn button"
            >
              <i className="fa fa-fw fa-plus"></i>{t('Add scenario')}
            </Link> : '' : <Link
              to={`/scenarios/add/${folderID}`}
              className="btn button"
            >
              <i className="fa fa-fw fa-plus"></i>{t('Add scenario')}
            </Link>}
          </Card>
        </Col>
        <Modal
          show={showErrorMessage}
          onHide={() => setShowErrorMessage(false)}
        >
          <Modal.Header>
            <Modal.Title>Error Message</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="status">
              <p>Status Code : {msg.status}</p>
              <p>Message : {msg.message.error}</p>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={showAddFolderModal}
          onHide={() => setShowAddFolderModal(false)}
        >
          <Modal.Header>
            <Modal.Title>{modalStatus} folder</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group row">
              <label className="col-4 col-form-label">Folder name:</label>
              <div className="col-8">
                <input
                  ref={inputRef}
                  className="form-control"
                  name="newFolderName"
                  value={newFolderName}
                  onChange={onChange}
                  type="text"
                  style={{ border: error ? '1px red solid' : '' }}
                />
                <p className="mt-1" style={{ color: 'red', fontStyle: 'italic', fontWeight: '600' }}>{error}</p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button onClick={addNewFolder} className="btn button">
              <i className="fa fa-fw fa-floppy-o"></i> {modalStatus} folder
            </button>
            <button onClick={cancelAddNewFolder} className="btn button button-cancel">
              <i className="fa fa-fw fa-ban"></i> Cancel
            </button>
          </Modal.Footer>
        </Modal>
        <Modal
          size="md"
          show={deleteConfirmation}
          onHide={() => setDeleteConfirmation(false)}
        >
          <Modal.Header closeButton>
            <h4>Scenario Folder</h4>
          </Modal.Header>
          <Modal.Body>
            <div className="row p-2">
              <div className="col-12">
                <h4 className="">Are you sure you want to delete folder: {currentFolderName}</h4>
              </div>
            </div>
            <div className="row p-2">
              <button onClick={deleteFolder} className="btn btn-sm btn-success mr-2">Confirm</button>
              <button onClick={() => setDeleteConfirmation(false)} className="btn btn-sm btn-danger">Cancel</button>
            </div>
          </Modal.Body>
        </Modal>
      </BasicContent>
    </>
  );
};

export default Scenarios;
