import React, { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom";
import { Link } from "react-router-dom"
import { Col, Modal } from "react-bootstrap";
import axios from "axios";

// Multi language property
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import common_en from "../../translations/en/common.json"
import common_de from "../../translations/de/common.json"
import common_nl from "../../translations/nl/common.json"

import BasicContent from "../../components/Basic/Content/partikel/BasicContent"
import { CardWithoutHead, Card } from '../../components/Basic/Content/molekul/Card'
import InputWithErrors from "../../components/Basic/Input/InputWithErrors";
import SelectWithErrors from "../../components/Basic/Select/SelectWithErrors";

// Prime React Property
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import "primereact/resources/themes/lara-light-indigo/theme.css";  //theme
import "primereact/resources/primereact.min.css";                  //core css
import "primeicons/primeicons.css";

// React Toast Property
import { toast, ToastContainer } from "react-toastify";

i18n
    .use(initReactI18next)
    .init({
        interpolation: { escapeValue: false },  // React already does escaping
        lng: 'en',                              // language to use
        resources: {
            en: {
                common: common_en
            },
            de: {
                common: common_de
            },
            nl: {
                common: common_nl
            }
        },
    });

const UploadScenario = () => {
    // Get User info from local storage
    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    // Get jsonwebtoken from browser storage
    const token = userInfo ? userInfo.token : null

    const { t } = useTranslation('common')
    const history = useHistory()
    const { id } = useParams()

    const [file, setFile] = useState()
    const [name, setName] = useState("")

    const uploadFile = (e) => {
        if (e.target.files && e.target.files.length === 1) {
            setFile(e.target.files[0])
        }
    }

    const upload = async (e) => {
        e.preventDefault()

        const formData = new FormData()

        formData.append('name', name)
        formData.append('file', file)

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${token}`
            }
        }

        const scenario = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/scenarios/upjson`, formData, config)

        history.push("/scenarios")
    }

    const onChange = (e) => {
        setName(e.target.value)
    }

    return (
        <BasicContent
            title="Upload Scenario"
            breadcrumb={
                [
                    { link: '/', name: t('ADMIN_HOME') },
                ]
            }
        >
            <ToastContainer />
            <Col sm={12}>
                <CardWithoutHead>
                    <form onSubmit={upload}>
                    <div className="form-group row">
                        <label htmlFor="" className="col-form-label col-2">Scenario Name</label>
                        <input required="required" value={name} onChange={onChange} type="text" name="name" className="col-5 form-control" />
                    </div>
                    <div className="form-group row">
                        <label htmlFor="" className="col-form-label col-2">File</label>
                        <input required onChange={uploadFile} type="file" name="file" className="col-5" />
                    </div>

                    <button type="submit" className="button btn">Upload</button>
                    </form>

                </CardWithoutHead>
            </Col>
            <ConfirmDialog />
        </BasicContent>
    )
}

export default UploadScenario