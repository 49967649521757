import {
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
} from "../constant/userConstant";

export const authReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return {
        error: null
      }
    case USER_LOGIN_SUCCESS:
      return {
        loading: false,
        userInfo: action.payload,
        isAuthenticated: true
      };
    case USER_LOGIN_FAIL:
      return {
        loading: false,
        error: action.payload,
        isAuthenticated: false
      };
    case USER_LOGOUT:
      return {
        loading: false,
        isAuthenticated: false
      };
    default:
      return state;
  }
};