import React from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'


const Changes = () => {
  return (
    <div class="container-fluid loaded" style={{ display: 'block' }}>
      <div class="row">
        <div class="col-12">
          <div class="page-title-box">
            <div class="page-title-right">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <Link to="/">Dashboard</Link>
                </li>
                <li class="breadcrumb-item">Changelog
                </li>
                <li class="breadcrumb-item active">Changes
                </li>
              </ol>
            </div>
            <h4 class="page-title">Changes</h4>
          </div>
        </div>
      </div>
      <div class="form-messages"></div>
      <div class="row"><div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="app-search search-form float-right">
              <form name="form11" action="/en/changelog/changes" method="post" class="form-horizontal">
                <div class="form-group position-relative">
                  <input type="text" name="edit[q]" class="form-control submittable" value="" placeholder="Search..." />
                  <span class="fa fa-search search-icon"></span>
                </div><input type="hidden" name="op" value="" />
              </form>
            </div>
            <h4 class="header-title mb-3 mt-1">Changes</h4>
            <table id="tbl0" class="table">
              <thead>
                <tr>
                  <th width="10%">Date</th>
                  <th>Change</th>
                  <th>Type</th>
                  <th width="5%">Language</th>
                  <th>Translations</th>
                  <th width="100"></th>
                </tr>
              </thead>
              <tbody>
                <tr class="font-weight-bold">
                  <td>07-01-2022</td>
                  <td>Improved Lines &amp; Measurement Snapping</td>
                  <td>Change</td>
                  <td>EN</td>
                  <td><a href="http://localhost/en/changelog/changes/1" class="badge badge-primary">DE</a></td>
                  <td><form class="form-embedded" name="form0" action="/en/changelog/changes" method="post">
                    <input type="hidden" name="edit[rootid]" value="11" />
                    <a href="javascript:submit('form0','Add change');" title="Add change"><i class="icon-button fa fa-fw fa-plus"></i></a>
                    <input type="hidden" name="op" value="" />
                  </form>
                  </td>
                </tr>
                <tr class="font-weight-bold">
                  <td>07-01-2022</td>
                  <td>Better object selection</td>
                  <td>Change</td>
                  <td>EN</td>
                  <td></td>
                  <td><form class="form-embedded" name="form1" action="/en/changelog/changes" method="post">
                    <input type="hidden" name="edit[rootid]" value="13" />
                    <a href="javascript:submit('form1','Add change');" title="Add change"><i class="icon-button fa fa-fw fa-plus"></i></a>
                    <input type="hidden" name="op" value="" />
                  </form>
                  </td>
                </tr>
                <tr class="font-weight-bold">
                  <td>07-01-2022</td>
                  <td>VR Editor - Changed deselection</td>
                  <td>Change</td>
                  <td>EN</td>
                  <td></td>
                  <td><form class="form-embedded" name="form2" action="/en/changelog/changes" method="post">
                    <input type="hidden" name="edit[rootid]" value="14" />
                    <a href="javascript:submit('form2','Add change');" title="Add change"><i class="icon-button fa fa-fw fa-plus"></i></a>
                    <input type="hidden" name="op" value="" />
                  </form>
                  </td>
                </tr>
                <tr class="font-weight-bold">
                  <td>07-01-2022</td>
                  <td>Different Scenario loaded for some users is now fixed</td>
                  <td>Bugfix</td>
                  <td>EN</td>
                  <td></td>
                  <td><form class="form-embedded" name="form3" action="/en/changelog/changes" method="post">
                    <input type="hidden" name="edit[rootid]" value="15" />
                    <a href="javascript:submit('form3','Add change');" title="Add change"><i class="icon-button fa fa-fw fa-plus"></i></a>
                    <input type="hidden" name="op" value="" />
                  </form>
                  </td>
                </tr>
                <tr class="font-weight-bold">
                  <td>07-01-2022</td>
                  <td>VR Editor Menu hand able to select objects is now fixed</td>
                  <td>Bugfix</td>
                  <td>EN</td>
                  <td></td>
                  <td><form class="form-embedded" name="form4" action="/en/changelog/changes" method="post">
                    <input type="hidden" name="edit[rootid]" value="16" />
                    <a href="javascript:submit('form4','Add change');" title="Add change"><i class="icon-button fa fa-fw fa-plus"></i></a>
                    <input type="hidden" name="op" value="" />
                  </form>
                  </td>
                </tr>
                <tr class="font-weight-bold">
                  <td>07-01-2022</td>
                  <td>VR Editor - Moveable Callouts</td>
                  <td>New feature</td>
                  <td>EN</td>
                  <td></td>
                  <td><form class="form-embedded" name="form5" action="/en/changelog/changes" method="post">
                    <input type="hidden" name="edit[rootid]" value="17" />
                    <a href="javascript:submit('form5','Add change');" title="Add change"><i class="icon-button fa fa-fw fa-plus"></i></a>
                    <input type="hidden" name="op" value="" />
                  </form>
                  </td>
                </tr>
                <tr class="font-weight-bold">
                  <td>07-01-2022</td>
                  <td>VR Editor - Save Feedback</td>
                  <td>New feature</td>
                  <td>EN</td>
                  <td></td>
                  <td><form class="form-embedded" name="form6" action="/en/changelog/changes" method="post">
                    <input type="hidden" name="edit[rootid]" value="18" />
                    <a href="javascript:submit('form6','Add change');" title="Add change"><i class="icon-button fa fa-fw fa-plus"></i></a>
                    <input type="hidden" name="op" value="" />
                  </form>
                  </td>
                </tr>
                <tr class="font-weight-bold">
                  <td>07-01-2022</td>
                  <td>Measurements/Lines can now be corrected after being placed</td>
                  <td>Change</td>
                  <td>EN</td>
                  <td></td>
                  <td><form class="form-embedded" name="form7" action="/en/changelog/changes" method="post">
                    <input type="hidden" name="edit[rootid]" value="19" />
                    <a href="javascript:submit('form7','Add change');" title="Add change"><i class="icon-button fa fa-fw fa-plus"></i></a>
                    <input type="hidden" name="op" value="" />
                  </form>
                  </td>
                </tr>
                <tr class="font-weight-bold">
                  <td>02-02-2022</td>
                  <td>Fixed performance issues in online sessions</td>
                  <td>Bugfix</td>
                  <td>EN</td>
                  <td></td>
                  <td><form class="form-embedded" name="form8" action="/en/changelog/changes" method="post">
                    <input type="hidden" name="edit[rootid]" value="20" />
                    <a href="javascript:submit('form8','Add change');" title="Add change"><i class="icon-button fa fa-fw fa-plus"></i></a>
                    <input type="hidden" name="op" value="" />
                  </form>
                  </td>
                </tr>
                <tr class="font-weight-bold">
                  <td>02-02-2022</td>
                  <td>Changed default state of aim rays</td>
                  <td>Change</td>
                  <td>EN</td>
                  <td></td>
                  <td><form class="form-embedded" name="form9" action="/en/changelog/changes" method="post">
                    <input type="hidden" name="edit[rootid]" value="21" />
                    <a href="javascript:submit('form9','Add change');" title="Add change"><i class="icon-button fa fa-fw fa-plus"></i></a>
                    <input type="hidden" name="op" value="" />
                  </form>
                  </td>
                </tr>
              </tbody>
            </table>
            <form name="form10" action="/en/changelog/changes" method="post" class="form-horizontal">
              <input type="hidden" name="op" value="" />
            </form>
          </div>
        </div>
      </div>
      </div>
    </div>
  )
}

export default Changes