import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";
import { RotatingLines } from "react-loader-spinner";
import { useDispatch } from "react-redux";
import { loginStatus } from "../../../actions/userActions";
import "./index.css"

const ListTeams = () => {
  // Get User info from local storage
  const userInfo = JSON.parse(localStorage.getItem('userInfo')) 
  // Get jsonwebtoken from browser storage
  const token = userInfo !== null ? userInfo.token : null
  // State for how many page on the table list
  const [pageNumber, setPageNumber] = useState([])
  // State to show which page of table we are now
  const [currentPage, setCurrentPage] = useState(1)
  // State for activate which page is active and will get blue background
  const [isActive, setIsActive] = useState({ ods_1: true })
  // Pagination setting will send to backend as url query parameter to get club data
  const [pagination, setPagination] = useState({
    search: '',
    page: 1,
    limit: 20
  })

  const dispatch = useDispatch()

  const { search, page, limit } = pagination

  const history = useHistory()

  // State as container or variable to receipt the club data from backend
  const [teams, setTeams] = useState([])

  // Config headers for send to backend
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }

  // Data Loading State
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const getTeams = async () => {
      try {
        setIsLoading(true)
        const teams = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/club/team?search=${search}&page=${page}&limit=${limit}`, config)
        setPageNumber(Array(Math.ceil(teams.data.count / limit)).fill(0))
        setTeams(teams.data.rows)
        setTimeout(() => {
          setIsLoading(false)
        }, 100);
      } catch (error) {
        if(error.response.status === 401){
          dispatch(loginStatus())
        }
      }
    }
    getTeams()
  }, [search, currentPage])

  const pageOnClick = (val) => {
    setCurrentPage(val)
    setPagination({ ...pagination, page: val })
    setIsActive({ [`ods_${val}`]: true })
  }

  // This function is for move forward when we click Next on pagination
  const onNextPage = () => {
    if (currentPage === pageNumber.length) {
      return
    }
    setCurrentPage(currentPage + 1)
    setPagination({ ...pagination, page: currentPage + 1 })
    setIsActive({ [`ods_${currentPage + 1}`]: true })
  }

  // This function is for move backforward when we click Previous on pagination
  const onPrevPage = () => {
    if (currentPage === 1) {
      return
    }
    setCurrentPage(currentPage - 1)
    setPagination({ ...pagination, page: currentPage - 1 })
    setIsActive({ [`ods_${currentPage - 1}`]: true })
  }

  const onChange = (e) => {
    setCurrentPage(1);
    setIsActive({ "ods_1": true })
    setPagination({ ...pagination, page: 1, search: e.target.value })
  }

  return (
    <div className="container-fluid loaded" style={{ display: "block" }}>
      <div className="rotating-lines">
        <RotatingLines
          strokeColor="grey"
          strokeWidth="5"
          animationDuration="0.75"
          width="82"
          visible={isLoading}
        />
      </div>
      <div className="row">
        <div className="col-12">
          <div className="page-title-box">
            <div className="page-title-right">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Dashboard</Link>
                </li>
                <li className="breadcrumb-item">Manage Teams</li>
                <li className="breadcrumb-item active">Teams</li>
              </ol>
            </div>
            <h4 className="page-title">Teams</h4>
          </div>
        </div>
      </div>
      <div className="form-messages"></div>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="app-search search-form float-right">
                <div className="form-group position-relative">
                  <input
                    type="text"
                    name="name"
                    className="form-control submittable"
                    onChange={onChange}
                    value={search}
                    placeholder="Search by name..."
                  />
                  <span className="fa fa-search search-icon"></span>
                </div>
                <input type="hidden" name="op" value="" />
              </div>
              <h4 className="header-title mb-3 mt-1">Teams</h4>
              <table id="tbl0" className="table">
                <thead>
                  <tr>
                    <th>Club</th>
                    <th>Team</th>
                    <th>#Members</th>
                    <th width="36"></th>
                  </tr>
                </thead>
                <tbody>
                  {teams.map(team => (
                    <tr key={team.team_id}>
                      <td>{team.clubname}</td>
                      <td>{team.name}</td>
                      <td>{team.count ? team.count : 0}</td>
                      <td>
                        <Link
                          to={`/manageclub/teams/${team.team_id}`}
                          title="Edit"
                        >
                          <i className="icon-button fa fa-border fa-pencil pencil-alt color-green-bg"></i>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <ul className="pagination">
                <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
                  <a style={{ cursor: 'pointer' }} onClick={onPrevPage} className="page-link" tabIndex="-1">Previous</a>
                </li>
                {pageNumber.map((item, index) => (
                  <li key={index} className={`page-item ${isActive[`ods_${index + 1}`] ? "active" : ""}`}><a style={{ cursor: 'pointer' }} className="page-link" onClick={() => pageOnClick(index + 1)} >{index + 1}</a></li>
                ))}
                <li className={`page-item ${currentPage === pageNumber.length ? "disabled" : ""}`}>
                  <a style={{ cursor: 'pointer' }} onClick={onNextPage} className="page-link">Next</a>
                </li>
              </ul>
              <Link to="/manageclub/teams/add" className="btn button">
                <i className="fa fa-fw fa-plus"></i> Add
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListTeams;
