import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import BasicContent from "../../../components/Basic/Content/partikel/BasicContent"
import i18n from "i18next";
import axios from "axios";
import { useTranslation, initReactI18next } from "react-i18next";
import common_en from "../../../translations/en/common.json"
import common_de from "../../../translations/de/common.json"
import common_nl from "../../../translations/nl/common.json"
import { Col } from "react-bootstrap";
import { CardWithoutHead } from '../../../components/Basic/Content/molekul/Card'

i18n
    .use(initReactI18next)
    .init({
        interpolation: { escapeValue: false },  // React already does escaping
        lng: 'en',                              // language to use
        resources: {
            en: {
                common: common_en
            },
            de: {
                common: common_de
            },
            nl: {
                common: common_nl
            }
        },
    });

const Group = () => {
    const { t } = useTranslation('common')

    const [groups, setGroups] = useState([])

    useEffect(() => {
        const getAllGroup = async () => {
            const group = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/group`)
            setGroups(group.data)
        }
        getAllGroup()
    }, [])

    return (
        <BasicContent 
            title="Groups" 
            breadcrumb={
                [
                    { link: '/', name: t('ADMIN_HOME') }, 
                    { link: '/users/group', name: 'Group' }
                ]
            } 
        >
            <Col sm={12}>
                <CardWithoutHead>
                    <table id="tbl0" className="table">
                        <thead>
                            <tr>
                                <th>Group</th>
                                <th width="36"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {groups.map(group => (
                                <tr>
                                    <td>{group.group}</td>
                                    <td><Link to={`group/edit/${group.pgid}`}><i class="icon-button fa fa-border fa-pencil pencil-alt color-green-bg"></i></Link></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <Link to='/users/group/add' className="btn button"><i className="fa fa-fw fa-plus"></i>Add</Link>
                </CardWithoutHead>
            </Col>
        </BasicContent>
    )
}

export default Group