import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

const ManagerServer = () => {
  const token = JSON.parse(localStorage.getItem("userInfo")).token

  const [servers, setServers] = useState([]);

  const serverStatus = [
    "Disabled",
    "Ready",
    "Offline",
    "Shutdown",
    "Crashed",
    "Processing",
  ];

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  useEffect(() => {
    const getServers = async () => {
      const servers = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/server`, config);
      setServers(servers.data);
    };
    getServers();
  }, []);

  return (
    <div class="container-fluid loaded" style={{ display: "block" }}>
      <div class="row">
        <div class="col-12">
          <div class="page-title-box">
            <div class="page-title-right">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <Link to="/">Dashboard</Link>
                </li>
                <li class="breadcrumb-item">Video Tracking</li>
                <li class="breadcrumb-item active">Manage servers</li>
              </ol>
            </div>
            <h4 class="page-title">Manage servers</h4>
          </div>
        </div>
      </div>
      <div class="form-messages"></div>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="app-search search-form float-right">
                <div class="form-group position-relative">
                  <input
                    type="text"
                    name="edit[q]"
                    class="form-control submittable"
                    value=""
                    placeholder="Search by name or IP..."
                  />
                  <span class="fa fa-search search-icon"></span>
                </div>
              </div>
              <h4 class="header-title mb-3 mt-1">Active servers</h4>
              <table id="tbl0" class="table">
                <thead>
                  <tr>
                    <th width="5%">ID</th>
                    <th>Name</th>
                    <th width="20%">IP</th>
                    <th>Status</th>
                    <th width="10%">Version</th>
                    <th width="8%">Role</th>
                    <th width="8%">Weight</th>
                    <th width="8%">Active</th>
                    <th width="60"></th>
                  </tr>
                </thead>
                <tbody>
                  {servers.map((server) => (
                    <tr key={server.server_id}>
                      <td>{server.server_id}</td>
                      <td>{server.name}</td>
                      <td>{server.ip}</td>
                      <td>{serverStatus[server.status]}</td>
                      <td>{server.api_version}</td>
                      <td>
                        {server.role === 0 ? "Live server" : "Internal use"}
                      </td>
                      <td>{server.weight}</td>
                      <td>
                        <i
                          class={`fa fa-lg ${server.active ? 'fa-check' : 'fa-minus'} color-green`}
                          aria-hidden="true"
                        ></i>
                      </td>
                      <td>
                        <Link
                          to={`/videotracking.admin/servers/${server.server_id}`}
                        >
                          <i className="icon-button fa fa-border fa-pencil pencil-alt color-green-bg"></i>{" "}
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <form
                name="form0"
                action="/en/videotracking.admin/servers"
                method="post"
                class="form-horizontal"
              >
                <Link to="/videotracking.admin/servers/add" class="btn button">
                  <i class="fa fa-fw fa-plus"></i> Add
                </Link>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default ManagerServer;
