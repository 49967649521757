import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { loginStatus } from "../../actions/userActions";
import { useDispatch } from "react-redux";

const Dashboard = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  history.push("/club")

  useEffect(() => {
    dispatch(loginStatus())
  }, [])
  return (
    <div className="container-fluid loaded" style={{ display: "block" }}>
      <div className="row">
        <div className="col-12">
          <div className="page-title-box">
            <div className="page-title-right">
              <ol className="breadcrumb">
                <li className="breadcrumb-item active">Dashboard</li>
              </ol>
            </div>
            <h4 className="page-title">Dashboard</h4>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <h4 className="header-title mb-3">Dashboard</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
