import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import axios from "axios";

const AddVideoTrackingServer = () => {
  const { id } = useParams();
  const history = useHistory();
  const token = JSON.parse(localStorage.getItem("userInfo")).token
  const [server, setServer] = useState({
    active: true,
    name: '',
    ip: '',
    status: "1",
    api_version: "0.98.7.0",
    fails: "0",
    task_id: "0",
    lastdt: Math.floor(Date.now() / 1000),
    role: "0",
    weight: "1"
  });

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const onChange = (e) => {
    setServer({ ...server, [e.target.name]: e.target.value });
  };

  const changeServerActive = () => {
    setServer({ ...server, active: !server.active });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const createServer = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/v1/server`,
      server,
      config
    );
    history.push("/videotracking.admin/servers");
  };

  return (
    <div className="container-fluid loaded" style={{ display: "block" }}>
      <div className="row">
        <div className="col-12">
          <div className="page-title-box">
            <div className="page-title-right">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Dashboard</Link>
                </li>
                <li className="breadcrumb-item">Video Tracking</li>
                <li className="breadcrumb-item active">Manage servers</li>
              </ol>
            </div>
            <h4 className="page-title">Manage servers</h4>
          </div>
        </div>
      </div>
      <div className="form-messages"></div>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <form onSubmit={onSubmit} method="post" className="form-horizontal">
                <fieldset>
                  <legend>Add server</legend>
                  <div className="form-group row">
                    <label className="col-4 col-form-label">Active:</label>{" "}
                    <div className="col-8">
                      <input
                        className="form-control"
                        type="checkbox"
                        name="active"
                        onClick={changeServerActive}
                        value={server.active}
                        checked={server.active}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-4 col-form-label">Name:</label>{" "}
                    <div className="col-8">
                      <input
                        className="form-control"
                        maxLength="64"
                        name="name"
                        onChange={onChange}
                        type="text"
                        value={server.name}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-4 col-form-label">IP:</label>{" "}
                    <div className="col-8">
                      <input
                        className="form-control"
                        maxlength="64"
                        onChange={onChange}
                        name="ip"
                        type="text"
                        value={server.ip}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-4 col-form-label">Role:</label>{" "}
                    <div className="col-8">
                      <select
                        onChange={onChange}
                        value={server.role}
                        className="custom-select select2"
                        name="role"
                      >
                        <option value="0">Live server</option>
                        <option value="1">Internal use</option>
                      </select>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-4 col-form-label">Weight:</label>{" "}
                    <div className="col-8">
                      <select
                        onChange={onChange}
                        value={server.weight}
                        className="custom-select select2"
                        name="weight"
                      >
                        <option value="-10">-10</option>
                        <option value="-9">-9</option>
                        <option value="-8">-8</option>
                        <option value="-7">-7</option>
                        <option value="-6">-6</option>
                        <option value="-5">-5</option>
                        <option value="-4">-4</option>
                        <option value="-3">-3</option>
                        <option value="-2">-2</option>
                        <option value="-1">-1</option>
                        <option value="0" selected="selected"></option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                      </select>
                    </div>
                  </div>
                  <input type="hidden" name="edit[server_id]" value="2" />
                </fieldset>
                <button type="submit" className="btn button">
                  <i className="fa fa-fw fa-floppy-o"></i> Save
                </button>
                <Link to="/videotracking.admin/servers" className="button-cancel">
                  <i className="fa fa-fw fa-step-backward"></i>&nbsp;Cancel
                </Link>
                <input type="hidden" name="op" value="" />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddVideoTrackingServer;
