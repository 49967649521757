import React, { useState, useEffect, useRef, useContext } from "react"
import "./login.css"
import logo from "../../assets/images/logo-login.png"
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login, loginStatus, loginRequest } from "../../actions/userActions";
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import common_en from "../../translations/en/common.json"
import common_de from "../../translations/de/common.json"
import common_nl from "../../translations/nl/common.json"
import LogoLogin from "../../assets/images/logo-login.png"
import { toast, ToastContainer } from 'react-toastify'
import "react-toastify/dist/ReactToastify.css";
import { LangContext } from "../../app";

i18n
    .use(initReactI18next)
    .init({
        interpolation: { escapeValue: false },  // React already does escaping
        lng: 'en',                              // language to use
        resources: {
            en: {
                common: common_en
            },
            de: {
                common: common_de
            },
            nl: {
                common: common_nl
            }
        },
    });

const Login2 = () => {
    const { t } = useTranslation('common')
    const [form, setForm] = useState({
        email: "",
        password: "",
        checkbox: true,
    });

    const createRef = useRef(null)

    const userInfo = JSON.parse(localStorage.getItem("userInfo"));

    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);

    const { isAuthenticated, error } = auth;

    const { email, password, checkbox } = form;
    const [isShow, setIsShow] = useState()
    const [lango, setLango] = useContext(LangContext)
    const [smallHeight, setSmallHeight] = useState(false)
    const [typePassword, setTypePassword] = useState("password")

    const changePasswordType = () => {
        console.log(typePassword)
        if (typePassword === "password") {
            setTypePassword("text")
        } else {
            setTypePassword("password")
        }
    }

    const history = useHistory();

    const redirect = location.search ? location.search.split("=")[1] : "/";

    localStorage.getItem("clubID") !== null ? localStorage.removeItem("clubID") : ""

    useEffect(() => {
        if (userInfo !== null) {
            dispatch(loginStatus());
        }
    }, []);

    useEffect(() => {
        window.addEventListener('resize', function () {
            if (window.innerHeight < 775 && this.window.innerWidth >= 992) {
                setSmallHeight(true)
            } else {
                setSmallHeight(false)
            }
        })
    }, [])

    if (isAuthenticated) {
        history.push("/club");
    }

    const onSubmit = (e) => {
        e.preventDefault()
        dispatch(login(email, password));
    };
    const onChange = (e) => {
        if (e.target.name === "remember") {
            setForm({ ...form, checkbox: !checkbox })
            return;
        }
        setForm({ ...form, [e.target.name]: e.target.value });
    };
    const closeAlert = () => {
        dispatch(loginRequest())
    }

    const showLanguage = (e) => {
        e.preventDefault()
        setIsShow(!isShow)
    }

    const getLanguage = (e, lng) => {
        e.preventDefault()
        i18n.changeLanguage(lng)
        setLango({ title: e.target.innerText, flag: lng })
        setIsShow(!isShow)
    }
    return (
        <div className="login-page row">
            <div className="left">
                <div className="image">
                    <img width="350px" src={logo} alt="" />
                </div>

                <div className="box-content">
                    <div className="sign-text">
                        <p className="main">{t('Sign in')}</p>
                        <p>{t('LOGINMSG')}</p>
                    </div>
                    <ul className="list-unstyled text-right mb-0">
                        <li className="dropdown ">
                            <a
                                className="nav-link dropdown-toggle arrow-none"
                                data-toggle="dropdown"
                                onClick={showLanguage}
                                href="#!"
                                role="button"
                                aria-haspopup="false"
                                aria-expanded="false"
                            >
                                <img
                                    src={`/images/flags/${lango.flag}.png`}
                                    alt="user-image"
                                    className="mr-0 mr-sm-1"
                                    height="12"
                                />
                                <span className="align-center d-none d-sm-inline-block">
                                    {lango.title}
                                </span>
                                <i className="fa fa-angle-down d-none d-sm-inline-block align-middle"></i>
                            </a>
                            <div className={`dropdown-menu dropdown-menu-right dropdown-menu-animated topbar-dropdown-menu ${isShow ? 'show' : ''}`}>
                                <a
                                    href="#"
                                    onClick={(e) => getLanguage(e, 'en')}
                                    className="dropdown-item notify-item"
                                    value="English"
                                >
                                    <img
                                        src="/images/flags/en.png"
                                        alt="user-image"
                                        className="mr-1"
                                        height="12"
                                    />
                                    <span className="align-middle">English</span>
                                </a>
                                <a
                                    href="#"
                                    onClick={(e) => getLanguage(e, 'de')}
                                    name="de"
                                    className="dropdown-item notify-item"
                                    defaultValue="Deutsch"
                                >
                                    <img
                                        src="/images/flags/de.png"
                                        alt="user-image"
                                        className="mr-1"
                                        height="12"
                                    />
                                    <span className="align-middle">Deutsch</span>
                                </a>
                                <a
                                    href="#"
                                    onClick={(e) => getLanguage(e, 'nl')}
                                    className="dropdown-item notify-item"
                                    name="nl"
                                >
                                    <img
                                        src="/images/flags/nl.png"
                                        alt="user-image"
                                        className="mr-1"
                                        height="12"
                                    />
                                    <span className="align-middle">Nederlands</span>
                                </a>
                            </div>
                        </li>
                    </ul>
                    <form onSubmit={onSubmit}>
                        <div className="form-group">
                            <label htmlFor="" className="email"><b>{t('Email address')}</b></label>
                            <input
                                className="form-control"
                                onChange={onChange}
                                value={email}
                                type="email"
                                name="email"
                                id="emailaddress"
                                style={{ border: error ? error.data.some(val => { return val.path[0] === "email" }) ? "1px red solid" : "" : "" }}
                                placeholder={t('Enter your email')}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="" className="email"><b>Password</b></label>
                            <div style={{ display: "flex" }} className="input-group">
                                <input
                                    onChange={onChange}
                                    value={password}
                                    className="form-control"
                                    type={typePassword}
                                    name="password"
                                    required=""
                                    id="password"
                                    placeholder={t('Enter your password')}
                                    style={{ border: error && error.data.some(val => { return val.path[0] === "password" }) ? "1px red solid" : "", borderRight: "0" }}
                                />
                                <div className="input-group-append">
                                    <span onClick={changePasswordType} style={{ cursor: "pointer", backgroundColor: "#fff", borderLeft: "0" }} className="input-group-text"><i className={typePassword === "text" ? "fa fa-eye" : "fa fa-eye-slash"}></i></span>
                                </div>
                            </div>
                            {error && error.data.some((val) => { return val.path[0] === "password" }) && <p style={{ color: "red", fontWeight: 600, fontSize: "14px" }}><i>{error.data.find(val => val.path[0] === "password").message}</i></p>}
                        </div>
                        <div className="form-group">
                            <div class="custom-control custom-checkbox">
                                <input
                                    onChange={onChange}
                                    type="checkbox"
                                    name="remember"
                                    checked={checkbox}
                                    className="custom-control-input"
                                    id="checkbox-signin"
                                />
                                <label class="custom-control-label" for="checkbox-signin">{t('Remember me')}</label></div>
                        </div>
                        {error && error.data.some((val) => { return val.path[0] === "invalid" }) && <div
                            className="alert alert-dismissable fade show alert-warning"
                            role="alert"
                        >
                            <button
                                type="button"
                                onClick={closeAlert}
                                class="close"
                                data-dismiss="alert"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                            {error.data.find(val => val.path[0] === "invalid").message}
                        </div>}
                        <button class="btn btn-primary btn-block" name="op" value="Login" type="submit"><i class="fa fa-sign-in"></i> Login</button>
                    </form>
                </div>
            </div>
            <div className="right">
                <div className="blur"></div>
            </div>
        </div>
    )
}

export default Login2