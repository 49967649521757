import React, { useState, useEffect, Fragment, useContext } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { loginStatus, logout, authFail } from "../../actions/userActions";
import { Modal, Button } from "react-bootstrap";
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import common_en from "../../translations/en/common.json"
import common_de from "../../translations/de/common.json"
import common_nl from "../../translations/nl/common.json"
import { useDispatch } from "react-redux";
import { clubIDContext } from "../Admin";
// Prime React Property
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import "primereact/resources/themes/lara-light-indigo/theme.css";  //theme
import "primereact/resources/primereact.min.css";                  //core css
import "primeicons/primeicons.css";

i18n
  .use(initReactI18next)
  .init({
    interpolation: { escapeValue: false },  // React already does escaping
    lng: 'en',                              // language to use
    resources: {
      en: {
        common: common_en
      },
      de: {
        common: common_de
      },
      nl: {
        common: common_nl
      }
    },
  });

const Club = () => {
  const { t } = useTranslation('common')
  const position = {
    0: "Player",
    70: "Assistant",
    80: "Coach",
    100: "Manager",
  };
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  // const clubId = userInfo !== null ? userInfo.club_id : null;

  //const clubId = JSON.parse(localStorage.getItem("clubID")).spyClub !== "" ? JSON.parse(localStorage.getItem("clubID")).spyClub : ""

  const dispatch = useDispatch()
  const [adminClub, setAdminClub] = useContext(clubIDContext)

  const [members, setMembers] = useState([]);
  const [filterMember, setFilterMember] = useState([]);
  const [club, setClub] = useState({});
  const [clubTeams, setClubTeams] = useState([]);
  const [teamName, setTeamName] = useState({ name: "", club_id: adminClub.spy });
  const [isUpdated, setIsUpdated] = useState(false)
  const [clubLogo, setClubLogo] = useState(null);
  const [searchName, setSearchName] = useState("");
  const [errors, setErrors] = useState({ teamName: false });
  const [invitationError, setInvitationError] = useState()

  const token = userInfo !== null ? userInfo.token : null;
  const [isUpdate, setIsUpdate] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => {
    setShowModal(false);
  };
  const inviteMember = (e) => {
    e.preventDefault()
    setInvitationError()
    setShowModal(true);
  };
  const [formInviteMember, setFormInviteMember] = useState({
    email: '',
    clubId: '',
    senderName: '',
    senderEmail: ''
  })
  const sendInvitation = async (e) => {
    e.preventDefault()
    try {
      const invitation = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/email/invitation`, formInviteMember)
      setShowModal(false)
    } catch (error) {
      setInvitationError(error.response.data.msg)
    }
  }
  const onChangeModal = (e) => {
    setFormInviteMember({ ...formInviteMember, [e.target.name]: e.target.value, clubId: adminClub.spy, senderName: userInfo.user_name, senderEmail: userInfo.user_email })
  }

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const configLogo = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  };

  useEffect(() => {
    if (adminClub.spy !== "") {
      const getData = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/api/v1/club/data/${adminClub.spy}?search=${searchName}`,
            config
          );

          setClub(response.data.club);
          setClubTeams(response.data.clubTeam);
          setMembers(response.data.members);
          setFilterMember(response.data.members);
        } catch (error) {
          if (error.response.status === 401) {
            dispatch(loginStatus())
          }
        }
      };
      getData();
    }

  }, [isUpdated]);

  const addTeam = async (e) => {
    e.preventDefault();
    if (teamName.name === "") {
      setErrors({ ...errors, teamName: true });
      return;
    }
    try {
      const team = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/club/team`,
        teamName,
        config
      );
      setTeamName({ ...teamName, name: "" });
      setIsUpdate(!isUpdate);
    } catch (error) {
      console.log("error");
    }
  };

  const onChange = (e) => {
    setErrors({ ...errors, teamName: false });
    setTeamName({ ...teamName, [e.target.name]: e.target.value });
  };

  const onChangeSearch = (e) => {
    const filter = filterMember.filter((member) => {
      return (
        new RegExp(".*" + e.target.value + ".*", "i").test(member.name) ||
        new RegExp(".*" + e.target.value + ".*", "i").test(member.email)
      );
    });
    setMembers(filter);
  };

  const onChangeLogo = (e) => {
    if (e.target.files && e.target.files.length === 1) {
      setClubLogo(e.target.files[0]);
    }
  };

  const uploadLogo = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("image", clubLogo);
    const upload = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/v1/club/logo/${adminClub.spy}`,
      formData,
      configLogo
    );
    document.querySelector('input[name="image"]').value = null;
    setIsUpdate(!isUpdate);
  };

  // Start Group Function to confirm PrimeReact Confirmation Box
  const confirm2 = (id, e) => {
    e.preventDefault()
    confirmDialog({
      message: 'Do you want to delete this user from club?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () => accept(id),
      reject
    });
  };
  const accept = async (id) => {
    const permission = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/v1/club/member/${id}`, config)
    setIsUpdated(!isUpdated)
  }
  const reject = () => {

  }
  // End Group Function to confirm PrimeReact Confirmation Box

  return (
    <div className="container-fluid loaded" style={{ display: "block" }}>
      <div className="row">
        <div className="col-12">
          <div className="page-title-box">
            <div className="page-title-right">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Dashboard</Link>
                </li>
                <li className="breadcrumb-item active">Club</li>
              </ol>
            </div>
            <h4 className="page-title">Club</h4>
          </div>
        </div>
      </div>
      <div className="form-messages"></div>
      {adminClub.spy !== "" &&
        <div className="clubContent">
          <div className="row">
            <div className="col-12 col-sm-8">
              <div className="card">
                <div className="card-header">
                  <h4 className="mb-1 mt-1">{club.name}</h4>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-6">
                      <img
                        src={process.env.REACT_APP_BACKEND_URL + "" + club.images}
                        className="mx-auto d-block rounded"
                        style={{ maxWidth: "95%" }}
                      />
                    </div>
                    <div className="col-sm-6">
                      <h3>Licensing information</h3>
                      <p>
                        <strong>Member licenses Left</strong>: {parseInt(club.member_licenses) - parseInt(members.length)}
                        <br />
                      </p>
                      <p>
                        <strong>#Members</strong>: {members.length}
                      </p>
                      <div className="form-group row">
                        <label className="col-4 col-form-label">
                          Change club logo:
                        </label>
                        <div className="col-8">
                          <input onChange={onChangeLogo} type="file" name="image" />
                        </div>
                      </div>
                      <button
                        type="button"
                        onClick={uploadLogo}
                        className="btn button"
                      >
                        <i className="fa fa-fw fa-upload"></i> Upload
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-4">
              <div className="card">
                <div className="card-header">
                  <h4 className="mb-1 mt-1">Teams</h4>
                </div>
                <div className="card-body">
                  {clubTeams.map((team) => (
                    <Fragment key={team.team_id}>
                      <Link to={`/club/team/${team.team_id}`}>{team.name}</Link>
                      <br />
                    </Fragment>
                  ))}
                  <br />
                  <form onSubmit={addTeam}>
                    <div className="input-group mt-2">
                      <input
                        type="text"
                        name="name"
                        style={{ border: errors.teamName ? "solid 1px red" : "" }}
                        onChange={onChange}
                        value={teamName.name}
                        className="form-control"
                        placeholder="Team name..."
                        aria-label="Team name"
                        aria-describedby="button-team"
                      />
                      <div className="input-group-append">
                        <button
                          className="btn btn-outline-success"
                          name="op"
                          value="Add"
                          type="submit"
                          id="button-team"
                        >
                          Add
                        </button>
                      </div>
                    </div>
                    {errors.teamName && (
                      <p style={{ color: "red", fontWeight: 500 }}>
                        <i>Team name cannot be empty</i>
                      </p>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <div className="app-search search-form float-right">
                    <div className="form-group position-relative">
                      <input
                        type="text"
                        name="search"
                        onChange={onChangeSearch}
                        className="form-control"
                        placeholder="Search by name or email..."
                      />
                      <span className="fa fa-search search-icon"></span>
                    </div>
                  </div>
                  <h4 className="mb-1 mt-1">Members</h4>
                </div>
                <div className="card-body">
                  <table id="tbl0" className="table">
                    <thead>
                      <tr>
                        <th width="32"></th>
                        <th>Club</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Access level</th>
                        <th width="80">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {members.map((member, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <img
                                src={
                                  process.env.REACT_APP_BACKEND_URL +
                                  "" +
                                  member.image
                                }
                                alt="user-image"
                                className="rounded"
                                style={{ width: "32px" }}
                              />
                            </td>
                            <td>{club.name}</td>
                            <td>{member.name}</td>
                            <td>{member.email}</td>
                            <td>{position[member.accessLevel]}</td>
                            <td>
                              <Link
                                to={`/club/editmember/${member.uid}`}
                                title="Edit"
                              >
                                <i className="icon-button fa fa-border fa-pencil pencil-alt color-green-bg"></i>
                              </Link>
                              <a href="" onClick={(e) => confirm2(member.uid, e)}><i className="icon-button fa fa-border fa-trash trash-alt color-red-bg"></i></a>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <form
                    name="form3"
                    action="/en/club"
                    method="post"
                    className="form-horizontal"
                  >
                    <a href="#!" onClick={inviteMember} className="button">
                      <i className="fa fa-fw fa-plus"></i>&nbsp;{t('Add')}
                    </a>
                    <input type="hidden" name="op" value="" />
                  </form>
                </div>
              </div>
            </div>
          </div>
          <Modal show={showModal} onHide={handleCloseModal}>
            <Modal.Header closeButton>
              <Modal.Title>Invite Member</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="form-group row">
                <label className="col-4 col-form-label">Email:</label>
                <div className="col-8">
                  <input
                    type="email"
                    required=""
                    className="form-control"
                    maxLength="128"
                    name="email"
                    value={formInviteMember.email}
                    onChange={onChangeModal}
                    placeholder="example@email.com"
                    style={{ border: invitationError ? '1px solid red' : '' }}
                  />
                  {invitationError && <p style={{ color: 'red', fontStyle: 'italic', fontWeight: 600, fontSize: '13px', marginTop: '3px' }}>{invitationError}</p>}
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                className="btn button button-cancel"
                data-dismiss="modal"
                onClick={handleCloseModal}
              >
                <i className="fa fa-fw fa-ban"></i> Cancel
              </button>
              <button
                type="button"
                className="btn button"
                onClick={sendInvitation}
              >
                <i className="fa fa-fw fa-floppy-o"></i> Invite
              </button>
            </Modal.Footer>
          </Modal>
        </div>
      }
      {
        adminClub.spy === "" && <p>You are not a member a club</p>
      }
      <ConfirmDialog />
    </div>
  );
};

export default Club;
