import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom"
import { Col } from "react-bootstrap";
import i18n from "i18next";
import axios from "axios";
import { useTranslation, initReactI18next } from "react-i18next";
import common_en from "../../../translations/en/common.json"
import common_de from "../../../translations/de/common.json"
import common_nl from "../../../translations/nl/common.json"
import BasicContent from "../../../components/Basic/Content/partikel/BasicContent"
import { Card } from '../../../components/Basic/Content/molekul/Card'
import {InputWithErrors} from "../../../components/Basic/Input/InputWithErrors";

i18n
    .use(initReactI18next)
    .init({
        interpolation: { escapeValue: false },  // React already does escaping
        lng: 'en',                              // language to use
        resources: {
            en: {
                common: common_en
            },
            de: {
                common: common_de
            },
            nl: {
                common: common_nl
            }
        },
    });

const AddGroup = () => {
    const { t } = useTranslation('common')
    const [group, setGroup] = useState({group:'', perm:''})
    const [errors, setErrors] = useState([])
    const history = useHistory()

    const onChange = (e) => {
        setGroup({...group, [e.target.name] : e.target.value})
    }

    const onClick = async (e) => {
        switch(e.target.name){
            case 'save':
                try {
                    await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/group`, group)
                    history.push('/users/group')
                } catch (error) {
                    setErrors(error.response.data)
                }
                break;
            default :
                history.push('/users/group')
                break;
        }
    }

    return (
        <BasicContent
            title="Groups"
            breadcrumb={
                [
                    { link: '/', name: t('ADMIN_HOME') },
                    { link: '/users/group', name: 'Group' },
                    { link: '/users/group', name: 'Add Group' }
                ]
            }
        >
            <Col sm={12}>
                <Card title="Add Group">
                    <div className="form-group row">
                        <label className="col-4">Group</label>
                        <div className="col-8">
                            <InputWithErrors
                                name="group"
                                value={group.group}
                                onChange={onChange}
                                errors={errors}
                            />
                        </div>
                    </div>
                    <button onClick={onClick} name="save" className="btn button"><i className="fa fa-fw fa-floppy-o"></i>Save</button>
                    <button onClick={onClick} name="cancel" className="button-cancel"><i className="fa fa-fw fa-step-backward"></i>Cancel</button>
                </Card>
            </Col>
        </BasicContent>
    )
}

export default AddGroup