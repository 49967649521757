import React, { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom";
import { Link } from "react-router-dom"
import { Col, Modal } from "react-bootstrap";
import axios from "axios";

// Multi language property
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import common_en from "../../../translations/en/common.json"
import common_de from "../../../translations/de/common.json"
import common_nl from "../../../translations/nl/common.json"

import BasicContent from "../../../components/Basic/Content/partikel/BasicContent"
import { CardWithoutHead } from '../../../components/Basic/Content/molekul/Card'
import {
    view_club,
    manage_club,
    manage_club_teams,
    admin_locale,
    manage_scenarios,
    saiva_lite_scenarios,
    dev_tools_scenarios,
    access_admin,
    access_config,
    access_log,
    admin_user,
    admin_permission,
    allow_developer_mode,
    access_app_settings,
    saica,
    use_video_tracking,
    change_club,
    special_permission,
    upload_scenario
} from "../../../constant/featureConstant";

i18n
    .use(initReactI18next)
    .init({
        interpolation: { escapeValue: false },  // React already does escaping
        lng: 'en',                              // language to use
        resources: {
            en: {
                common: common_en
            },
            de: {
                common: common_de
            },
            nl: {
                common: common_nl
            }
        },
    });

const UserPermission = () => {
    // Get User info from local storage
    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    // Get jsonwebtoken from browser storage
    const token = userInfo ? userInfo.token : null

    const { t } = useTranslation('common')
    const history = useHistory()
    const { id } = useParams()
    const [isLoading, setIsLoading] = useState(false)
    const [groups, setGroups] = useState([])
    const [isUpdated, setIsUpdated] = useState(false)

    useEffect(() => {
        const getAllGroup = async () => {
            const gr = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/group`)
            setGroups(gr.data)
        }

        getAllGroup()
    }, [isUpdated])

    const getPermission = (name, page) => {
        const group = groups.filter(group => {
            return group.group === name
        })

        return group[0].perm.split(",").some(item => item.trim() === page)
    }

    const onChange = async (feature, e) => {
        const data = groups.find(item => { return item.pgid == e.target.name })
        const perm = data.perm.length === 0 ? [] : data.perm.split(",")

        if (e.target.value === 'true') {
            perm.splice(perm.findIndex(pr => pr.trim() === feature), 1)
        } else {
            perm.push(feature)
        }

        data.perm = perm.toString()

        await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/v1/group/${e.target.name}`, data)

        setIsUpdated(!isUpdated)
    }

    return (
        <BasicContent
            title="Permissions"
            breadcrumb={
                [
                    { link: '/', name: t('ADMIN_HOME') },
                    { link: '/users/permissions', name: 'Permission' }
                ]
            }
        >
            <Col sm={12}>
                <CardWithoutHead>
                    <table id="tbl0" class="table">
                        <thead>
                            <tr>
                                <th width="40%">App</th>
                                {groups.map(group => (
                                    <th key={group.pgid}>{group.group}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>App settings</td>
                                {groups.map(group => (
                                    <td>
                                        <input
                                            class="checkbox"
                                            onChange={(e) => onChange(access_app_settings, e)}
                                            value={getPermission(group.group, access_app_settings)}
                                            checked={getPermission(group.group, access_app_settings)}
                                            type="checkbox"
                                            name={group.pgid} />
                                    </td>
                                ))}
                            </tr>
                        </tbody>
                    </table>
                    <table id="tbl1" class="table">
                        <thead>
                            <tr>
                                <th width="40%">Club</th>
                                {groups.map(group => (
                                    <th key={group.pgid}>{group.group}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{view_club}</td>
                                {groups.map(group => (
                                    <td>
                                        <input
                                            class="checkbox"
                                            onChange={(e) => onChange(view_club, e)}
                                            value={getPermission(group.group, view_club)}
                                            checked={getPermission(group.group, view_club)}
                                            type="checkbox"
                                            name={group.pgid} />
                                    </td>
                                ))}
                            </tr>
                            <tr>
                                <td>{manage_club}</td>
                                {groups.map(group => (
                                    <td>
                                        <input
                                            class="checkbox"
                                            onChange={(e) => onChange(manage_club, e)}
                                            value={getPermission(group.group, manage_club)}
                                            checked={getPermission(group.group, manage_club)}
                                            type="checkbox"
                                            name={group.pgid}
                                        />
                                    </td>
                                ))}
                            </tr>
                            <tr>
                                <td>{manage_club_teams}</td>
                                {groups.map(group => (
                                    <td>
                                        <input
                                            class="checkbox"
                                            onChange={(e) => onChange(manage_club_teams, e)}
                                            value={getPermission(group.group, manage_club_teams)}
                                            checked={getPermission(group.group, manage_club_teams)}
                                            type="checkbox"
                                            name={group.pgid}
                                        />
                                    </td>
                                ))}
                            </tr>
                        </tbody>
                    </table>
                    <table id="tbl2" class="table">
                        <thead>
                            <tr>
                                <th width="40%">Locale</th>
                                {groups.map(group => (
                                    <th key={group.pgid}>{group.group}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Admin locale</td>
                                {groups.map(group => (
                                    <td>
                                        <input
                                            class="checkbox"
                                            onChange={(e) => onChange(admin_locale, e)}
                                            value={getPermission(group.group, admin_locale)}
                                            checked={getPermission(group.group, admin_locale)}
                                            type="checkbox" name={group.pgid}
                                        />
                                    </td>
                                ))}
                            </tr>
                        </tbody>
                    </table>
                    <table id="tbl3" class="table">
                        <thead>
                            <tr>
                                <th width="40%">Scenarios</th>
                                {groups.map(group => (
                                    <th key={group.pgid}>{group.group}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{manage_scenarios}</td>
                                {groups.map(group => (
                                    <td>
                                        <input
                                            class="checkbox"
                                            onChange={(e) => onChange(manage_scenarios, e)}
                                            value={getPermission(group.group, manage_scenarios)}
                                            checked={getPermission(group.group, manage_scenarios)}
                                            type="checkbox" name={group.pgid}
                                        />
                                    </td>
                                ))}
                            </tr>
                            <tr>
                                <td>{saiva_lite_scenarios}</td>
                                {groups.map(group => (
                                    <td>
                                        <input
                                            class="checkbox"
                                            onChange={(e) => onChange(saiva_lite_scenarios, e)}
                                            value={getPermission(group.group, saiva_lite_scenarios)}
                                            checked={getPermission(group.group, saiva_lite_scenarios)}
                                            type="checkbox" name={group.pgid}
                                        />
                                    </td>
                                ))}
                            </tr>
                            <tr>
                                <td>{dev_tools_scenarios}</td>
                                {groups.map(group => (
                                    <td>
                                        <input
                                            class="checkbox"
                                            onChange={(e) => onChange(dev_tools_scenarios, e)}
                                            value={getPermission(group.group, dev_tools_scenarios)}
                                            checked={getPermission(group.group, dev_tools_scenarios)}
                                            type="checkbox" name={group.pgid}
                                        />
                                    </td>
                                ))}
                            </tr>
                        </tbody>
                    </table>
                    <table id="tbl4" class="table">
                        <thead>
                            <tr>
                                <th width="40%">System</th>
                                {groups.map(group => (
                                    <th key={group.pgid}>{group.group}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Access admin pages</td>
                                {groups.map(group => (
                                    <td>
                                        <input
                                            class="checkbox"
                                            onChange={(e) => onChange(access_admin, e)}
                                            value={getPermission(group.group, access_admin)}
                                            checked={getPermission(group.group, access_admin)}
                                            type="checkbox" name={group.pgid}
                                        />
                                    </td>
                                ))}
                            </tr>
                            <tr>
                                <td>Access site configuration</td>
                                {groups.map(group => (
                                    <td>
                                        <input
                                            class="checkbox"
                                            onChange={(e) => onChange(access_config, e)}
                                            value={getPermission(group.group, access_config)}
                                            checked={getPermission(group.group, access_config)}
                                            type="checkbox" name={group.pgid}
                                        />
                                    </td>
                                ))}
                            </tr>
                            <tr>
                                <td>Access Log</td>
                                {groups.map(group => (
                                    <td>
                                        <input
                                            class="checkbox"
                                            onChange={(e) => onChange(access_log, e)}
                                            value={getPermission(group.group, access_log)}
                                            checked={getPermission(group.group, access_log)}
                                            type="checkbox" name={group.pgid}
                                        />
                                    </td>
                                ))}
                            </tr>
                        </tbody>
                    </table>
                    <table id="tbl5" class="table">
                        <thead>
                            <tr>
                                <th width="40%">Users</th>
                                {groups.map(group => (
                                    <th key={group.pgid}>{group.group}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Administer users</td>
                                {groups.map(group => (
                                    <td>
                                        <input
                                            class="checkbox"
                                            onChange={(e) => onChange(admin_user, e)}
                                            value={getPermission(group.group, admin_user)}
                                            checked={getPermission(group.group, admin_user)}
                                            type="checkbox" name={group.pgid}
                                        />
                                    </td>
                                ))}
                            </tr>
                            <tr>
                                <td>Admin permissions</td>
                                {groups.map(group => (
                                    <td>
                                        <input
                                            class="checkbox"
                                            onChange={(e) => onChange(admin_permission, e)}
                                            value={getPermission(group.group, admin_permission)}
                                            checked={getPermission(group.group, admin_permission)}
                                            type="checkbox" name={group.pgid}
                                        />
                                    </td>
                                ))}
                            </tr>
                            <tr>
                                <td>{allow_developer_mode}</td>
                                {groups.map(group => (
                                    <td>
                                        <input
                                            class="checkbox"
                                            onChange={(e) => onChange(allow_developer_mode, e)}
                                            value={getPermission(group.group, allow_developer_mode)}
                                            checked={getPermission(group.group, allow_developer_mode)}
                                            type="checkbox" name={group.pgid}
                                        />
                                    </td>
                                ))}
                            </tr>
                        </tbody>
                    </table>
                    <table id="tbl6" class="table">
                        <thead>
                            <tr>
                                <th width="40%">Saica</th>
                                {groups.map(group => (
                                    <th key={group.pgid}>{group.group}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Use Saica</td>
                                {groups.map(group => (
                                    <td>
                                        <input
                                            class="checkbox"
                                            onChange={(e) => onChange(saica, e)}
                                            value={getPermission(group.group, saica)}
                                            checked={getPermission(group.group, saica)}
                                            type="checkbox" name={group.pgid}
                                        />
                                    </td>
                                ))}
                            </tr>
                        </tbody>
                    </table>
                    <table id="tbl7" class="table">
                        <thead>
                            <tr>
                                <th width="40%">Video Tracking</th>
                                {groups.map(group => (
                                    <th key={group.pgid}>{group.group}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Use Video Tracking</td>
                                {groups.map(group => (
                                    <td>
                                        <input
                                            class="checkbox"
                                            onChange={(e) => onChange(use_video_tracking, e)}
                                            value={getPermission(group.group, use_video_tracking)}
                                            checked={getPermission(group.group, use_video_tracking)}
                                            type="checkbox" name={group.pgid}
                                        />
                                    </td>
                                ))}
                            </tr>
                        </tbody>
                    </table>
                    <table id="tbl8" class="table">
                        <thead>
                            <tr>
                                <th width="40%">Change Club</th>
                                {groups.map(group => (
                                    <th key={group.pgid}>{group.group}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Change Club to any club</td>
                                {groups.map(group => (
                                    <td>
                                        <input
                                            class="checkbox"
                                            onChange={(e) => onChange(change_club, e)}
                                            value={getPermission(group.group, change_club)}
                                            checked={getPermission(group.group, change_club)}
                                            type="checkbox" name={group.pgid}
                                        />
                                    </td>
                                ))}
                            </tr>
                        </tbody>
                    </table>
                    <table id="tbl9" class="table">
                        <thead>
                            <tr>
                                <th width="40%">Special Permission</th>
                                {groups.map(group => (
                                    <th key={group.pgid}>{group.group}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Special Permission For SAIVA Lite</td>
                                {groups.map(group => (
                                    <td>
                                        <input
                                            class="checkbox"
                                            onChange={(e) => onChange(special_permission, e)}
                                            value={getPermission(group.group, special_permission)}
                                            checked={getPermission(group.group, special_permission)}
                                            type="checkbox" name={group.pgid}
                                        />
                                    </td>
                                ))}
                            </tr>
                        </tbody>
                    </table>
                    <table id="tbl10" class="table">
                        <thead>
                            <tr>
                                <th width="40%">Upload Scenario</th>
                                {groups.map(group => (
                                    <th key={group.pgid}>{group.group}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Upload Scenario File For Developer</td>
                                {groups.map(group => (
                                    <td>
                                        <input
                                            class="checkbox"
                                            onChange={(e) => onChange(upload_scenario, e)}
                                            value={getPermission(group.group, upload_scenario)}
                                            checked={getPermission(group.group, upload_scenario)}
                                            type="checkbox" name={group.pgid}
                                        />
                                    </td>
                                ))}
                            </tr>
                        </tbody>
                    </table>
                </CardWithoutHead>
            </Col>
        </BasicContent>
    )
}

export default UserPermission