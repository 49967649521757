import React, { useEffect, useState, useRef } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import _ from "lodash";
import { useDispatch } from "react-redux";
import { loginStatus } from "../../../actions/userActions";
import { toast, ToastContainer } from "react-toastify";
import { InputWithErrors } from "../../../components/Basic/Input/InputWithErrors";
import SelectWithErrors from "../../../components/Basic/Select/SelectWithErrors";

const AddUser = () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"))
  const token = userInfo ? userInfo.token : null;
  const history = useHistory();
  const dispatch = useDispatch()

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const [team, setTeam] = useState({
    status: "0",
    name: "",
    email: "",
    theme: "saiva",
    language: "en",
    timezone: "Europe/Berlin",
    password: "",
    confirmPassword: "",
    pgid: "0"
  });
  const [errors, setErrors] = useState([]);

  const [groups, setGroups] = useState([])

  const [passwordType, setPasswordType] = useState("password")
  const [confirmPasswordType, setConfirmPasswordType] = useState("password")

  useEffect(() => {
    const getGroups = async () => {
      const groups = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/group`)
      setGroups(groups.data)
    }
    getGroups()
  }, [])

  useEffect(() => {
    dispatch(loginStatus())
  }, [])

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const save = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/auth/signup`, team, config);

      toast.success('Add New User Success !', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 200,
        onClose: () => {
          history.push("/users/list");
        }
      })
    } catch (error) {
      setErrors(error.response.data);
    }
  };

  const onChange = (e) => {
    if (e.target.name === "status") {
      setTeam({ ...team, status: !team.status })
      return
    }
    setTeam({ ...team, [e.target.name]: e.target.value });
  }

  const changePasswordType = (type) => {
    console.log(type)
    if (type === 'password') {
      if (passwordType === 'text') {
        setPasswordType('password')
      } else {
        setPasswordType('text')
      }
    } else {
      if (confirmPasswordType === 'text') {
        setConfirmPasswordType('password')
      } else {
        setConfirmPasswordType('text')
      }
    }
  }

  return (
    <div className="container-fluid loaded" style={{ display: "block" }}>
      <ToastContainer />
      <div className="row">
        <div className="col-12">
          <div className="page-title-box">
            <div className="page-title-right">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Dashboard</Link>
                </li>
                <li className="breadcrumb-item">Users</li>
                <li className="breadcrumb-item active">Accounts</li>
              </ol>
            </div>
            <h4 className="page-title">Accounts</h4>
          </div>
        </div>
      </div>
      <div className="form-messages"></div>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <h4 className="header-title mb-3">Add</h4>
              <form
                onSubmit={onSubmit}
                className="form-horizontal"
              >
                <fieldset data-select2-id="6">
                  <div className="form-group row">
                    <label className="col-4 col-form-label">Status:</label>
                    <div className="col-8">
                      <input
                        className="form-control"
                        type="checkbox"
                        name="status"
                        onChange={onChange}
                        value={team.status}
                        checked={team.status}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-4 col-form-label">Name:</label>
                    <div className="col-8">
                      <InputWithErrors
                        name="name"
                        errors={errors}
                        onChange={onChange}
                        value={team.name}
                        placeholder="Input User Name ..."
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-4 col-form-label">Email:</label>
                    <div className="col-8">
                      <InputWithErrors
                        name="email"
                        errors={errors}
                        onChange={onChange}
                        value={team.email}
                        placeholder="Input User Email ..."
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-4 col-form-label">Theme:</label>
                    <div className="col-8">
                      <select onChange={onChange} className="custom-select" name="theme">
                        <option value={team.theme}>SAIVA</option>
                      </select>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-4 col-form-label">Language:</label>{" "}
                    <div className="col-8">
                      <select
                        className="custom-select"
                        name="language"
                        value={team.language}
                        onChange={onChange}
                      >
                        <option value="en">
                          English
                        </option>
                        <option value="de">German</option>
                        <option value="nl">Dutch</option>
                      </select>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-4 col-form-label">Timezone:</label>{" "}
                    <div className="col-8">
                      <select
                        className="custom-select"
                        name="timezone"
                        value={team.timezone}
                        onChange={onChange}
                      >
                        <optgroup label="Africa" data-select2-id="9">
                          <option value="Africa/Abidjan" data-select2-id="10">
                            Abidjan
                          </option>
                          <option value="Africa/Accra" data-select2-id="11">
                            Accra
                          </option>
                          <option
                            value="Africa/Addis_Ababa"
                            data-select2-id="12"
                          >
                            Addis_Ababa
                          </option>
                          <option value="Africa/Algiers" data-select2-id="13">
                            Algiers
                          </option>
                          <option value="Africa/Asmara" data-select2-id="14">
                            Asmara
                          </option>
                          <option value="Africa/Asmera" data-select2-id="15">
                            Asmera
                          </option>
                          <option value="Africa/Bamako" data-select2-id="16">
                            Bamako
                          </option>
                        </optgroup>
                        <optgroup label="Etc" data-select2-id="17">
                          <option value="Etc/GMT" data-select2-id="18">
                            GMT
                          </option>
                          <option value="Etc/GMT+0" data-select2-id="19">
                            GMT+0
                          </option>
                          <option value="Etc/GMT+1" data-select2-id="20">
                            GMT+1
                          </option>
                          <option value="Etc/GMT+10" data-select2-id="21">
                            GMT+10
                          </option>
                          <option value="Etc/GMT+11" data-select2-id="22">
                            GMT+11
                          </option>
                          <option value="Etc/GMT+12" data-select2-id="23">
                            GMT+12
                          </option>
                          <option value="Etc/GMT+2" data-select2-id="24">
                            GMT+2
                          </option>
                          <option value="Etc/GMT+3" data-select2-id="25">
                            GMT+3
                          </option>
                          <option value="Etc/GMT+4" data-select2-id="26">
                            GMT+4
                          </option>
                          <option value="Etc/GMT+5" data-select2-id="27">
                            GMT+5
                          </option>
                          <option value="Etc/GMT+6" data-select2-id="28">
                            GMT+6
                          </option>
                          <option value="Etc/GMT+7" data-select2-id="29">
                            GMT+7
                          </option>
                          <option value="Etc/GMT+8" data-select2-id="30">
                            GMT+8
                          </option>
                          <option value="Etc/GMT+9" data-select2-id="31">
                            GMT+9
                          </option>
                          <option value="Etc/GMT-0" data-select2-id="32">
                            GMT-0
                          </option>
                          <option value="Etc/GMT-1" data-select2-id="33">
                            GMT-1
                          </option>
                          <option value="Etc/GMT-10" data-select2-id="34">
                            GMT-10
                          </option>
                          <option value="Etc/GMT-11" data-select2-id="35">
                            GMT-11
                          </option>
                          <option value="Etc/GMT-12" data-select2-id="36">
                            GMT-12
                          </option>
                          <option value="Etc/GMT-13" data-select2-id="37">
                            GMT-13
                          </option>
                          <option value="Etc/GMT-14" data-select2-id="38">
                            GMT-14
                          </option>
                          <option value="Etc/GMT-2" data-select2-id="39">
                            GMT-2
                          </option>
                          <option value="Etc/GMT-3" data-select2-id="40">
                            GMT-3
                          </option>
                          <option value="Etc/GMT-4" data-select2-id="41">
                            GMT-4
                          </option>
                          <option value="Etc/GMT-5" data-select2-id="42">
                            GMT-5
                          </option>
                          <option value="Etc/GMT-6" data-select2-id="43">
                            GMT-6
                          </option>
                          <option value="Etc/GMT-7" data-select2-id="44">
                            GMT-7
                          </option>
                          <option value="Etc/GMT-8" data-select2-id="45">
                            GMT-8
                          </option>
                          <option value="Etc/GMT-9" data-select2-id="46">
                            GMT-9
                          </option>
                          <option value="Etc/GMT0" data-select2-id="47">
                            GMT0
                          </option>
                          <option value="Etc/Greenwich" data-select2-id="48">
                            Greenwich
                          </option>
                          <option value="Etc/UCT" data-select2-id="49">
                            UCT
                          </option>
                          <option value="Etc/Universal" data-select2-id="50">
                            Universal
                          </option>
                          <option value="Etc/UTC" data-select2-id="51">
                            UTC
                          </option>
                          <option value="Etc/Zulu" data-select2-id="52">
                            Zulu
                          </option>
                        </optgroup>
                        <optgroup label="Europe" data-select2-id="53">
                          <option value="Europe/Amsterdam" data-select2-id="54">
                            Amsterdam
                          </option>
                          <option
                            value="Europe/Berlin"
                            data-select2-id="3"
                          >
                            Berlin
                          </option>
                          <option value="Europe/Brussels" data-select2-id="55">
                            Brussels
                          </option>
                        </optgroup>
                      </select>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-4 col-form-label">
                      <span
                        className="help"
                        title="Enter your new password twice if you want to change your current password or leave it blank if you are happy with your current password."
                      >
                        Password
                      </span>
                    </label>
                    <div style={{ position: "relative"}} className="col-4">
                      <InputWithErrors
                        name="password"
                        errors={errors}
                        onChange={onChange}
                        value={team.password}
                        placeholder=""
                        type={passwordType}
                      />
                      <div style={{ position: "absolute", top:0, right:0}} className="input-group-append">
                        <span onClick={() => changePasswordType('password')} style={{ background: "#fff", height: "32px", cursor: "pointer" }} className="input-group-text"><i className={passwordType === "text" ? "fa fa-eye" : "fa fa-eye-slash"}></i></span>
                      </div>
                    </div>
                    <div style={{ position: "relative" }} className="col-4">
                      <InputWithErrors
                        name="confirmPassword"
                        errors={errors}
                        onChange={onChange}
                        value={team.confirmPassword}
                        placeholder=""
                        type={confirmPasswordType}
                      />
                      <div style={{ position: "absolute", top:0, right:0}} className="input-group-append">
                        <span onClick={() => changePasswordType('confirmPassword')} style={{ background: "#fff", height: "32px", cursor: "pointer" }} className="input-group-text"><i className={confirmPasswordType === "text" ? "fa fa-eye" : "fa fa-eye-slash"}></i></span>
                      </div>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-4 col-form-label">Group:</label>{" "}
                    <div className="col-8">
                      <SelectWithErrors
                        name="pgid"
                        value={team.pgid}
                        errors={errors}
                        data={groups}
                        keyPair={{ key: 'pgid', val: 'group' }}
                        onChange={onChange}
                        intialSelect={{ key: '0', val: '--- Select Permission ---' }}
                      />
                    </div>
                  </div>
                  <input type="hidden" name="edit[uid]" value="" />
                </fieldset>
                <button type="submit" className="btn button">
                  <i className="fa fa-fw fa-floppy-o"></i> Save
                </button>
                <Link to="/users/list" className="button-cancel">
                  <i className="fa fa-fw fa-step-backward"></i>&nbsp;Cancel
                </Link>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddUser;
