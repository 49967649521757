import React, { useState, useEffect, Suspense, useContext } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login, loginStatus, loginRequest } from "../../actions/userActions";
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import common_en from "../../translations/en/common.json"
import common_de from "../../translations/de/common.json"
import common_nl from "../../translations/nl/common.json"
import LogoLogin from "../../assets/images/logo-login.png"
import { LangContext } from "../../app";
import axios from 'axios'

i18n
  .use(initReactI18next)
  .init({
    interpolation: { escapeValue: false },  // React already does escaping
    lng: 'en',                              // language to use
    resources: {
      en: {
        common: common_en
      },
      de: {
        common: common_de
      },
      nl: {
        common: common_nl
      }
    },
  });

const ResetPassword = () => {
  const { t } = useTranslation('common')
  const [form, setForm] = useState({
    password: "",
    confirmPassword: "",
    token: "",
    email: ""
  });

  const { token } = useParams()
  const { email } = useParams()

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  const { isAuthenticated, error } = auth;

  const { password, confirmPassword } = form;
  const [errors, setErrors] = useState([])
  const [isShow, setIsShow] = useState()
  const [lango, setLango] = useContext(LangContext)

  const history = useHistory();

  useEffect(() => {
    setForm({...form, token, email})
    if (userInfo !== null) {
      dispatch(loginStatus());
    }
  }, []);

  if (isAuthenticated) {
    history.push("/club");
  }

  const onSubmit = async (e) => {
    try {
      e.preventDefault()
      const user = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/auth/resetPassword`, form)
      history.push('/')
    } catch (error) {
      setErrors(error.response.data)
    }
  };
  const onChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const showLanguage = (e) => {
    e.preventDefault()
    setIsShow(!isShow)
  }

  const getLanguage = (e, lng) => {
    e.preventDefault()
    i18n.changeLanguage(lng)
    setLango({ title: e.target.innerText, flag: lng })
    setIsShow(!isShow)
  }
  return (
    <div className="auth-fluid">
      <div className="auth-fluid-form-box">
        <div className="align-items-center d-flex h-100">
          <div className="card-body">
            <div className="auth-brand text-center text-lg-left">
              <Link to="/" className="logo-dark">
                <span>
                  <img
                    src={LogoLogin}
                    alt=""
                    width="320"
                  />
                </span>
              </Link>
            </div>
            <h4 className="mt-0 text-capitalize">{t('Reset Your Password')}</h4>

            <form onSubmit={onSubmit} style={{ clear: "both" }}>
              <div className="form-group">
                <label htmlFor="password">{t('Password')}</label>
                <input
                  onChange={onChange}
                  className="form-control"
                  type="password"
                  name="password"
                  value={password}
                  id="password"
                  required=""
                  placeholder={t('Enter your new password')}
                  style={{ border: errors.length > 0 ? errors.some(val => { return val.path[0] === "password" }) ? "1px red solid" : "" : "" }}
                />
                {errors.length > 0 ? errors.some((val) => { return val.path[0] === "password" }) && <p class="mt-1" style={{ color: "red", fontWeight: 600, fontSize: "14px" }}><i>{errors.find(val => val.path[0] === "password").message}</i></p> : ""}
              </div>
              <div className="form-group">
                <label htmlFor="confirmPassword">{t('Confirm Password')}</label>
                <input
                  onChange={onChange}
                  value={confirmPassword}
                  className="form-control"
                  type="password"
                  name="confirmPassword"
                  required=""
                  id="confirmPassword"
                  placeholder={t('Confirm your new password')}
                  style={{ border: errors.length > 0 ? errors.some(val => { return val.path[0] === "confirmPassword" }) ? "1px red solid" : "" : ""}}
                />
                {errors.length > 0 ? errors.some((val) => { return val.path[0] === "confirmPassword" }) && <p class="mt-1" style={{ color: "red", fontWeight: 600, fontSize: "14px" }}><i>{errors.find(val => val.path[0] === "confirmPassword").message}</i></p> : ""}
              </div>
              <div className="form-group mb-0 text-center">
                <button
                  className="btn btn-primary btn-block"
                  name="op"
                  value="Login"
                  type="submit"
                  style={{ marginTop : '20px'}}
                >
                  <i className="fa fa-sign-in"></i> {t('Reset Password')}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
