const SelectWithErrors = ({ name, value, errors, data, keyPair, onChange, disabled=false, style={},errorStyle={}, className="", intialSelect = { key: "", val: '' } }) => {
    const { key, val } = keyPair

    return (
        <>
            <select
                disabled={disabled}
                className={`custom-select ${className}`}
                name={name}
                value={value}
                onChange={onChange}
                style={{...style, border: errors.length > 0 ? errors.some(val => { return val.path[0] === name }) ? "1px red solid" : "" : "" }}
            >
                <option value={intialSelect.key}>{intialSelect.val}</option>
                {data &&
                    data.map((dta, index) => (
                        <option
                            key={index}
                            value={dta[key]}
                        >
                            {dta[val]}
                        </option>
                    ))}
            </select>
            {errors.length > 0 ? errors.some((val) => { return val.path[0] === name }) && <p class="mt-1" style={{color: "red", fontWeight: 600, fontSize: "14px", ...errorStyle }}><i>{errors.find(val => val.path[0] === name).message}</i></p> : ""}
        </>
    )
}

export default SelectWithErrors