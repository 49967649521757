import React, { useContext, useEffect} from 'react'
import { Link } from 'react-router-dom'
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import common_en from "../../translations/en/common.json"
import common_de from "../../translations/de/common.json"
import common_nl from "../../translations/nl/common.json"
import { LangContext } from '../../app';
import { useDispatch } from 'react-redux';
import { login, loginStatus } from '../../actions/userActions';

i18n
  .use(initReactI18next)
  .init({
    interpolation: { escapeValue: false },  // React already does escaping
    lng: 'en',                              // language to use
    resources: {
      en: {
        common: common_en
      },
      de: {
        common: common_de
      },
      nl: {
        common: common_nl
      }
    },
  });

const Documentation = () => {
  const { t } = useTranslation('common')
  const [lango, setLango] = useContext(LangContext)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(loginStatus())
  }, [])

  return (
    <div className="container-fluid loaded" style={{ display: 'block' }}>
      <div className="row">
        <div className="col-12">
          <div className="page-title-box">
            <div className="page-title-right">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link href="/">{t('ADMIN_HOME')}</Link>
                </li>
                <li className="breadcrumb-item">{t('Support')}
                </li>
                <li className="breadcrumb-item active">{t('Documentation')}
                </li>
              </ol>
            </div>
            <h4 className="page-title">{t('Documentation')}</h4>
          </div>
        </div>
      </div>
      <div className="row"><div className="col-12">
        <div className="card">
          <div className="card-body">
            <h4 className="header-title mb-3">{t('SAIVA DOCUMENTATION')}</h4>
            {t('DocumentationText')}<br />
            <br />
            <a 
              href={`${process.env.REACT_APP_BACKEND_URL}/${lango.flag === "en" ? "download/documentation/SAIVA.en.pdf": "download/documentation/SAIVA.de.pdf"}`} download target="_blank">
              {t('DocumentationDownloadText')}
            </a>
          </div>
        </div>
      </div>
      </div>
    </div>
  )
}

export default Documentation