import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios"
import { useDispatch } from "react-redux";
import { loginStatus } from "../../../actions/userActions";
import { toast, ToastContainer } from "react-toastify";
import SelectWithErrors from '../../../components/Basic/Select/SelectWithErrors'

const AddMember = () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"))
  const token = userInfo !== null ? userInfo.token : null
  const history = useHistory()
  const dispatch = useDispatch()

  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
  const [data, setData] = useState({ clubs: [], userNonMember: [] })
  const [member, setMember] = useState({ club_id: "0", uid: "0", access_level: "0" })
  const [errors, setErrors] = useState([])

  useEffect(() => {
    dispatch(loginStatus())
  }, [])

  useEffect(() => {
    const getData = async () => {
      const users = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/auth/nonMemberUser`, config)
      const clubs = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/club?search=&page=1&limit=10000000`, config)
      setData({ clubs: clubs.data.data.clubs, userNonMember: users.data })
    }
    getData()
  }, [])
  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const save = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/club/member`, member, config)

      toast.success('Add Member Success !', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 200,
        onClose: () => {
          history.push('/manageclub/members')
        }
      })
    } catch (error) {
      setErrors(error.response.data)
    }
  }

  const onChange = (e) => {
    setErrors({ ...errors, [e.target.name]: null })
    setMember({ ...member, [e.target.name]: e.target.value })
  }

  return (
    <div className="container-fluid loaded" style={{ display: "block" }}>
      <ToastContainer />
      <div className="row">
        <div className="col-12">
          <div className="page-title-box">
            <div className="page-title-right">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Dashboard</Link>
                </li>
                <li className="breadcrumb-item">Manage Clubs</li>
                <li className="breadcrumb-item active">Members</li>
              </ol>
            </div>
            <h4 className="page-title">Members</h4>
          </div>
        </div>
      </div>
      <div className="form-messages"></div>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <form
                onSubmit={onSubmit}
              >
                <fieldset>
                  <legend>Add</legend>
                    {(errors.length > 0 && errors.some(error => error.path[0] == 'member_licenses') ? (<div class="alert alert-danger" role="alert"><b>This Club has no licenses left</b></div>) : '')}
                  <div className="form-group row">
                    <label className="col-4 col-form-label">Club:</label>{" "}
                    <div className="col-8">
                      <SelectWithErrors
                        name="club_id"
                        value={member.club_id}
                        errors={errors}
                        data={data.clubs}
                        keyPair={{ key: 'club_id', val: 'name' }}
                        onChange={onChange}
                        intialSelect={{ key: '0', val: '--- Select Clubs ---' }}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-4 col-form-label">User:</label>{" "}
                    <div className="col-8">
                      <SelectWithErrors
                        name="uid"
                        value={member.uid}
                        errors={errors}
                        data={data.userNonMember}
                        keyPair={{ key: 'uid', val: 'name' }}
                        onChange={onChange}
                        intialSelect={{ key: '0', val: '--- Select User ---' }}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-4 col-form-label">Access level:</label>{" "}
                    <div className="col-8">
                      <select
                        className="custom-select select2"
                        name="access_level"
                        onChange={onChange}
                      >
                        <option value="0">
                          Player
                        </option>
                        <option value="70">Assistent</option>
                        <option value="80">Coach</option>
                        <option value="100">Manager</option>
                      </select>
                    </div>
                  </div>
                </fieldset>
                <button type="submit" className="btn button">
                  <i className="fa fa-fw fa-floppy-o"></i> Save
                </button>
                <Link
                  to="/manageclub/members"
                  className="button-cancel"
                >
                  <i className="fa fa-fw fa-step-backward"></i>&nbsp;Cancel
                </Link>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddMember;
