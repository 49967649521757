import React, { useState, useEffect, Suspense, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login, loginStatus, loginRequest } from "../../actions/userActions";
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import common_en from "../../translations/en/common.json"
import common_de from "../../translations/de/common.json"
import common_nl from "../../translations/nl/common.json"
import LogoLogin from "../../assets/images/logo-login.png"
import { LangContext } from "../../app";
import axios from 'axios'

i18n
  .use(initReactI18next)
  .init({
    interpolation: { escapeValue: false },  // React already does escaping
    lng: 'en',                              // language to use
    resources: {
      en: {
        common: common_en
      },
      de: {
        common: common_de
      },
      nl: {
        common: common_nl
      }
    },
  });

const ForgotPassword = () => {
  const { t } = useTranslation('common')
  const [form, setForm] = useState({
    email: "",
  });

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  const { isAuthenticated, error } = auth;

  const { email } = form;
  const [errors, setErrors] = useState([])
  const [lango, setLango] = useContext(LangContext)

  const history = useHistory();

  useEffect(() => {
    if (userInfo !== null) {
      dispatch(loginStatus());
    }
  }, []);

  if (isAuthenticated) {
    history.push("/club");
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    try {
      const sendEmail = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/auth/sendpassword`, form)
      history.push('/')
    } catch (error) {
      setErrors(error.response.data)
    }
  };
  const onChange = (e) => {
    if(errors){
      setErrors([])
    }
    setForm({ ...form, [e.target.name]: e.target.value });
  };
  const closeAlert = () => {
    dispatch(loginRequest())
  }

  return (
    <div className="auth-fluid">
      <div className="auth-fluid-form-box">
        <div className="align-items-center d-flex h-100">
          <div className="card-body">
            <div className="auth-brand text-center text-lg-left">
              <a href="index.html" className="logo-dark">
                <span>
                  <img
                    src={LogoLogin}
                    alt=""
                    width="320"
                  />
                </span>
              </a>
            </div>
            <h4 className="mt-0 text-capitalize">{t('Reset Your Password')}</h4>
       
            <form onSubmit={onSubmit} style={{ clear: "both" }}>
              <div className="form-group">
                <label htmlFor="emailaddress">{t('Email')}</label>
                <input
                  onChange={onChange}
                  className="form-control"
                  type="email"
                  name="email"
                  value={email}
                  id="emailaddress"
                  required=""
                  style={{ border: errors.length > 0 ? errors.some(val => { return val.path[0] === "email" }) ? "1px red solid" : "" : "" }}
                  placeholder={t('Enter your email')}
                />
                {errors.length > 0 ? errors.some((val) => { return val.path[0] === "email" }) && <p class="mt-1" style={{ color: "red", fontWeight: 600, fontSize: "14px" }}><i>{errors.find(val => val.path[0] === "email").message}</i></p> : ""}
              </div>

              <div className="form-group mb-0 text-center">
                <button
                  className="btn btn-primary btn-block"
                  name="op"
                  value="Login"
                  type="submit"
                  style={{ marginTop:'40px'}}
                >
                  <i className="fa fa-sign-in"></i> {t('Reset Password')}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
