import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";

const UserAccount = () => {
  const userId = JSON.parse(localStorage.getItem("userInfo")).user_id;
  const token = JSON.parse(localStorage.getItem("userInfo")).token;

  const [user, setUser] = useState({
    name: "",
    email: "",
    theme: "",
    language: "",
    timezone: "",
    password: null,
    confirmPassword: null,
  });

  const config = {
    headers: {
      Accept: "multipart/form-data",
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  };

  const [file, setFile] = useState();
  const [previewUrl, setPreviewUrl] = useState();
  const [errors, setErrors] = useState();

  const history = useHistory();

  useEffect(() => {
    if (!file) {
      return;
    }
    const fileReader = new FileReader();

    fileReader.onload = () => {
      setPreviewUrl(fileReader.result);
    };
    fileReader.readAsDataURL(file);
  }, [file]);

  useEffect(() => {
    const getUserById = async () => {
      const user = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/auth/${userId}`
      );
      setUser(user.data);
      setPreviewUrl(process.env.REACT_APP_BACKEND_URL + "" + user.data.image);
    };

    getUserById();
  }, []);

  const onChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const uploadFile = (e) => {
    if (e.target.files && e.target.files.length === 1) {
      setFile(e.target.files[0]);
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();

      formData.append("file", file);
      formData.append("name", user.name);
      formData.append("email", user.email);
      formData.append("theme", user.theme);
      formData.append("language", user.language);
      formData.append("timezone", user.timezone);
      !user.password ? "" : formData.append("password", user.password);
      !user.confirmPassword
        ? ""
        : formData.append("confirmPassword", user.confirmPassword);

      const updateUser = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/auth/${userId}`,
        formData,
        config
      );
      history.push("/club");
    } catch (error) {
      setErrors(error)
    }
  };

  return (
    <div class="container-fluid loaded" style={{ display: "block" }}>
      <div class="row">
        <div class="col-12">
          <div class="page-title-box">
            <div class="page-title-right">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <Link to="/">Dashboard</Link>
                </li>
                <li class="breadcrumb-item">Users</li>
                <li class="breadcrumb-item active">My account</li>
              </ol>
            </div>
            <h4 class="page-title">My account</h4>
          </div>
        </div>
      </div>

      <div class="form-messages"></div>
      <div class="row">
        <div class="col-12 col-sm-8">
          <div class="card">
            <div class="card-body">
              <form onSubmit={onSubmit} class="form-horizontal">
                <fieldset>
                  <legend>Edit account: {user.name}</legend>
                  <div class="form-group row">
                    <label class="col-4 col-form-label">Name:</label>
                    <div class="col-8">
                      <input
                        class="form-control"
                        maxlength="64"
                        name="name"
                        type="text"
                        onChange={onChange}
                        value={user.name}
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-4 col-form-label">Email:</label>
                    <div class="col-8">
                      <input
                        readonly=""
                        class="form-control"
                        maxlength="64"
                        name="email"
                        type="text"
                        onChange={onChange}
                        value={user.email}
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-4 col-form-label">Theme:</label>
                    <div class="col-8">
                      <select
                        onChange={onChange}
                        value={user.theme}
                        class="custom-select select2"
                        name="theme"
                      >
                        <option value="saiva" selected="selected">
                          SAIVA
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-4 col-form-label">Language:</label>
                    <div class="col-8">
                      <select
                        class="custom-select select2"
                        name="language"
                        value={user.language}
                        onChange={onChange}
                      >
                        <option value="en">English</option>
                        <option value="de">German</option>
                        <option value="nl">Dutch</option>
                      </select>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-4 col-form-label">Timezone:</label>
                    <div class="col-8">
                      <select
                        data-toggle="select2"
                        class="custom-select"
                        name="timezone"
                        value={user.timezone}
                        onChange={onChange}
                        tabindex="-1"
                        aria-hidden="true"
                      >
                        <optgroup label="Africa">
                          <option value="Africa/Abidjan">Abidjan</option>
                          <option value="Africa/Accra">Accra</option>
                          <option value="Africa/Addis_Ababa">
                            Addis_Ababa
                          </option>
                          <option value="Africa/Algiers">Algiers</option>
                          <option value="Africa/Asmara">Asmara</option>
                          <option value="Africa/Asmera">Asmera</option>
                          <option value="Africa/Bamako">Bamako</option>
                        </optgroup>
                        <optgroup label="Etc">
                          <option value="Etc/GMT">GMT</option>
                          <option value="Etc/GMT+0">GMT+0</option>
                          <option value="Etc/GMT+1">GMT+1</option>
                          <option value="Etc/GMT+10">GMT+10</option>
                          <option value="Etc/GMT+11">GMT+11</option>
                          <option value="Etc/GMT+12">GMT+12</option>
                          <option value="Etc/GMT+2">GMT+2</option>
                          <option value="Etc/GMT+3">GMT+3</option>
                          <option value="Etc/GMT+4">GMT+4</option>
                          <option value="Etc/GMT+5">GMT+5</option>
                          <option value="Etc/GMT+6">GMT+6</option>
                          <option value="Etc/GMT+7">GMT+7</option>
                          <option value="Etc/GMT+8">GMT+8</option>
                          <option value="Etc/GMT+9">GMT+9</option>
                          <option value="Etc/GMT-0">GMT-0</option>
                          <option value="Etc/GMT-1">GMT-1</option>
                          <option value="Etc/GMT-10">GMT-10</option>
                          <option value="Etc/GMT-11">GMT-11</option>
                          <option value="Etc/GMT-12">GMT-12</option>
                          <option value="Etc/GMT-13">GMT-13</option>
                          <option value="Etc/GMT-14">GMT-14</option>
                          <option value="Etc/GMT-2">GMT-2</option>
                          <option value="Etc/GMT-3">GMT-3</option>
                          <option value="Etc/GMT-4">GMT-4</option>
                          <option value="Etc/GMT-5">GMT-5</option>
                          <option value="Etc/GMT-6">GMT-6</option>
                          <option value="Etc/GMT-7">GMT-7</option>
                          <option value="Etc/GMT-8">GMT-8</option>
                          <option value="Etc/GMT-9">GMT-9</option>
                          <option value="Etc/GMT0">GMT0</option>
                          <option value="Etc/Greenwich">Greenwich</option>
                          <option value="Etc/UCT">UCT</option>
                          <option value="Etc/Universal">Universal</option>
                          <option value="Etc/UTC">UTC</option>
                          <option value="Etc/Zulu">Zulu</option>
                        </optgroup>
                        <optgroup label="Europe">
                          <option value="Europe/Amsterdam">Amsterdam</option>
                          <option
                            value="Europe/Berlin"
                            selected="selected"
                            data-select2-id="3"
                          >
                            Berlin
                          </option>
                          <option value="Europe/Brussels">Brussels</option>
                        </optgroup>
                      </select>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-4 col-form-label">
                      <span
                        class="help"
                        title="Enter your new password twice if you want to change your current password or leave it blank if you are happy with your current password."
                      >
                        Password
                      </span>
                    </label>{" "}
                    <div class="col-4">
                      <input
                        class="form-control"
                        type="password"
                        name="password"
                        value={user.password}
                        onChange={onChange}
                      />
                    </div>
                    <div class="col-4">
                      <input
                        class="form-control"
                        type="password"
                        name="confirmPassword"
                        onChange={onChange}
                        style={{ border: errors && errors.response.data.confirmPassword ? '1px red solid' : ''}}
                      />
                      {errors && <p style={{color: 'red', marginTop:'8px'}}>{errors.response.data.confirmPassword}</p> }
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-4 col-form-label">Profile image:</label>{" "}
                    <div class="col-8">
                      <input onChange={uploadFile} type="file" name="image" />
                    </div>
                  </div>
                </fieldset>
                <button type="submit" class="btn button">
                  <i class="fa fa-fw fa-floppy-o"></i> Save
                </button>
                <Link to="/" class="button-cancel">
                  <i class="fa fa-fw fa-step-backward"></i>&nbsp;Cancel
                </Link>
              </form>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-4">
          <div class="card">
            <div class="card-body">
              <img
                src={previewUrl}
                style={{ width: "480x", objectFit: "fill" }}
                class="img-fluid rounded"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserAccount;
