import React, { useState, useEffect, useContext } from 'react'
//import menus from '../../data/SidebarMenu.json'
import { Link } from 'react-router-dom'
import './Sidebar.css'
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import common_en from "../../translations/en/common.json"
import common_de from "../../translations/de/common.json"
import common_nl from "../../translations/nl/common.json"
import { LangContext } from '../../app';
import Tooltip from '@mui/material/Tooltip';

i18n
  .use(initReactI18next)
  .init({
    interpolation: { escapeValue: false },  // React already does escaping
    lng: "en",                              // language to use
    resources: {
      en: {
        common: common_en
      },
      de: {
        common: common_de
      },
      nl: {
        common: common_nl
      }
    },
  });

const Sidebar = ({ menus = [] }) => {
  const { t } = useTranslation('common')

  const [lango, setLango] = useContext(LangContext)

  const [userGroup, setUserGroup] = useState(1)

  const userInfo = JSON.parse(localStorage.getItem('userInfo'))

  const pgid = userInfo ? userInfo.pgid : ''

  useEffect(() => {
    i18n.changeLanguage(lango.flag)
    setUserGroup(pgid)
  }, [lango])


  const menuInitialState = {};

  const subMenuInitialState = { 'modules': false, 'site settings': false }

  menus.map(item => {
    menuInitialState[item.name.toLocaleLowerCase()] = false;
  })

  const [statusSubMenu, setStatusSubMenu] = useState(menuInitialState);

  const [statusSecondSubMenu, setStatusSecondSubMenu] = useState(subMenuInitialState);


  const [subMenuTwo, setSubMenuTwo] = useState(false);

  const openClose = (item) => {
    setStatusSubMenu({ ...menuInitialState, [item]: !statusSubMenu[item] })
  }

  const openCloseSecondSubMenu = (item) => {
    setStatusSecondSubMenu({ ...subMenuInitialState, [item]: !statusSecondSubMenu[item] })
  }

  useEffect(() => {
    window.addEventListener('resize', function () {
      if (this.window.innerWidth < 1024 && this.window.innerWidth > 768) {
        document.body.setAttribute('data-leftbar-compact-mode', 'condensed')
      } else if (this.window.innerWidth >= 1024) {
        this.document.body.removeAttribute('data-leftbar-compact-mode')
      } else if (this.window.innerWidth <= 768) {
        if (this.document.body.hasAttribute('data-leftbar-compact-mode')) {
          this.document.body.removeAttribute('data-leftbar-compact-mode')
        }
      }
    })
  }, [])

  const [hover, setHover] = useState({set_1:false})

 
  const mouseOver = (index) => {
    if(document.body.hasAttribute('data-leftbar-compact-mode')){
      setHover({...hover, [`set_${index}`] : true})
    }
  }

  const mouseLeave = (index) => {
    if(document.body.hasAttribute('data-leftbar-compact-mode')){
      setHover({...hover, [`set_${index}`] : false})
    }
  }

  return (
    <div className="left-side-menu mm-show">
      <Link to="#" className="logo text-center logo-light">
        <span className="logo-lg">
          <img src={`${process.env.REACT_APP_BACKEND_URL}/images/logo-light.png`} alt="" height="64" />
        </span>
        <span className="logo-sm">
          <img src={`${process.env.REACT_APP_BACKEND_URL}/images/logo-sm.png`} alt="" width="48" />
        </span>
      </Link>
      <div className="h-100 mm-active" id="left-side-menu-container" data-simplebar="init">
        <div className="simplebar-wrapper" style={{ margin: '0px' }}>
          <div className="simplebar-height-auto-observer-wrapper">
            <div className="simplebar-height-auto-observer"></div>
          </div>
          <div className="simplebar-mask">
            <div className="simplebar-offset" style={{ right: '0px', bottom: '0px' }}>
              <div className="simplebar-content-wrapper" style={{ height: '100%', overflow: 'hidden scroll' }}>
                <div className="simplebar-content" style={{ padding: '0px' }}>
                  <ul className="metismenu2 side-nav metismenu">
                    {/* <li className="side-nav-title side-nav-item">Navigation</li> */}
                    {/* Menu Will Be Here */}
                    {menus.map((menu, index) => {
                      if (menu.type === 'separator') {
                        if (menus.some(mn => mn.group === menu.name && mn.status != 'notready')) {
                          return (
                            <li num={index} key={index} className="side-nav-title side-nav-item">{menu.name}</li>
                          )
                        }
                      }
                      else {
                        if (menu.status !== 'notready') {
                          return (
                            <li style={{ overflow: hover[`set_${index}`] ? 'visible' : 'hidden'}} onMouseLeave={() => mouseLeave(index)} onMouseOver={()=>mouseOver(index)} num={index} key={index} className={`side-nav-item ${statusSubMenu[menu.name.toLocaleLowerCase()] ? 'mm-active' : ''}`}>

                              <Link to={menu.url} onClick={() => openClose(menu.name.toLocaleLowerCase())} className="side-nav-link">
                                <i className={menu.icon}></i>
                                {menu.submenu ? <span className="menu-arrow"></span> : ''}
                                <span>{t(menu.name)}</span>
                                {menu.tooltips && <Tooltip title={menu.tooltips.message} placement='right-start'>
                                  <i className="fa fa-question-circle-o float-right"></i>
                                </Tooltip>}
                              </Link>

                              {menu.submenu ? (
                                <ul className={`
                                side-nav-second-level ${statusSubMenu[menu.name.toLocaleLowerCase()] ?
                                    statusSubMenu['config'] === true ?
                                      statusSecondSubMenu['modules'] || statusSecondSubMenu['site settings'] ?
                                        `menu-open-${menu.submenu.length}` :
                                        `menu-open-${menu.submenu.length}` :
                                      `menu-open-${menu.submenu.length}` :
                                    'menu-close'}`} aria-expanded="false"
                                >
                                  {menu.submenu.map((submenu, index2) => (
                                    <li key={index2} className="side-nav-item">
                                      <Link to={submenu.url} onClick={() => openCloseSecondSubMenu(submenu.name.toLocaleLowerCase())} className="">
                                        <i className={submenu.icon ? submenu.icon : ''}></i>
                                        {submenu.secondSubmenu ? <span className="menu-arrow"></span> : ''}
                                        <span>{t(submenu.name)}</span>

                                      </Link>
                                      {submenu.secondSubmenu ? (
                                        <ul className={`side-nav-third-level ${statusSecondSubMenu[submenu.name.toLocaleLowerCase()] ? 'menu-open-4' : 'menu-close'}`} aria-expanded="false">
                                          {submenu.secondSubmenu.map((secondSubMenu, index3) => (
                                            <li key={index3} className="side-nav-item">
                                              <Link to={secondSubMenu.url}>
                                                <span>{secondSubMenu.name}</span>
                                              </Link>
                                            </li>
                                          ))}
                                        </ul>
                                      ) : ''}
                                    </li>
                                  ))}
                                </ul>
                              )
                                : ''}
                            </li>
                          )
                        }
                      }
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="simplebar-track simplebar-horizontal" style={{ visibility: 'hidden' }}>
          <div className="simplebar-scrollbar"
            style={{ width: '0px', display: 'none', transform: 'translate3d(0px, 0px, 0px)' }}></div>
        </div>

        <div className="simplebar-track simplebar-vertical" style={{ visibility: 'visible' }}>
          <div className="simplebar-scrollbar"
            style={{ height: '392px', transform: 'translate3d(0px, 0px, 0px)', display: 'block' }}></div>
        </div>
      </div>
    </div>
  )
}

export default Sidebar