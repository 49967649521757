import React, { createContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Link, Switch, Route, BrowserRouter as Router } from "react-router-dom";
import Sidebar from "../components/Sidebar/Sidebar";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import Dashboard from "./Dashboard";
import Club from "./Club";
import EditClubTeam from "./Club/edit";
import Scenarios from "./Scenarios/Scenarios";
import EditScenarios from "./Scenarios/Edit";
import AddScenario from "./Scenarios/Add";
import Documentation from "./Support/documentation";
import Download from "./Support/download";
import AddVideo from "./VideoTrackingAI/addvideo";
import ViewTasks from "./VideoTrackingAI/viewtasks";
import ManagerServer from "./VideoTracking/manageServer";
import EditVideoTrackingServer from "./VideoTracking/editServer";
import AddVideoTrackingServer from "./VideoTracking/addServer";
import EditClubMember from "./Club/editClubMember";
import ListClubs from "./ManageClubs/clubs/list";
import EditClub from "./ManageClubs/clubs/edit";
import AddClub from "./ManageClubs/clubs/add";
import ListMembers from "./ManageClubs/members/list";
import AddMember from "./ManageClubs/members/add";
import EditMember from "./ManageClubs/members/edit";
import ListTeams from "./ManageClubs/teams/list";
import AddTeam from "./ManageClubs/teams/add";
import EditTeam from "./ManageClubs/teams/edit";
import ListUsers from "./Users/account/list";
import AddUser from "./Users/account/add";
import EditUser from "./Users/account/edit";
import UserAccount from "./Users/account";
import Changes from "./Changelog/changes";
import UserActivity from "./Users/activity/activityList";
import UserActivityList from "./Users/activity/userList";
import BotPlatform from "./BotPlatform/botPlatform";
import Group from "./Users/group/list";
import AddGroup from "./Users/group/add";
import EditGroup from "./Users/group/edit";
import UserPermission from "./Users/permission/permission";
import ChangeClub from "./ChangeClub";
import AIPages from "./AI/aipages";
import SpecialPermission from "./specialPermission";
import UploadScenario from "./Scenarios/Upload";
import ScenarioEditor from "./Scenarios/Editor";
import { useDispatch, useSelector } from "react-redux";
import { logout, loginStatus } from "../actions/userActions";
import axios from "axios";

export const clubIDContext = createContext()

const Admin = () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"))
  const clubID = userInfo !== null ? userInfo.club_id : ""
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const { isAuthenticated, error } = auth
  const history = useHistory();
  const [sidebarMenu, setSidebarMenu] = useState([])
  const token = userInfo !== null ? userInfo.token : ''
  const [adminClub, setAdminClub] = useState({original: clubID, spy: clubID})

  const config = {
    headers : {
      'Content-Type' : 'application/json',
      'Authorization' : `Bearer ${token}`
    }
  }

  const logoutHandler = (e) => {
    e.preventDefault();
    dispatch(logout());
    history.push("/login")
  };
  useEffect(() => {
    if(userInfo === null){
      history.push('/login')
    }

    dispatch(loginStatus())

  }, [error]);

  useEffect(() => {
    const getMenu = async () => {
      const menu = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/settings/menu`, config)
      setSidebarMenu(menu.data)
    }
    getMenu()
  }, [])
  
  return (
  <Router>
    {<div className="wrapper mm-active">
      <Sidebar menus={sidebarMenu} />
      <div className="content-page">
        <div className="content">
          <Header userInfo={userInfo} logoutHandler={logoutHandler} />
          <clubIDContext.Provider value={[adminClub, setAdminClub]}>
          <Switch>
            <Route exact path="/" component={Dashboard} />
            <Route exact path="/club" component={Club} />
            <Route exact path="/club/team/:id" component={EditClubTeam} />
            <Route exact path="/club/editmember/:id" component={EditClubMember} />
            <Route exact path="/scenarios" component={Scenarios} />
            <Route exact path="/scenarios/add/:folderID" component={AddScenario} />
            <Route exact path="/scenarios/edit/:id" component={EditScenarios} />
            <Route exact path="/scenarios/edit/editor/:id" component={ScenarioEditor} />
            <Route exact path="/support/documentation" component={Documentation} />
            <Route exact path="/support/downloads" component={Download} />
            <Route exact path="/videotracking/add" component={AddVideo} />
            <Route exact path="/videotracking/tasks" component={ViewTasks} />
            <Route exact path="/videotracking.admin/servers" component={ManagerServer} />
            <Route exact path="/videotracking.admin/servers/add" component={AddVideoTrackingServer} />
            <Route exact path="/videotracking.admin/servers/:id" component={EditVideoTrackingServer} />
            <Route exact path="/manageclub/clubs" component={ListClubs} />
            <Route exact path="/manageclub/clubs/add" component={AddClub} />
            <Route exact path="/manageclub/clubs/:id" component={EditClub} />
            <Route exact path="/manageclub/members" component={ListMembers} />
            <Route exact path="/manageclub/members/add" component={AddMember} />
            <Route exact path="/manageclub/members/:id" component={EditMember} />
            <Route exact path="/manageclub/teams" component={ListTeams} />
            <Route exact path="/manageclub/teams/add" component={AddTeam} />
            <Route exact path="/manageclub/teams/:id" component={EditTeam} />
            <Route exact path="/users/list" component={ListUsers} />
            <Route exact path="/users/group" component={Group} />
            <Route exact path="/users/activity" component={UserActivityList} />
            <Route exact path="/users/activity/list" component={UserActivity} />
            <Route exact path="/users/group/add" component={AddGroup} />
            <Route exact path="/users/group/edit/:id" component={EditGroup} />
            <Route exact path="/users/add" component={AddUser} />
            <Route exact path="/users/account" component={UserAccount} />
            <Route exact path="/users/edit/:id" component={EditUser} />
            <Route exact path="/users/permissions" component={UserPermission} />
            <Route exact path="/changelog/changes" component={Changes} />
            <Route exact path="/botplatform" component={BotPlatform} />
            <Route exact path="/saica" component={AIPages} />
            <Route exact path="/changeclub" component={ChangeClub} />
            <Route exact path="/specialpermission" component={SpecialPermission} />
            <Route exact path="/uploadscenario" component={UploadScenario} />
          </Switch>
          </clubIDContext.Provider>
        </div>
        <Footer />
      </div>
    </div>}
    </Router>
  );
};

export default Admin;
