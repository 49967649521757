import axios from "axios";
import {
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_LOGIN_STATUS
} from "../constant/userConstant";

export const login = (email, password) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/v1/auth/login`,
      { email, password },
      config
    );

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    });

    localStorage.setItem("userInfo", JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload: error.response,
    });
  }
};

export const loginStatus = () => async (dispatch) => {
  try {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    const token = userInfo.token

    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    }
    
    const user = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/auth/confirm`, {}, config)
    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: userInfo
    });
  } catch (error) {
    localStorage.removeItem("userInfo");
    dispatch({
      type: USER_LOGIN_FAIL,
      payload: { data: [] },
    });
  }
};

export const logout = () => (dispatch) => {
  localStorage.removeItem("userInfo");
  dispatch({ type: USER_LOGOUT });
};

export const authFail = () => (dispatch) => {
  localStorage.removeItem("userInfo");
  dispatch({
    type: USER_LOGIN_FAIL,
    payload: { data: [] },
  });
}

export const loginRequest = () => (dispatch) => {
  dispatch({ type: USER_LOGIN_REQUEST })
}
