import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import { RotatingLines } from "react-loader-spinner";
import { loginStatus } from "../../../actions/userActions";
import "./index.css"

const ListMembers = () => {
  const position = {
    "0": "Player",
    "70": "Assistant",
    "80": "Coach",
    "100": "Manager"
  }
  // Get User Info from local storage
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  // Get jsonwebtoken from browser storage
  const token = userInfo !== null ? userInfo.token : null
  // State for how many page on the table list
  const [pageNumber, setPageNumber] = useState([])
  // State to show which page of table we are now
  const [currentPage, setCurrentPage] = useState(1)
  // State for activate which page is active and will get blue background
  const [isActive, setIsActive] = useState({ ods_1: true })
  // Pagination setting will send to backend as url query parameter to get club data
  const [pagination, setPagination] = useState({
    search: '',
    page: 1,
    limit: 20
  })

  const { search, page, limit } = pagination

  const dispatch = useDispatch()

  // State as container or variable to receipt the club data from backend
  const [members, setMembers] = useState([])

  // Config headers for send to backend
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }

  // Delete Club State
  const [deleteConfirmation, setDeleteConfirmation] = useState(false)

  // Club Data State
  const [clubMemberData, setClubMemberData] = useState({ clubId: '', uid: '', clubName: '', userName: '' })

  const [isUpdated, setIsUpdated] = useState(false)

  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const getMembers = async () => {
      try {
        setIsLoading(true)
        const members = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/club/member?search=${search}&page=${page}&limit=${limit}`, config)
        setPageNumber(Array(Math.ceil(members.data.count / limit)).fill(0))
        setMembers(members.data.members)
        setTimeout(() => {
          setIsLoading(false)
        }, 100);
      } catch (error) {
        if (error.response.status === 401) {
          dispatch(loginStatus())
        }
      }
    }
    getMembers()
  }, [search, currentPage, isUpdated])

  const pageOnClick = (val) => {
    setCurrentPage(val)
    setPagination({ ...pagination, page: val })
    setIsActive({ [`ods_${val}`]: true })
  }

  // This function is for move forward when we click Next on pagination
  const onNextPage = () => {
    if (currentPage === pageNumber.length) {
      return
    }
    setCurrentPage(currentPage + 1)
    setPagination({ ...pagination, page: currentPage + 1 })
    setIsActive({ [`ods_${currentPage + 1}`]: true })
  }

  // This function is for move backforward when we click Previous on pagination
  const onPrevPage = () => {
    if (currentPage === 1) {
      return
    }
    setCurrentPage(currentPage - 1)
    setPagination({ ...pagination, page: currentPage - 1 })
    setIsActive({ [`ods_${currentPage - 1}`]: true })
  }

  const onChange = (e) => {
    setCurrentPage(1);
    setIsActive({ "ods_1": true })
    setPagination({ ...pagination, page: 1, search: e.target.value })
  }

  const deleteClubMember = async (clubId, uid, clubName, userName) => {
    const member = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/v1/club/member/${clubMemberData.uid}`, config)

    toast.success('Delete Club Member Success !', {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 200,
    })
    setPagination({ page: 1, limit: 20, search: '' })
    setIsUpdated(!isUpdated)
    setDeleteConfirmation(false)
  }

  const deleteClubMemberConfirmation = async (clubId, uid, clubName, userName) => {
    setClubMemberData({ clubId, uid, clubName, userName })
    setDeleteConfirmation(true)
  }

  return (
    <div>
      <div className="rotating-lines">
        <RotatingLines
          strokeColor="grey"
          strokeWidth="5"
          animationDuration="0.75"
          width="82"
          visible={isLoading}
        />
      </div>
      <div className="container-fluid loaded" style={{ display: "block" }}>
        <ToastContainer />
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <div className="page-title-right">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">Manage Members</li>
                  <li className="breadcrumb-item active">Members</li>
                </ol>
              </div>
              <h4 className="page-title">Members</h4>
            </div>
          </div>
        </div>
        <div className="form-messages"></div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className="app-search search-form float-right">
                  <div className="form-group position-relative">
                    <input
                      type="text"
                      name="edit[q]"
                      className="form-control submittable"
                      onChange={onChange}
                      value={search}
                      placeholder="Search by name..."
                    />
                    <span className="fa fa-search search-icon"></span>
                  </div>
                  <input type="hidden" name="op" value="" />
                </div>
                <h4 className="header-title mb-3 mt-1">Members</h4>
                <table id="tbl0" className="table">
                  <thead>
                    <tr>
                      <th width="32"></th>
                      <th>Club</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Access Level</th>
                      <th width="72">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {members.map(member => (
                      <tr key={member.uid}>
                        <td>
                          <img
                            src={process.env.REACT_APP_BACKEND_URL + "" + member.image}
                            alt="user-image"
                            style={{ width: "32px" }}
                          />
                        </td>
                        <td>{member.clubName}</td>
                        <td>{member.name}</td>
                        <td>{member.email}</td>
                        <td>{position[member.access_level]}</td>
                        <td>
                          <Link
                            to={`/manageclub/members/${member.uid}`}
                            title="Edit"
                          >
                            <i className="icon-button fa fa-border fa-pencil pencil-alt color-green-bg"></i>
                          </Link>
                          <a onClick={() => deleteClubMemberConfirmation(member.club_id, member.uid, member.clubName, member.name)} style={{ cursor: "pointer" }}>
                            <i className="icon-button fa fa-border fa-trash trash-alt color-red-bg"></i>
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <ul className="pagination">
                  <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
                    <a style={{ cursor: 'pointer' }} onClick={onPrevPage} className="page-link" tabIndex="-1">Previous</a>
                  </li>
                  {pageNumber.map((item, index) => (
                    <li key={index} className={`page-item ${isActive[`ods_${index + 1}`] ? "active" : ""}`}><a style={{ cursor: 'pointer' }} className="page-link" onClick={() => pageOnClick(index + 1)} >{index + 1}</a></li>
                  ))}
                  <li className={`page-item ${currentPage === pageNumber.length ? "disabled" : ""}`}>
                    <a style={{ cursor: 'pointer' }} onClick={onNextPage} className="page-link">Next</a>
                  </li>
                </ul>
                <Link to="/manageclub/members/add" className="btn button">
                  <i className="fa fa-fw fa-plus"></i> Add
                </Link>
              </div>
            </div>
          </div>
        </div>
        <Modal
          size="md"
          show={deleteConfirmation}
          onHide={() => setDeleteConfirmation(false)}
        >
          <Modal.Header closeButton>
            <h4>Member</h4>
          </Modal.Header>
          <Modal.Body>
            <div className="row p-2">
              <div className="col-12">
                <h4 className="">Are you sure you want to remove user: {clubMemberData.userName} from club: {clubMemberData.clubName}</h4>
              </div>

            </div>
            <div className="row p-2">
              <button onClick={deleteClubMember} className="btn btn-sm btn-success mr-2">Confirm</button>
              <button onClick={() => setDeleteConfirmation(false)} className="btn btn-sm btn-danger">Cancel</button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default ListMembers;
