import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import './style.css'
import footballField from '../../data/pitch.png'
import axios from 'axios'
import _, { isArrayLike } from 'lodash'
import { loginStatus, authFail } from '../../actions/userActions';
import { useDispatch } from 'react-redux';
import { Bars } from 'react-loader-spinner'
import Slider from '@mui/material/Slider'
import { Knob } from 'primereact/knob';
import { Modal } from 'react-bootstrap';
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import common_en from "../../translations/en/common.json"
import common_de from "../../translations/de/common.json"
import common_nl from "../../translations/nl/common.json"
import tracking_1 from "../../data/ea5/tracking/4-3-3 - Angriffspressing - nach innen lenken .json"
import tracking_2 from "../../data/ea5/tracking/Al-Ahli .json"
import tracking_3 from "../../data/ea5/tracking/Al Ittihad Clip1.json"
import tracking_4 from "../../data/ea5/tracking/Al Ittihad Clip 2.json"
import tracking_5 from "../../data/ea5/tracking/Al Nassr Clip 1.json"
import tracking_6 from "../../data/ea5/tracking/Al Nassr Clip 2.json"
import tracking_7 from "../../data/ea5/tracking/Al Nassr Clip 3.json"
import tracking_8 from "../../data/ea5/tracking/Belgien - Wales 2-0 .json"
import tracking_9 from "../../data/ea5/tracking/CL Final 2011 - 1-0 .json"
import tracking_10 from "../../data/ea5/tracking/FC Wohlen - FC Luzern Raumverteidigung.json"
import tracking_11 from "../../data/ea5/tracking/FC Wohlen - FC Luzern TorDef.json"
import tracking_12 from "../../data/ea5/tracking/Example for RBFA Demo.json"
import tracking_13 from "../../data/ea5/tracking/Spieleröffnung gegen Angriffspressing - Muster 1.json"
import tracking_14 from "../../data/ea5/tracking/Spieleröffnung gegen Angriffspressing - Muster 2.json"
import tracking_15 from "../../data/ea5/tracking/Spieleröffnung gegen Angriffspressing - Muster 3.json"
import tracking_16 from "../../data/ea5/tracking/Spielsimulation - II - 4-4-2 Mittelfeldpressing.json"
import tracking_17 from "../../data/ea5/tracking/Spielsimulation V - 5-2-3 gegen 4-3-3 - GB - Option 1.json"
import tracking_18 from "../../data/ea5/tracking/Spielsimulation V - 5-2-3 gegen 4-3-3 - GB - Option 2.json"
import tracking_19 from "../../data/ea5/tracking/Spielsimulation VI - 3-4-3 vs 4-4-2.json"
import tracking_20 from "../../data/ea5/tracking/Switzerland - Turkey Doublepass.json"
import tracking_21 from "../../data/ea5/tracking/saiva_test_SN-190_tracking.json"
import tracking_22 from "../../data/ea5/tracking/saiva_test_SN-195_tracking.json"
import analysis_1 from "../../data/ea5/pass_analyzer/4-3-3 - Angriffspressing - nach innen lenken .json"
import analysis_2 from "../../data/ea5/pass_analyzer/Al-Ahli .json"
import analysis_3 from "../../data/ea5/pass_analyzer/Al Ittihad Clip1.json"
import analysis_4 from "../../data/ea5/pass_analyzer/Al Ittihad Clip 2.json"
import analysis_5 from "../../data/ea5/pass_analyzer/Al Nassr Clip 1.json"
import analysis_6 from "../../data/ea5/pass_analyzer/Al Nassr Clip 2.json"
import analysis_7 from '../../data/ea5/pass_analyzer/Al Nassr Clip 3.json'
import analysis_8 from "../../data/ea5/pass_analyzer/Belgien - Wales 2-0 .json"
import analysis_9 from "../../data/ea5/pass_analyzer/CL Final 2011 - 1-0 .json"
import analysis_10 from "../../data/ea5/pass_analyzer/FC Wohlen - FC Luzern Raumverteidigung.json"
import analysis_11 from "../../data/ea5/pass_analyzer/FC Wohlen - FC Luzern TorDef.json"
import analysis_12 from "../../data/ea5/pass_analyzer/Example for RBFA Demo.json"
import analysis_13 from "../../data/ea5/pass_analyzer/Spieleröffnung gegen Angriffspressing - Muster 1.json"
import analysis_14 from "../../data/ea5/pass_analyzer/Spieleröffnung gegen Angriffspressing - Muster 2.json"
import analysis_15 from "../../data/ea5/pass_analyzer/Spieleröffnung gegen Angriffspressing - Muster 3.json"
import analysis_16 from "../../data/ea5/pass_analyzer/Spielsimulation - II - 4-4-2 Mittelfeldpressing.json"
import analysis_17 from "../../data/ea5/pass_analyzer/Spielsimulation V - 5-2-3 gegen 4-3-3 - GB - Option 1.json"
import analysis_18 from "../../data/ea5/pass_analyzer/Spielsimulation V - 5-2-3 gegen 4-3-3 - GB - Option 2.json"
import analysis_19 from "../../data/ea5/pass_analyzer/Spielsimulation VI - 3-4-3 vs 4-4-2.json"
import analysis_20 from "../../data/ea5/pass_analyzer/Switzerland - Turkey Doublepass.json"
import analysis_21 from "../../data/ea5/pass_analyzer/saiva_test_SN-190_analyzer.json"
import analysis_22 from "../../data/ea5/pass_analyzer/saiva_test_SN-195_analyzer.json"

i18n
  .use(initReactI18next)
  .init({
    interpolation: { escapeValue: false },  // React already does escaping
    lng: 'en',                              // language to use
    resources: {
      en: {
        common: common_en
      },
      de: {
        common: common_de
      },
      nl: {
        common: common_nl
      }
    },
  });

let lastFrame = 0
let eventClick = []
let jsonSample = []
let saicaRes = []
let footballSimData = {}

const AIPages = () => {
  const { t } = useTranslation('common')
  const userInfo = JSON.parse(localStorage.getItem("userInfo"))
  const uid = userInfo !== null ? userInfo.user_id : null;
  const token = userInfo !== null ? userInfo.token : null
  const [ea5file, setEa5File] = useState({ sceneObjects: [] })
  const [isActive, setIsActive] = useState({ frame_1: true })
  const [frameLength, setFrameLength] = useState([])
  const [frameResult, setFrameResult] = useState({})
  const [currentFrame, setCurrentFrame] = useState({})
  const [notification, setNotification] = useState("")
  const [saicaResult, setSaicaResult] = useState({})
  const [playButton, setPlayButton] = useState(false)
  const [isPlayDisable, setIsPlayDisable] = useState(false)
  const [breakLoop, setBreakLoop] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isFileLoaded, setIsFileLoaded] = useState(false)
  const [teamA, setTeamA] = useState({})
  const [teamB, setTeamB] = useState({})
  const [eventResult, setEventResult] = useState({})
  const [isPlay, setIsPlay] = useState(true)
  const [value, setValue] = useState(0)
  const [scenarioShow, setScenarioShow] = useState(false)
  const [eventDetailsShow, setEventDetailsShow] = useState(false)
  const [startPlay, setStartPlay] = useState(true)
  const [passEvent, setPassEvent] = useState([])
  const [dribbleEvent, setDribbleEvent] = useState([])
  const [receptionEvent, setReceptionEvent] = useState([])
  const [interceptEvent, setInterceptEvent] = useState([])
  const [sliderValue, setSliderValue] = useState(2)
  const [theEvents, setTheEvents] = useState([])
  const [dataAssessment, setDataAssessment] = useState()
  const [assessmentShow, setAssessmentShow] = useState(false)
  const [sliderChange, setSliderChange] = useState(false)
  const [isEvent, setIsEvent] = useState()
  const [scenarioName, setScenarioName] = useState('')
  const [isScenarioUploaded, setIsScenarioUploaded] = useState(false)
  const [passData, setPassData] = useState({ passType: 'short', passPower: 0.2, passAngle: 0 })
  const [isFootballSim, setIsFootballSim] = useState(false)
  const [footballSimFrame, setFootballSimFrame] = useState(0)

  const initAngleStatus = {
    angle_1: false,
    angle_2: false,
    angle_3: false,
    angle_4: false,
    angle_5: false,
    angle_6: false,
    angle_7: false,
    angle_8: false
  }

  const [angleStatus, setAngleStatus] = useState(initAngleStatus)

  const tt_1 = tracking_1

  let listTeamA = []
  let listTeamB = []

  const marks = [
    {
      value: 1,
      label: 'Conservative'
    },
    {
      value: 2,
      label: 'Moderate'
    },
    {
      value: 3,
      label: 'Aggresive'
    }
  ]

  let playPause = false
  const [ball, setBall] = useState({})

  const fakeArray = Array(100).fill(0)

  const folderInit = { f_1: false, f_30: false, f_170: false };
  const [parentFolder, setParentFolder] = useState([{ id: 1, name: 'Dummy' }]);
  const [childFolder, setChildFolder] = useState({
    premade: [],
    user: [],
    saiva_lite: [],
  });
  const [activeFolder, setActiveFolder] = useState(folderInit);
  const [scenarios, setScenarios] = useState([]);

  var width = 1060;
  var height = 683

  let stage

  var layer = new Konva.Layer();

  const user = JSON.parse(localStorage.getItem('userInfo'))

  const dispatch = useDispatch()

  const config = {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  }

  useEffect(() => {
    const createLog = async () => {
      const log = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/log`, { userId: user.user_id, name: user.user_name, page: 'AI Bot Platform' })
    }
    createLog()
  }, [])

  useEffect(() => {
    dispatch(loginStatus())
  }, [])

  useEffect(() => {
    setScenarios([
      {
        id: 1,
        name: '4-3-3 - Angriffspressing - nach innen lenken',
        datedt: '2023-Sep-18'
      },
      {
        id: 2,
        name: 'Al-Ahli',
        datedt: '2023-Sep-18'
      },
      {
        id: 3,
        name: 'Al Ittihad Clip1',
        datedt: '2023-Sep-18'
      },
      {
        id: 4,
        name: 'Al Ittihad Clip 2',
        datedt: '2023-Sep-18'
      },
      {
        id: 5,
        name: 'Al Nassr Clip 1',
        datedt: '2023-Sep-18'
      },
      {
        id: 6,
        name: 'Al Nassr Clip 2',
        datedt: '2023-Sep-18'
      },
      {
        id: 7,
        name: 'Al Nassr Clip 3',
        datedt: '2023-Sep-18'
      },
      {
        id: 8,
        name: 'Belgien - Wales 2-0',
        datedt: '2023-Sep-18'
      },
      {
        id: 9,
        name: 'CL Final 2011 - 1-0',
        datedt: '2023-Sep-18'
      },
      {
        id: 10,
        name: 'FC Wohlen - FC Luzern Raumverteidigung',
        datedt: '2023-Sep-18'
      },
      {
        id: 11,
        name: 'FC Wohlen - FC Luzern Raumverteidigung',
        datedt: '2023-Sep-18'
      },
      {
        id: 12,
        name: 'Example for RBFA Demo',
        datedt: '2023-Sep-18'
      },
      {
        id: 13,
        name: 'Spieleröffnung gegen Angriffspressing - Muster 1',
        datedt: '2023-Sep-18'
      },
      {
        id: 14,
        name: 'Spieleröffnung gegen Angriffspressing - Muster 2',
        datedt: '2023-Sep-18'
      },
      {
        id: 15,
        name: 'Spieleröffnung gegen Angriffspressing - Muster 3',
        datedt: '2023-Sep-18'
      },
      {
        id: 16,
        name: 'Spielsimulation - II - 4-4-2 Mittelfeldpressing',
        datedt: '2023-Sep-18'
      },
      {
        id: 17,
        name: 'Spielsimulation V - 5-2-3 gegen 4-3-3 - GB - Option 1',
        datedt: '2023-Sep-18'
      },
      {
        id: 18,
        name: 'Spielsimulation V - 5-2-3 gegen 4-3-3 - GB - Option 2',
        datedt: '2023-Sep-18'
      },
      {
        id: 19,
        name: 'Spielsimulation VI - 3-4-3 vs 4-4-2',
        datedt: '2023-Sep-18'
      },
      {
        id: 20,
        name: 'Switzerland - Turkey Doublepass',
        datedt: '2023-Sep-18'
      },
      {
        id: 21,
        name: 'saiva_test_SN-190',
        datedt: '2024-Jan-29'
      },
      {
        id: 22,
        name: 'saiva_test_SN-195',
        datedt: '2024-Jan-29'
      },
    ])
  }, [])

  useEffect(() => {
    const getParentFolder = async () => {
      try {
        const folders = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/scenarios/folder/parent/${uid}`, config);
        setParentFolder(folders.data);
      } catch (error) {
        if (error.response.status === 401) {
          dispatch(loginStatus())
        }
      }
    };
    //getParentFolder();
  }, [activeFolder, scenarios]);

  const getScenariosByFolderId = async (id, e) => {
    e.preventDefault()
    createLog(`Click On Scenario ${e.target.name} Sub folder`)
    const scenarios = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/scenarios/folder/${id}`, config);
    setScenarios(scenarios.data.scenarios);
  };

  const pageOnClick = (val) => {
    setCurrentFrame(frameResult[val - 1])
    setTeamA(frameResult[val - 1].team_a)
    setTeamB(frameResult[val - 1].team_b)
    setBall(frameResult[val - 1].ball_coor)
    setIsActive({ [`ods_${val}`]: true })
  }

  const pause = async () => {
    playPause = true
    getInfo()
  }

  const stop = async () => {
    lastFrame = 0
    getInfo()
    playPause = true
    document.querySelectorAll('.event-data').forEach(f => {
      if (f.classList.contains('saiva-button-active')) {
        f.classList.remove('saiva-button-active')
      }
    })
    document.querySelectorAll('.page-item ').forEach(f => {
      if (f.classList.contains('active')) {
        f.classList.remove('active')
      }
    })
    document.querySelector('.page-item').classList.add('active')
    setTimeout(() => {
      lastFrame = 1
      getInfo()
    }, 10);
  }

  console.log(listTeamA)

  const loopFootballSim = (data, index) => {
    setTimeout(() => {
      let indexa = 0;

      for (const dt in footballSimData.tracking[data].left) {
        if (playPause) {
          return
        }
        const converted = convert_coordinate(footballSimData.tracking[data].left[dt].x, footballSimData.tracking[data].left[dt].y, 1060, 683)
        window['teama_' + (indexa + 1)].x(converted.x)
        window['teama_' + (indexa + 1)].y(converted.y)
        indexa++
        lastFrame = parseInt(data)

        const frameNumber = document.querySelector(`.page_${data}`)
        frameNumber.classList.add('active')
        frameNumber.scrollIntoView(false)

        if (data > 0) {
          document.querySelector(`.page_${data - 1}`).classList.remove('active')
        }
      }

      let indexb = 0;
      for (const dt in footballSimData.tracking[data].right) {
        if (playPause) return
        const converted = convert_coordinate(footballSimData.tracking[data].right[dt].x, footballSimData.tracking[data].right[dt].y, 1060, 683)
        const ball_converted = convert_coordinate(footballSimData.tracking[data].ball.x, footballSimData.tracking[data].ball.y, 1060, 683)
        window['teamb_' + (indexb + 1)].x(converted.x)
        window['teamb_' + (indexb + 1)].y(converted.y)
        ballTeam.x(ball_converted.x)
        ballTeam.y(ball_converted.y)
        indexb++
      }
      if (data == (frameLength.length - 1)) {
        lastFrame = 0
      }
    }, 150 * index);
  }

  const loopData = async (data, index) => {
    setTimeout(() => {
      let indexa = 0;

      for (const dt in jsonSample.tracking[data].right) {
        if (playPause) {
          return
        }
        const converted = convert_coordinate(jsonSample.tracking[data].right[dt].x, jsonSample.tracking[data].right[dt].y, 1060, 683)
        window['teama_' + (indexa + 1)].x(converted.x)
        window['teama_' + (indexa + 1)].y(converted.y)
        indexa++
        lastFrame = parseInt(data)

        const frameNumber = document.querySelector(`.page_${data}`)
        frameNumber.classList.add('active')
        frameNumber.scrollIntoView(false)

        if (data > 0) {
          document.querySelector(`.page_${data - 1}`).classList.remove('active')
        }
      }
      const dataOne = jsonSample.events.filter(fs => {
        return fs.start_frame === data
      })

      if (dataOne.length > 0) {
        dataOne.map(dt => {
          document.querySelectorAll('.event-data').forEach(data => {
            if (data.classList.contains('saiva-button-active')) {
              data.classList.remove('saiva-button-active')
            }
          })
          const event = document.querySelector(`.${dt.type}_${dt.index}`)
          event.classList.add('saiva-button-active')
          event.scrollIntoView(false)

        })
      }

      let indexb = 0;
      for (const dt in jsonSample.tracking[data].left) {
        if (playPause) return
        const converted = convert_coordinate(jsonSample.tracking[data].left[dt].x, jsonSample.tracking[data].left[dt].y, 1060, 683)
        const ball_converted = convert_coordinate(jsonSample.tracking[data].ball.x, jsonSample.tracking[data].ball.y, 1060, 683)
        window['teamb_' + (indexb + 1)].x(converted.x)
        window['teamb_' + (indexb + 1)].y(converted.y)
        ballTeam.x(ball_converted.x)
        ballTeam.y(ball_converted.y)
        indexb++
      }
      if (data == (frameLength.length - 1)) {
        lastFrame = 0
      }
    }, 150 * index);

  }

  const startScenario = async () => {
    let index = 0

    playPause = false
    setEventResult({})


    if (isFootballSim) {
      for (const data in footballSimData.tracking) {
        if (parseInt(data) >= lastFrame) {
          loopFootballSim(data, index)
          index++
        }
      }
    } else {
      for (const data in jsonSample.tracking) {
        if (parseInt(data) >= lastFrame) {
          loopData(data, index)
          index++
        }
      }
    }
  }

  const createLog = async (page) => {
    const log = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/log`, { userId: user.user_id, name: user.user_name, page })
  }

  const footballSim = async () => {

  }

  const getChildFolder = async (e) => {
    e.preventDefault();
    const id = e.target.name;

    createLog(`Click On Scenario ${e.target.getAttribute('tag')} Main folder`)
    if (id != 1) {
      getScenariosByFolderId(id, e);
    } else {
      setScenarios([])
    }
    setActiveFolder({ ...folderInit, [`f_${id}`]: !activeFolder[`f_${id}`] });
  };

  useEffect(() => {

  }, [])

  const convert_coordinate = (x, y, dim_x, dim_y) => {
    const xmin_A = -1
    const xmax_A = 1
    const ymin_A = -1
    const ymax_A = 1

    const xmin_B = 0
    const xmax_B = dim_x
    const ymin_B = dim_y
    const ymax_B = 0

    const x_range_A = xmax_A - xmin_A
    const x_range_B = xmax_B - xmin_B

    const converted_x = ((x - xmin_A) / x_range_A) * x_range_B + xmin_B

    const y_range_A = ymax_A - ymin_A
    const y_range_B = ymax_B - ymin_B

    const converted_y = ((y - ymin_A) / y_range_A) * y_range_B + ymin_B

    return {
      x: converted_x,
      y: converted_y
    }
  }

  const [resize, setResize] = useState(false)

  useEffect(() => {
    stage = new Konva.Stage({
      container: 'konva-container',
      width: width,
      height: height,
    });

    // stage.container = 'konva-container'
    // stage.width = width
    // stage.height = height

    // Image API
    var imageObj = new Image()
    imageObj.onload = function () {
      var football = new Konva.Image({
        x: 0,
        y: 0,
        image: imageObj,
        width,
        height
      })

      layer.add(football)
      football.zIndex(0)
    }

    imageObj.src = footballField

    if (!_.isEmpty(teamA)) {
      let indexa = 0;

      for (const team in teamA) {
        const converted = convert_coordinate(teamA[team].x, teamA[team].y, stage.width(), stage.height())
        window["teama_" + (indexa + 1)] = new Konva.Circle({
          x: converted.x,
          y: converted.y,
          radius: 7,
          fill: 'red',
          name: "teama_" + team,
          draggable: false
        });
        listTeamA.push('teama_' + (indexa + 1))
        layer.add(window["teama_" + (indexa + 1)])

        indexa++;
      }

      let indexb = 0

      for (const team in teamB) {
        const converted = convert_coordinate(teamB[team].x, teamB[team].y, stage.width(), stage.height())
        window["teamb_" + (indexb + 1)] = new Konva.Circle({
          x: converted.x,
          y: converted.y,
          radius: 7,
          fill: 'blue',
          name: 'teamb_' + team,
          draggable: false
        });
        listTeamB.push('teamb_' + (indexb + 1))
        layer.add(window["teamb_" + (indexb + 1)])

        indexb++;
      }

      const ball_converted = convert_coordinate(ball.x, ball.y, stage.width(), stage.height())
      window["ballTeam"] = new Konva.Circle({
        x: ball_converted.x,
        y: ball_converted.y,
        radius: 5,
        fill: 'white',
        name: 'ball',
        draggable: false
      })

      layer.add(ballTeam)
    }

    if (!_.isEmpty(eventResult)) {
      let index = 0;
      eventResult.event.map(t => {
        const converted = convert_coordinate(t.target[0], t.target[1], stage.width(), stage.height())
        const converted_circle = convert_coordinate(t.target[0], t.target[1], stage.width(), stage.height())
        window["event_circle_" + (index + 1)] = new Konva.Circle({
          x: converted_circle.x + 5,
          y: converted_circle.y + 9,
          radius: 10,
          stroke: 'black',
          name: 'event_circle_' + (index + 1),
          draggable: false
        });

        window["event_text_" + (index + 1)] = new Konva.Text({
          x: converted.x,
          y: converted.y,
          fontSize: 20,
          text: index + 1,
          fill: 'black',
          name: 'event_text_' + (index + 1),
          align: 'center',

          draggable: false
        });
        layer.add(window["event_circle_" + (index + 1)])
        layer.add(window["event_text_" + (index + 1)])
        index++;
      })

      const targetX = convert_coordinate(eventResult.target[0].target[0], eventResult.target[0].target[1], stage.width(), stage.height())
      window["event_circle_target"] = new Konva.Circle({
        x: targetX.x,
        y: targetX.y,
        radius: 7,
        fill: 'yellow',
        name: 'event_circle_' + (index + 1),
        draggable: false
      });
      layer.add(window["event_circle_target"])
    }
    stage.add(layer);
  }, [teamA, teamB, ball, eventResult])

  useEffect(() => {
    if (isEvent !== undefined) {
      const res = saicaRes.recommendations.find(saica => {
        return (saica.index === isEvent.index && saica.type === isEvent.type)
      })
      let ttd

      if (res !== undefined) {
        const actualTarget = res.top_pass.actual_target

        if (res !== undefined && sliderValue === 3) {
          ttd = res.top_pass.aggresive
        } else if (res !== undefined && sliderValue === 2) {
          ttd = res.top_pass.moderate
        } else if (res !== undefined && sliderValue === 1) {
          ttd = res.top_pass.conservative
        }

        setEventResult({ event: ttd, target: actualTarget })
      }
    }

  }, [sliderChange, isEvent])

  const getEvent = async (event, e) => {
    setFootballSimFrame(event.start_frame)

    setIsFootballSim(true)

    document.querySelectorAll('.event-data').forEach(data => {
      if (data.classList.contains('saiva-button-active')) {
        data.classList.remove('saiva-button-active')
      }
    })
    let getIndex = eventClick.findIndex(f => {
      return f.id === event.index && f.type === event.type
    })

    document.querySelector(`.${event.type}_${event.index}`).classList.add('saiva-button-active')

    if (getIndex === -1) {
      eventClick.push({ id: event.index, type: event.type, click: 1 })
      getIndex = eventClick.findIndex(f => {
        return f.id === event.index && f.type === event.type
      })
    }

    if (eventClick[getIndex].click > 2) {
      eventClick[getIndex].click = 1
    }

    if (eventClick[getIndex].click === 1) {
      setIsEvent(event)
    } else {
      const res = saicaRes.assessments.find(saica => {
        return (saica.index === event.index && saica.type === event.type)
      })
      setDataAssessment(res)
      setAssessmentShow(true)
    }

    eventClick[getIndex].click++
    lastFrame = parseInt(event.start_frame)
    getInfo()
  }

  const getInfo = () => {
    const length = Object.keys(jsonSample.tracking).length
    setIsFileLoaded(true)
    setFrameResult(jsonSample.tracking)
    setFrameLength(Array(length).fill(0))
    setTeamA(jsonSample.tracking[lastFrame].left)
    setTeamB(jsonSample.tracking[lastFrame].right)
    setBall(jsonSample.tracking[lastFrame].ball)
    setTheEvents(jsonSample.events)
  }

  const getScenarioById = async (e, id, name) => {
    e.preventDefault()
    setEventResult({})
    setScenarioName(name)
    eventClick = []
    const ss = window[`tracking_${id}`]
    switch (id) {
      case 1:
        jsonSample = tracking_1
        saicaRes = analysis_1
        break;
      case 2:
        jsonSample = tracking_2
        saicaRes = analysis_2
        break;
      case 3:
        jsonSample = tracking_3
        saicaRes = analysis_3
        break;
      case 4:
        jsonSample = tracking_4
        saicaRes = analysis_4
        break;
      case 5:
        jsonSample = tracking_5
        saicaRes = analysis_5
        break;
      case 6:
        jsonSample = tracking_6
        saicaRes = analysis_6
        break;
      case 7:
        jsonSample = tracking_7
        saicaRes = analysis_7
        break;
      case 8:
        jsonSample = tracking_8
        saicaRes = analysis_8
        break;
      case 9:
        jsonSample = tracking_9
        saicaRes = analysis_9
        break;
      case 10:
        jsonSample = tracking_10
        saicaRes = analysis_10
        break;
      case 11:
        jsonSample = tracking_11
        saicaRes = analysis_11
        break;
      case 12:
        jsonSample = tracking_12
        saicaRes = analysis_12
        break;
      case 13:
        jsonSample = tracking_13
        saicaRes = analysis_13
        break;
      case 14:
        jsonSample = tracking_14
        saicaRes = analysis_14
        break;
      case 15:
        jsonSample = tracking_15
        saicaRes = analysis_15
        break;
      case 16:
        jsonSample = tracking_16
        saicaRes = analysis_16
        break;
      case 17:
        jsonSample = tracking_17
        saicaRes = analysis_17
        break;
      case 18:
        jsonSample = tracking_18
        saicaRes = analysis_18
        break;
      case 19:
        jsonSample = tracking_19
        saicaRes = analysis_19
        break;
      case 21:
        jsonSample = tracking_21
        saicaRes = analysis_21
        break;
      case 22:
        jsonSample = tracking_22
        saicaRes = analysis_22
        break;
    }
    setFrameLength(jsonSample.tracking.length)
    setIsScenarioUploaded(true)
    setScenarioShow(false)
    getInfo()
    fitStage()
    //const scenario = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/scenarios/get?id=${id}`, config)
  }

  const showEventDetails = async () => {
    setEventDetailsShow(true)
  }

  const aiCoach = async () => {
    console.log(jsonSample.tracking.length())
  }

  const getSlider = e => {
    setSliderValue(e.target.value)
    setSliderChange(!sliderChange)
  }

  const reset = () => {
    setTeamA({})
    setTeamB({})
    setBall({})
    setEventResult({})
    setFrameLength([])
    setScenarioName('')
    setTheEvents([])
    setIsFootballSim(false)
    setPassData({ passType: 'short', passPower: 0.2, passAngle: 0 })
  }

  const getDescription = (item) => {
    if (dataAssessment && dataAssessment.assesment.postanalysis !== 'pass failed') {
      switch (item) {
        case 'diff_xPressure':
          if (dataAssessment.assesment.postanalysis[item] === 'Not Relevant') {
            return ''
          }
          else if (dataAssessment.assesment.postanalysis[item] > 0) {
            return 'Higher pressure on the ball from opposing player'
          } else {
            return 'Lower pressure on the ball from opposing player'
          }
        case 'diff_xGV':
          if (dataAssessment.assesment.postanalysis[item] === 'Not Relevant') {
            return ''
          }
          else if (dataAssessment.assesment.postanalysis[item] > 0) {
            return 'Higher chance of goal if shot from current spot'
          } else {
            return 'Lower chance of goal if shot from current spot'
          }
        case 'diff_xC':
          if (dataAssessment.assesment.postanalysis[item] === 'Not Relevant') {
            return ''
          }
          else if (dataAssessment.assesment.postanalysis[item] > 0) {
            return 'Higher chance of getting counter attacked from current spot'
          } else {
            return 'Lower chance of getting counter attacked from current spot'
          }
        case 'diff_epv':
          if (dataAssessment.assesment.postanalysis[item] === 'Not Relevant') {
            return ''
          }
          else if (dataAssessment.assesment.postanalysis[item] > 0) {
            return 'Higher chance of scoring on the next 10 seconds of play'
          } else {
            return 'Lower chance of scoring on the next 10 seconds of play'
          }
        case 'diff_xT':
          if (dataAssessment.assesment.postanalysis[item] === 'Not Relevant') {
            return ''
          }
          else if (dataAssessment.assesment.postanalysis[item] > 0) {
            return 'Higher immediate threat of scoring'
          } else {
            return 'Lower immediate threat of scoring'
          }
        case 'packing':
          return `Pass outplay ${dataAssessment.assesment.postanalysis[item]} players`
        case 'linebreak':
          switch (dataAssessment.assesment.postanalysis[item]) {
            case '':
              return "Pass didn't break any defensive line."
            case 'att':
              return 'Pass break opposition attacking line.'
            case 'mid':
              return 'Pass break opposition midfield line'
            default:
              return 'Pass break opposition defensive line'
          }
      }
    }
  }

  const getPostDataAssessment = (item) => {
    if (dataAssessment && dataAssessment.assesment.postanalysis !== 'pass failed') {
      if (item !== 'packing' && item !== 'linebreak') {
        if (dataAssessment.assesment.postanalysis[item] === 'Not Relevant') {
          return 'null'
        } else {
          return dataAssessment.assesment.postanalysis[item].toFixed(5)
        }
      }
      return dataAssessment.assesment.postanalysis[item]
    }
  }

  const getPreDataAssessment = (itm) => {
    if (dataAssessment) {
      if (dataAssessment.assesment.preanalysis[itm] === null) {
        return 'null'
      } else {
        return dataAssessment.assesment.preanalysis[itm].toFixed(5)
      }
    }
  }

  const getPostActionData = (pre) => {
    if (dataAssessment) {
      if (getPreDataAssessment(pre) !== null) {
        return (parseFloat(getPreDataAssessment(pre)) + parseFloat(getPostDataAssessment(`diff_${pre}`))).toFixed(5)
      }
    }
  }

  const passChange = (e) => {
    setPassData({ ...passData, [e.target.name]: e.target.value })
  }

  const getFootballSim = () => {
    const length = Object.keys(footballSimData.tracking).length
    setIsFileLoaded(true)
    setEventResult({})
    setFrameResult(footballSimData.tracking)
    setFrameLength(Array(length).fill(0))
    setTeamA(footballSimData.tracking[lastFrame].left)
    setTeamB(footballSimData.tracking[lastFrame].right)
    setBall(footballSimData.tracking[lastFrame].ball)
  }

  const generateFootballSim = async () => {
    const data = {
      saica: jsonSample,
      start_frame: footballSimFrame,
      pass_type: passData.passType,
      pass_power: parseFloat(passData.passPower),
      pass_angle: passData.passAngle
    }

    const data2 = {
      fname: "Test1.json",
      selected_frame: footballSimFrame,
      pass_action: {
        pass_type: passData.passType,
        pass_power: parseFloat(passData.passPower),
        pass_angle: passData.passAngle
      },
      data_src: "saica",
      data: jsonSample,
    }

    try {
      const football = await axios.post(`${process.env.REACT_APP_SAICA_COMP_URL}/saica/gen_footballsim_input`, data2)

      setIsLoading(true)

      const footballSim = await axios.post(`${process.env.REACT_APP_FOOTBALL_SIM_URL}/football_sim`, football.data)

      footballSimData = footballSim.data.output

      console.log('footballsim succeed')

      lastFrame = 0

      setIsLoading(false)
      getFootballSim()
    } catch (error) {
      console.log(error)
    }

  }

  const setTheAngle = (angle) => {
    setPassData({ ...passData, passAngle: angle })
    document.querySelectorAll('.circle').forEach(c => {
      if (c.classList.contains('circle-active')) {
        c.classList.remove('circle-active')
      }
    })
    document.querySelector(`.c_${angle}`).classList.add('circle-active')
  }

  const fitStage = () => {
    var container = document.querySelector('#stage-parent');

    if (container) {
      var containerWidth = container.offsetWidth;

      var scale = containerWidth / width;

      stage.width(width * scale);
      stage.height(height * scale);
      stage.scale({ x: scale, y: scale });
    }
  }

  console.log(teamA)

  useEffect(() => {
    fitStage()
    window.addEventListener('resize', fitStage)
  }, [teamA, teamB, ball, eventResult])

  return (
    <div className="container-fluid loaded" style={{ display: 'block' }}>
      <div style={{ display: isLoading ? "block" : "none", position: "absolute", top: "29rem", left: "51.4rem", zIndex: 1, color: "#60a5fa" }}>
        <Bars
          height="80"
          width="80"
          color="#2563eb"
          ariaLabel="bars-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
        <p style={{ textAlign: "center", fontSize: "20px" }}>Processing....</p>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="page-title-box">
            <div className="page-title-right">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/">Dashboard</Link>
                </li>
                <li className="breadcrumb-item">AI Pages
                </li>
                <li className="breadcrumb-item active">AI Pages
                </li>
              </ol>
            </div>
            <h4 className="page-title">AI Pages</h4>
          </div>
        </div>
      </div>
      <div className="form-messages"></div>
      <div className="row">
        <div className="col-12">
          <div className='card'>
            <div className="card-body d-flex">
              <div className='col-9'>
                <div style={{ width: "100%" }} id="stage-parent">
                  <div id="konva-container" className='picture col-12 mb-1'></div>
                </div>
                <div className="d-flex col-12 mb-3 bottom">
                  <div className="d-flex col-2 justify-content-between left">
                    <button onClick={startScenario} className="btn play saiva-circle-button"><i className="fa fa-play"></i> </button>
                    <button onClick={pause} className="btn pause saiva-circle-button"><i className="fa fa-pause"></i> </button>
                    <button onClick={stop} className="btn stop saiva-circle-button"><i className="fa fa-stop"></i></button>
                  </div>
                  <div className="col-2">
                    <button onClick={reset} className='btn btn-success'><i className="fa fa-refresh"></i> Reset</button>
                  </div>
                  <div className="center col-6">
                  </div>
                  <div className="d-flex right col-2">
                    <input type="text" className="form-control mr-2" />
                    <button className="btn saiva-circle-button"><i className="fa fa-search"></i> </button>
                  </div>
                </div>
                <div className="col-12">
                  <ul style={{ listStyle: 'none', wordBreak: '', padding: 0, overflowX: 'scroll' }} className="d-flex justify-content-start">
                    {frameLength.map((fk, index) => (
                      <li key={index} className={`page-item page_${index}`}><a className="page-link" href="#">{index + 1}</a></li>
                    ))}
                  </ul>
                </div>
              </div>
              <div style={{ backgroundColor: '#f3f4f6' }} className='col-3 p-3'>
                <p className='text-center h4 mb-3'>{scenarioName ? scenarioName : 'No Scenario ...'}</p>
                <div className="mx-auto d-flex justify-content-between mb-3">
                  <button className='saiva-button'>Info</button>
                  <button onClick={() => setScenarioShow(true)} className='saiva-button'>Select Scenario</button>
                </div>
                <div style={{ backgroundColor: 'white', overflowY: 'auto', height: '211px', display: 'inline-block', width: '100%' }} className="mx-auto mb-3">
                  <ul className='p-3'>
                    {theEvents.map(event => (
                      <li key={event.index} onClick={(e) => getEvent(event, e)} className={`event-data saiva-button text-left mb-2 ${event.type}_${event.index}`}>Event {event.index} : {event.type.toUpperCase()}</li>
                    ))}
                  </ul>
                </div>
                <div className="card p-0">
                  <div onClick={aiCoach} className="click-header mt-0 h4 card-header text-center saiva-color">
                    AICoach: Pass Recomender
                  </div>
                  <div className="card-body p-2">
                    <div style={{ width: '80%', margin: 'auto' }}>
                      <Slider marks={marks} value={sliderValue} onChange={(e) => getSlider(e)} defaultValue={2} min={1} max={3} aria-label="Default" valueLabelDisplay="auto" />
                    </div>

                  </div>
                </div>
                <div className="card p-0">
                  <button style={{ border: 'none' }} disabled={isFootballSim ? false : true} className={`mt-0 h4 card-header text-center ${isFootballSim ? 'saiva-color' : ''}`}>
                    Football Simulation
                  </button>
                  <div className="card-body py-3">
                    <div className="row">
                      <div className="d-flex col-12 text-center">
                        <div className="text-left col-4">
                          <p>Action: </p>
                        </div>

                        <div className='col-4 text-center'>
                          {/* <button className='saiva-std-btn mb-2'>DropDown</button>
                          <button className='saiva-std-btn mb-3'>Textfield</button> */}
                          <select disabled={isFootballSim ? false : true} onChange={passChange} name="passType" id="passType" className="mb-1 form-control">
                            <option value="short">Short</option>
                            <option value="long">Long</option>
                            <option value="high">High</option>
                            <option value="idle">Idle</option>
                          </select>
                          <select disabled={isFootballSim ? false : true} onChange={passChange} name="passPower" id="passPower" className="mb-1 form-control">
                            <option value="0.2">Low</option>
                            <option value="0.5">Medium</option>
                            <option value="0.9">High</option>
                          </select>
                          <button disabled={isFootballSim ? false : true} onClick={generateFootballSim} className={isFootballSim ? 'saiva-button2' : ''}>Generate</button>
                        </div>
                        <div className="col-4 text-right">
                          {/* <Knob disabled ={isFootballSim ? false : true} value={value} onChange={(e) => setValue(e.value)} size={60} valueColor="#708090" rangeColor="#48d1cc" /> */}
                          <div className="mb-1 shape-circle">
                            <div onClick={() => setTheAngle(-180)} className="c_-180 circle circle-button-1"></div>
                            <div onClick={() => setTheAngle(-135)} className="c_-135 circle circle-button-2"></div>
                            <div onClick={() => setTheAngle(-90)} className="c_-90 circle circle-button-3"></div>
                            <div onClick={() => setTheAngle(-45)} className="c_-45 circle circle-button-4"></div>
                            <div onClick={() => setTheAngle(0)} className="c_0 circle circle-button-5"></div>
                            <div onClick={() => setTheAngle(45)} className="c_45 circle circle-button-6"></div>
                            <div onClick={() => setTheAngle(90)} className="c_90 circle circle-button-7"></div>
                            <div onClick={() => setTheAngle(135)} className="c_135 circle circle-button-8"></div>
                          </div>
                          <p>Direction</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        size="xl"
        show={scenarioShow}
        onHide={() => setScenarioShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Scenario
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">

            <div className="col-md-4">
              <div className="card">
                <div className="card-header">
                  Folders
                </div>
                <div className="card-body">
                  <Link
                    to="/scenarios"
                    className="font-weight-bold"
                  >
                    <i
                      className="fa fa-lg fa-folder-open folder"
                      aria-hidden="true"
                    ></i>
                    {t('Rootfolder')}
                  </Link>
                  <br />
                  <ul style={{ listStyleType: "none" }}>
                    {parentFolder &&
                      parentFolder.map((folder, index) => (
                        <li key={index}>
                          <a
                            name={folder.folder_id}
                            tag={folder.name}
                          // href="#"
                          //onClick={getChildFolder}
                          >
                            <i
                              className="fa fa-lg fa-folder folder"
                              aria-hidden="true"
                            ></i>
                            {folder.name}
                          </a>
                          {/* <ul
                            style={{
                              listStyleType: "none",
                              display: activeFolder[`f_${folder.folder_id}`]
                                ? "block"
                                : "none",
                            }}
                          >
                            {folder.sub.length > 0 &&
                              folder.sub.map((fr) => (
                                <li key={fr.folder_id}>
                                  <a
                                    name={fr.name}
                                    onClick={(e) =>
                                      getScenariosByFolderId(fr.folder_id, e)
                                    }
                                    href=""
                                  >
                                    <i
                                      className="fa fa-lg fa-folder folder"
                                      aria-hidden="true"
                                    ></i>
                                    {fr.name}
                                  </a>
                                </li>
                              ))}
                          </ul> */}
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <div className="card">
                <div className="card-header">
                  Scenarios
                </div>
                <div className="card-body">
                  <table id="tbl0" className="table">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th width="150">Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {scenarios.length > 0 &&
                        scenarios.map((sc) => (
                          <tr key={sc.id}>
                            <td>
                              <a onClick={(e) => getScenarioById(e, sc.id, sc.name)} href="">
                                {sc.name}
                              </a>
                            </td>
                            <td className="text-muted" style={{ overflow: "none" }}>
                              {sc.datedt}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        size="md"
        show={eventDetailsShow}
        onHide={() => setEventDetailsShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Event Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row mb-2">
            <div className="col-md-7">
              <label htmlFor="">Type</label>
              <select name="" id="" className="form-control">
                <option value="0">Select Type</option>
                <option value="1">Pass</option>
                <option value="2">Dribble</option>
                <option value="3">Reception</option>
                <option value="4">Interception</option>
              </select>
            </div>
            <div className="col-md-5">
              <label htmlFor="">Start</label>
              <select name="" id="" className="form-control">
                <option value="">Frame</option>
              </select>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-md-7">
              <label htmlFor="">Sub-Type</label>
              <select name="" id="" className="form-control">
                <option value="0">Select Sub-Type</option>
              </select>
            </div>
            <div className="col-md-5">
              <label htmlFor="">End</label>
              <select name="" id="" className="form-control">
                <option value="">Frame</option>
              </select>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-md-5">
              <label htmlFor="">Player 1 Team</label>
              <select name="" id="" className="form-control">
                <option value="0">Team</option>
              </select>
            </div>
            <div className="col-md-7">
              <label htmlFor="">Player 1 ID</label>
              <select name="" id="" className="form-control">
                <option value="">ID</option>
              </select>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-5">
              <label htmlFor="">Player 2 Team</label>
              <select name="" id="" className="form-control">
                <option value="0">Team</option>
              </select>
            </div>
            <div className="col-md-7">
              <label htmlFor="">Player 2 ID</label>
              <select name="" id="" className="form-control">
                <option value="">ID</option>
              </select>
            </div>
          </div>
          <div className='text-center'>
            <button className='btn btn-success text-center'><i className="fa fa-save mr-1"></i> Save</button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        size="xl"
        show={assessmentShow}
        onHide={() => setAssessmentShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Assessment
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="table">
            <thead>
              <tr>
                <th></th>
                <th>Pre-action</th>
                <th>Post-action</th>
                <th>Difference</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>xPressure</td>
                <td>{getPreDataAssessment('xPressure')}</td>
                <td>{getPostActionData('xPressure')}</td>
                <td>{getPostDataAssessment('diff_xPressure')}</td>
                <td>{getDescription('diff_xPressure')}</td>
              </tr>
              <tr>
                <td>xGV</td>
                <td>{getPreDataAssessment('xGV')}</td>
                <td>{getPostActionData('xGV')}</td>
                <td>{getPostDataAssessment('diff_xGV')}</td>
                <td>{getDescription('diff_xGV')}</td>
              </tr>
              <tr>
                <td>xCounter</td>
                <td>{getPreDataAssessment('xC')}</td>
                <td>{getPostActionData('xC')}</td>
                <td>{getPostDataAssessment('diff_xC')}</td>
                <td>{getDescription('diff_xC')}</td>
              </tr>
              <tr>
                <td>EPV</td>
                <td>{getPreDataAssessment('epv')}</td>
                <td>{getPostActionData('epv')}</td>
                <td>{getPostDataAssessment('diff_epv')}</td>
                <td>{getDescription('diff_epv')}</td>
              </tr>
              <tr>
                <td>xT</td>
                <td>{getPreDataAssessment('xT')}</td>
                <td>{getPostActionData('xT')}</td>
                <td>{getPostDataAssessment('diff_xT')}</td>
                <td>{getDescription('diff_xT')}</td>
              </tr>
              <tr>
                <td>Packing</td>
                <td></td>
                <td></td>
                <td>{getPostDataAssessment('packing')}</td>
                <td>{getDescription('packing')}</td>
              </tr>
              <tr>
                <td>Linebreak</td>
                <td></td>
                <td></td>
                <td>{getPostDataAssessment('linebreak')}</td>
                <td>{getDescription('linebreak')}</td>
              </tr>
            </tbody>
          </table>
        </Modal.Body>
      </Modal>
    </div>

  )
}

export default AIPages