import React, { useState, useEffect } from "react";
import { useParams, useHistory } from 'react-router-dom'
import axios from 'axios'
import { Link } from "react-router-dom";

const EditClubMember = () => {
  const { id } = useParams()
  const [member, setMember] = useState("")
  const history = useHistory()

  useEffect(() => {
    const getMember = async () => {
      const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/club/member/${id}`)
      setMember(res.data)
    }
    getMember()
  }, [])

  const onChange = (e) => {
    setMember({...member, [e.target.name]:e.target.value})
  }

  const onSubmit = async (e) => {
    e.preventDefault();
    const res = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/v1/club/member/${id}`,member)
    
    history.push('/club')
  }

  return (
    <div class="container-fluid loaded" style={{ display: 'block' }}>
      <div class="row">
        <div class="col-12">
          <div class="page-title-box">
            <div class="page-title-right">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <Link to="/">Dashboard</Link>
                </li>
                <li class="breadcrumb-item active">Club</li>
              </ol>
            </div>
            <h4 class="page-title">Club</h4>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <h4 class="header-title mb-3">Edit member "{member && member.user.name}"</h4>
              <form
                onSubmit={onSubmit}
              >
                <fieldset>
                  <div class="form-group row">
                    <label class="col-4 col-form-label">Access level:</label>
                    <div class="col-8">
                      <select
                        class="custom-select select2"
                        name="access_level"
                        value={member.access_level}
                        onChange={onChange}
                      >
                        <option value="0">Player</option>
                        <option value="70">Assistent</option>
                        <option value="80">Coach</option>
                        <option value="100">Manager</option>
                      </select>
                    </div>
                  </div>
                </fieldset>
                <button
                  type="submit"
                  class="btn button"
                >
                  <i class="fa fa-fw fa-floppy-o"></i> Save member
                </button>
                <Link to="/club" class="button-cancel">
                  <i class="fa fa-fw fa-step-backward"></i>&nbsp;Cancel
                </Link>
                <input type="hidden" name="op" value="" />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditClubMember;
