import React, { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom";
import { Link } from "react-router-dom"
import { Col, Modal } from "react-bootstrap";
import axios from "axios";

// Multi language property
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import common_en from "../../translations/en/common.json"
import common_de from "../../translations/de/common.json"
import common_nl from "../../translations/nl/common.json"
import moment from 'moment'

import BasicContent from "../../components/Basic/Content/partikel/BasicContent"
import { CardWithoutHead, Card } from '../../components/Basic/Content/molekul/Card'
import InputWithErrors from "../../components/Basic/Input/InputWithErrors";
import SelectWithErrors from "../../components/Basic/Select/SelectWithErrors";

// Prime React Property
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import "primereact/resources/themes/lara-light-indigo/theme.css";  //theme
import "primereact/resources/primereact.min.css";                  //core css
import "primeicons/primeicons.css";

// React Toast Property
import { toast, ToastContainer } from "react-toastify";

i18n
    .use(initReactI18next)
    .init({
        interpolation: { escapeValue: false },  // React already does escaping
        lng: 'en',                              // language to use
        resources: {
            en: {
                common: common_en
            },
            de: {
                common: common_de
            },
            nl: {
                common: common_nl
            }
        },
    });

const SpecialPermission = () => {
    // Get User info from local storage
    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    // Get jsonwebtoken from browser storage
    const token = userInfo ? userInfo.token : null
    // Config Header
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    }

    const { t } = useTranslation('common')
    const history = useHistory()
    const { id } = useParams()
    const [isLoading, setIsLoading] = useState(false)

    // Function to call toast after success
    const toastSuccess = () => {
        toast.success('Add New User Success !', {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 200,
            onClose: () => {
                history.push("/users/list");
            }
        })
    }

    // Start Group Function to confirm PrimeReact Confirmation Box
    const confirm2 = (id, e) => {
        e.preventDefault()
        confirmDialog({
            message: 'Do you want to delete this user from special permission?',
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept: () => accept(id),
            reject
        });
    };
    const accept = async (id) => {
        const permission = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/v1/permission/${id}`, config)
        setIsUpdated(!isUpdated)
    }
    const reject = () => {

    }
    // End Group Function to confirm PrimeReact Confirmation Box

    // Page Variable
    // State for how many page on the table list
    const [pageNumber, setPageNumber] = useState([])
    // State to show which page of table we are now
    const [currentPage, setCurrentPage] = useState(1)
    // State for activate which page is active and will get blue background
    const [isActive, setIsActive] = useState({ ods_1: true })
    // Pagination setting will send to backend as url query parameter to get club data
    const [pagination, setPagination] = useState({
        search: '',
        page: 1,
        limit: 5
    })

    const { search, page, limit } = pagination

    let lastPage = 1

    // Pages Function 
    const pageOnClick = (val) => {
        setCurrentPage(val)
        setPagination({ ...pagination, page: val })
        setIsActive({ [`ods_${val}`]: true })
    }

    const [userStatus, setUserStatus] = useState([])

    // This function is for move forward when we click Next on pagination
    const onNextPage = () => {
        if (currentPage === pageNumber.length) {
            return
        }
        setCurrentPage(currentPage + 1)
        setPagination({ ...pagination, page: currentPage + 1 })
        setIsActive({ [`ods_${currentPage + 1}`]: true })
    }

    // This function is for move backforward when we click Previous on pagination
    const onPrevPage = () => {
        if (currentPage === 1) {
            return
        }
        setCurrentPage(currentPage - 1)
        setPagination({ ...pagination, page: currentPage - 1 })
        setIsActive({ [`ods_${currentPage - 1}`]: true })
    }

    const onChange = (e) => {
        setCurrentPage(1);
        setIsActive({ "ods_1": true })
        setPagination({ ...pagination, page: 1, search: e.target.value })
    }

    // Function and Variabel For Each Page
    const [listPermission, setListPermission] = useState([])
    const [isUpdated, setIsUpdated] = useState(false)
    const [users, setUsers] = useState([])

    useEffect(() => {
        const getPermission = async () => {
            const permission = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/permission`, config)
            setListPermission(permission.data)
        }
        getPermission()
    }, [isUpdated])

    useEffect(() => {
        const getUsers = async () => {
          try {
            setIsLoading(true)
            const users = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/auth/user?search=${search}&page=${page}&limit=${limit}`, config)
            if (page !== lastPage) {
              setUserStatus([])
            }

            users.data.rows.map(dt => {
              setUserStatus(current => [...current, dt.status])
            })
            setPageNumber(Array(Math.ceil(users.data.count / limit)).fill(0))
            setUsers(users.data.rows)
    
            lastPage = page
            setTimeout(() => {
              setIsLoading(false)
            }, 100);
          } catch (error) {
            dispatch(loginStatus())
          }
        }
        getUsers()
      }, [search, currentPage, isUpdated])

      const addUser = async(uid, e) => {
        e.preventDefault()
        const body = {
            uid
        }
        const permission = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/permission`, body, config)
        setIsUpdated(!isUpdated)
      }

    return (
        <BasicContent
            title="Special Permission"
            breadcrumb={
                [
                    { link: '/', name: t('ADMIN_HOME') },
                ]
            }
        >
            <ToastContainer />
            <Col sm={12}>
                <CardWithoutHead>
                    <div className="app-search search-form float-right">
                        <div className="form-group position-relative">
                            <input
                                type="text"
                                name="edit[q]"
                                className="form-control submittable"
                                onChange={onChange}
                                value={search}
                                placeholder="Search by name..."
                            />
                            <span className="fa fa-search search-icon"></span>
                        </div>
                    </div>
                    <h4 className="header-title mb-3 mt-1">Users</h4>
                    <table id="tbl0" className="table">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {users.map((user, index) => (
                                <tr key={user.uid}>
                                    <td>{user.name}</td>
                                    <td>{user.email}</td>
                                    <td><a href="" onClick={(e) => addUser(user.uid, e)}><i className="icon-button fa fa-border fa-plus trash-alt color-green-bg"></i></a></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <ul className="pagination mb-10">
                        <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
                            <a style={{ cursor: 'pointer' }} onClick={onPrevPage} className="page-link" tabIndex="-1">Previous</a>
                        </li>
                        {pageNumber.map((item, index) => (
                            <li key={index} className={`page-item ${isActive[`ods_${index + 1}`] ? "active" : ""}`}><a style={{ cursor: 'pointer' }} className="page-link" onClick={() => pageOnClick(index + 1)} >{index + 1}</a></li>
                        ))}
                        <li className={`page-item ${currentPage === pageNumber.length ? "disabled" : ""}`}>
                            <a style={{ cursor: 'pointer' }} onClick={onNextPage} className="page-link">Next</a>
                        </li>
                    </ul>

                    <div className="mt-5">
                        <h5 style={{ marginTop: '20px' }}>PERMISSIONS</h5>
                        <table className="table table-bordered table-striped">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {listPermission.map(permission => (
                                    <tr>
                                        <td>{permission.user.name}</td>
                                        <td>{permission.user.email}</td>
                                        <td><a href="" onClick={(e) => confirm2(permission.uid, e)}><i className="icon-button fa fa-border fa-trash trash-alt color-red-bg"></i></a></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </CardWithoutHead>
            </Col>
            <ConfirmDialog />
        </BasicContent>
    )
}

export default SpecialPermission