import React from "react";
import { Link } from "react-router-dom";


const BasicContent = ({ breadcrumb=[], title, children }) => {
    return (
        <div className="container-fluid loaded" style={{ display: "block" }}>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box">
                        <div className="page-title-right">
                            <ol className="breadcrumb">
                                {breadcrumb.map((item,index) => (
                                    <li key={index} className={`breadcrumb-item ${index === (breadcrumb.length - 1) ? 'active' : ''}`}>
                                        {index === (breadcrumb.length -1 ) ? item.name : <Link to={item.link}>{item.name}</Link>}
                                    </li>
                                ))}
                            </ol>
                        </div>
                        <h4 className="page-title">{title}</h4>
                    </div>
                </div>
            </div>
            <div className="row">
                {children}
            </div>
        </div>
    )
}

export default BasicContent