import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import './style.css'
import footballField from '../../data/pitch.png'
import axios from 'axios'
import _, { isArrayLike } from 'lodash'
import { loginStatus, authFail } from '../../actions/userActions';
import { useDispatch } from 'react-redux';
import { Bars } from 'react-loader-spinner'

const BotPlatform = () => {
  const [ea5file, setEa5File] = useState({ sceneObjects: [] })
  const [isActive, setIsActive] = useState({ ods_1: true })
  const [frameLength, setFrameLength] = useState([])
  const [frameResult, setFrameResult] = useState({})
  const [currentFrame, setCurrentFrame] = useState({})
  const [notification, setNotification] = useState("No player has been selected")
  const [saicaResult, setSaicaResult] = useState({})
  const [playButton, setPlayButton] = useState(false)
  const [isPlayDisable, setIsPlayDisable] = useState(false)
  const [breakLoop, setBreakLoop] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isFileLoaded, setIsFileLoaded] = useState(false)
  const [teamA, setTeamA] = useState({})
  const [teamB, setTeamB] = useState({})
  const [isPlay, setIsPlay] = useState(false)
  let listTeamA = []
  let listTeamB = []
  const [ball, setBall] = useState({})

  var width = 920;
  var height = 600;
  let stage;
  var layer = new Konva.Layer();

  const user = JSON.parse(localStorage.getItem('userInfo'))

  const dispatch = useDispatch()

  useEffect(() => {
    const createLog = async () => {
      const log = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/log`, {userId: user.user_id, name: user.user_name, page: 'AI Bot Platform'})
    }
    createLog()
  }, [])

  useEffect(() => {
    dispatch(loginStatus())
  }, [])

  const onChange = (e) => {
    setPlayButton(false)
    setNotification([])
    const fileReader = new FileReader()
    fileReader.readAsText(e.target.files[0], "UTF-8")
    fileReader.onload = async e => {
      const parseResult = JSON.parse(e.target.result)
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Access-Control-Allow-Origin': '*'
        }
      }
      const postProc = await axios.post(`${process.env.REACT_APP_SAICA_COMP_URL}/post-proc`, parseResult, config)

      const length = Object.keys(postProc.data).length
      setIsFileLoaded(true)
      setFrameResult(postProc.data)
      setFrameLength(Array(length).fill(0))
      setCurrentFrame(postProc[0])
      setTeamA(postProc.data[0].team_a)
      setTeamB(postProc.data[0].team_b)
      setBall(postProc.data[0].ball_coor)
      console.log(postProc.data[0].team_a)
    }
  }

  const pageOnClick = (val) => {
    setCurrentFrame(frameResult[val - 1])
    setTeamA(frameResult[val - 1].team_a)
    setTeamB(frameResult[val - 1].team_b)
    setBall(frameResult[val - 1].ball_coor)
    setIsActive({ [`ods_${val}`]: true })
  }

  const resetBotPlatform = () => {
    setEa5File({ sceneObjects: [] })
    setIsActive({ ods_1: true })
    setFrameLength([])
    setFrameResult({})
    setCurrentFrame({})
    setNotification("No player has been selected")
    setBreakLoop(true)
    setSaicaResult({})
    setPlayButton(false)
    setIsPlayDisable(false)
    setIsLoading(false)
    setIsFileLoaded(false)
    setTeamA({})
    setTeamB({})
    setIsPlay(false)
    listTeamA = []
    listTeamB = []
    setBall({})
  }

  const startScenario = async () => {
    let index = 0
    for (const data in saicaResult.tracking) {
      if(breakLoop) break;
      setTimeout(() => {
        for (const dt in saicaResult.tracking[data].team_a) {
          window['teama_' + dt].x((920 / 2) * (saicaResult.tracking[data].team_a[dt].x + 1))
          window['teama_' + dt].y((600 / 0.96) * (saicaResult.tracking[data].team_a[dt].y + 0.48))
        }
        for (const dt in saicaResult.tracking[data].team_b) {
          window['teamb_' + dt].x((920 / 2) * (saicaResult.tracking[data].team_b[dt].x + 1))
          window['teamb_' + dt].y((600 / 0.96) * (saicaResult.tracking[data].team_b[dt].y + 0.48))
        }
        ballTeam.x((920 / 2) * (saicaResult.tracking[data].ball.x + 1))
        ballTeam.y((600 / 0.96) * (saicaResult.tracking[data].ball.y + 0.48))
      }, 150 * index);
      index++
    }

    setTimeout(() => {
      setIsPlayDisable(false)
    }, 150 * Object.keys(saicaResult['tracking']).length);
  }

  useEffect(() => {
    stage = new Konva.Stage({
      container: 'konva-container',
      width: width,
      height: height,
    });

    // Image API
    var imageObj = new Image()
    imageObj.onload = function () {
      var football = new Konva.Image({
        x: 0,
        y: 0,
        image: imageObj,
        width,
        height
      })

      layer.add(football)
      football.zIndex(0)
    }

    imageObj.src = footballField

    if (!_.isEmpty(teamA) && !isPlay) {
      let indexa = 0;
      for (const team in teamA) {
        window["teama_" + team] = new Konva.Circle({
          x: ((stage.width() / 2) * (1 + teamA[team].x)),
          y: ((stage.height() / 0.96) * (0.48 + teamA[team].y)),
          radius: 7,
          fill: 'red',
          name: "teama_" + team,
          draggable: true
        });
        listTeamA.push('teama_' + team)
        layer.add(window["teama_" + team])
        indexa++;
      }
      let indexb = 0
      for (const team in teamB) {
        window["teamb_" + team] = new Konva.Circle({
          x: ((stage.width() / 2) * (1 + teamB[team].x)),
          y: ((stage.height() / 0.96) * (0.48 + teamB[team].y)),
          radius: 7,
          fill: 'blue',
          name: 'teamb_' + team,
          draggable: true
        });
        listTeamB.push('teamb_' + team)
        layer.add(window["teamb_" + team])
        indexb++;
      }

      window["ballTeam"] = new Konva.Circle({
        x: ((stage.width() / 2) * (1 + ball.x)),
        y: ((stage.height() / 0.96) * (0.48 + ball.y)),
        radius: 5,
        fill: 'white',
        name: 'ball',
        draggable: true
      })

      layer.add(ballTeam)
    } else if (!_.isEmpty(teamA) && isPlay) {
      let indexa = 0;
      for (const team in teamA) {
        window["teama_" + (indexa + 1)] = new Konva.Circle({
          x: ((stage.width() / 2) * (1 + teamA[team].x)),
          y: ((stage.height() / 0.96) * (0.48 + teamA[team].y)),
          radius: 7,
          fill: 'red',
          name: "teama_" + (indexa + 1),
          draggable: true
        });
        listTeamA.push('teama_' + (indexa + 1))
        layer.add(window["teama_" + (indexa + 1)])
        indexa++;
      }
      let indexb = 0
      for (const team in teamB) {
        window["teamb_" + (indexb + 1)] = new Konva.Circle({
          x: ((stage.width() / 2) * (1 + teamB[team].x)),
          y: ((stage.height() / 0.96) * (0.48 + teamB[team].y)),
          radius: 7,
          fill: 'blue',
          name: 'teamb_' + (indexb + 1),
          draggable: true
        });
        listTeamB.push('teamb_' + (indexb + 1))
        layer.add(window["teamb_" + (indexb + 1)])
        indexb++;
      }

      window['ballTeam'] = new Konva.Circle({
        x: ((stage.width() / 2) * (1 + ball.x)),
        y: ((stage.height() / 0.96) * (0.48 + ball.y)),
        radius: 5,
        fill: 'white',
        name: 'ball',
        draggable: true
      })

      layer.add(ballTeam)
    }
    stage.add(layer);
  }, [teamA, teamB, ball, breakLoop == true])

  const sendData = async () => {
    setNotification([])
    setIsLoading(true)
    setTeamA({})
    setTeamB({})
    setBall({})
    let team_a = {}
    let team_b = {}
    let ball_coor = {}

    listTeamA.map(team => {
      team_a[team] = ({ x: ((2 / 921) * window[team].x()) - 1, y: ((0.96 / 600) * window[team].y()) - 0.48 })
    })
    listTeamB.map(team => {
      team_b[team] = ({ x: ((2 / 921) * window[team].x()) - 1, y: ((0.96 / 600) * window[team].y()) - 0.48 })
    })
    ball_coor = ball

    const data = {
      pass_action: {
        direction: [
          0,
          1,
          0
        ],
        passType: "idle",
        power: 0.3
      },
      scenario: {
        team_a,
        team_b,
        ball_coor,
        possession: "team_a"
      },
      settings: {
        max_steps: 200
      }
    }

    const config = {
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "multipart/form-data",
      },
      auth: {
        username: 'saica',
        password: 'Seoul2021!'
      }
    };

    const saicaRes = await axios.post(`${process.env.REACT_APP_SAICA_COMP_URL}/bot-helper`, data)
    const jsonBlob = new Blob([JSON.stringify(saicaRes.data)], { type: 'application/json' });
    const file = new File([jsonBlob], 'data.json', { type: 'application/json' });

    const formData = new FormData();
    formData.append('file', file);
    const finalSaica = await axios.post(`${process.env.REACT_APP_SAICA_URL}/api/football_sim/`, formData, config)
    setIsPlay(true)
    setSaicaResult(finalSaica.data)
    setTeamA(finalSaica.data.tracking[0].team_a)
    setTeamB(finalSaica.data.tracking[0].team_b)
    setBall(finalSaica.data.tracking[0].ball)
    setFrameLength([])
    setFrameResult({})
    setCurrentFrame({})
    listTeamA = []
    listTeamB = []
    setIsLoading(false)
    setPlayButton(true)
    document.querySelector('input[name="file"]').value = ""
  }

  return (
    <div className="container-fluid loaded" style={{ display: 'block' }}>
      <div style={{ display: isLoading ? "block" : "none", position: "absolute", top: "27rem", left: "46.4rem", zIndex: 1, color: "red" }}>
        <Bars
          height="80"
          width="80"
          color="red"
          ariaLabel="bars-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
        <p style={{ textAlign: "center", fontSize: "20px" }}>Processing....</p>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="page-title-box">
            <div className="page-title-right">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/">Dashboard</Link>
                </li>
                <li className="breadcrumb-item">AI Bot Platform
                </li>
                <li className="breadcrumb-item active">AI Bot Platform
                </li>
              </ol>
            </div>
            <h4 className="page-title">AI Bot Platform</h4>
          </div>
        </div>
      </div>
      <div className="form-messages"></div>
      <div className="notification">
        <ul>
          <li>{notification}</li>
        </ul>
      </div>
      <div className="row"><div className="col-12">
        <div className="card">
          <div className="card-body">
            <div style={{ margin: 0, padding: 0 }} id="konva-container" className='picture col-12'>
            </div>
            <div>
              <div className='row'>
                <label className="col-4 col-form-label">
                  <span className="help" title=".mp4,.mov,..">
                    Scenario File
                  </span>
                </label>
                <div className='col-8'>
                  <input onChange={onChange} name="file" id="filename" className='form-control' type="file" />
                </div>
              </div>
              <button disabled={!isFileLoaded} onClick={sendData} style={{ display: playButton ? "none" : "block", marginTop: "20px", marginRight: "20px" }} className='btn btn-primary'>Start</button>
              <button disabled={isPlayDisable} onClick={startScenario} style={{ display: playButton ? "inline" : "none", marginTop: "20px" }} className='btn btn-success'>Play</button>
              <button disabled={isPlayDisable} onClick={resetBotPlatform} style={{ display: playButton ? "inline" : "none", marginTop: "20px", marginLeft: "10px" }} className='btn btn-danger'>Reset</button>
              <div className='row' style={{ marginTop: "30px", maxWidth: "920px" }}>
                <ul className="pagination flex-wrap">
                  {frameLength.length > 0 && frameLength.map((item, index) => (
                    <li key={index} className={`page-item ${isActive[`ods_${index + 1}`] ? "active" : ""}`}><a style={{ cursor: 'pointer' }} className="page-link" onClick={() => pageOnClick(index + 1)} >{index + 1}</a></li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  )
}

export default BotPlatform