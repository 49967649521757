const dateFormat = (date='1970-01-01') => {
    const newFormat = new Date(date)
   
    return addTwoNumber(newFormat.getDate())+"-"+ numberToMonth(newFormat.getMonth())+"-"+newFormat.getFullYear()
}

const addTwoNumber = (text) => {
    if(text.toString().length === 1){
        return text.toString().padStart(2, "0")
    }
    return text
}

const numberToMonth = (number) => {
    const month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dev']
    return month[number]
}

export default dateFormat