import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <footer className="footer">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6">2023 © SAIVA</div>
              <div className="col-md-6">
              </div>
            </div>
          </div>
        </footer>
  )
}

export default Footer