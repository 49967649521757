import React, { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom";
import { Link } from "react-router-dom"
import { Col, Modal } from "react-bootstrap";
import { RotatingLines } from "react-loader-spinner";
import axios from "axios";

// Multi language property
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import common_en from "../../translations/en/common.json"
import common_de from "../../translations/de/common.json"
import common_nl from "../../translations/nl/common.json"

import BasicContent from "../../components/Basic/Content/partikel/BasicContent"
import { CardWithoutHead, Card } from '../../components/Basic/Content/molekul/Card'
import InputWithErrors from "../../components/Basic/Input/InputWithErrors";
import SelectWithErrors from "../../components/Basic/Select/SelectWithErrors";

// Prime React Property
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import "primereact/resources/themes/lara-light-indigo/theme.css";  //theme
import "primereact/resources/primereact.min.css";                  //core css
import "primeicons/primeicons.css";
import _ from "lodash"

import footballField from "../../data/pitch.jpg"

// React Toast Property
import { toast, ToastContainer } from "react-toastify";

import './editor.css'

i18n
    .use(initReactI18next)
    .init({
        interpolation: { escapeValue: false },  // React already does escaping
        lng: 'en',                              // language to use
        resources: {
            en: {
                common: common_en
            },
            de: {
                common: common_de
            },
            nl: {
                common: common_nl
            }
        },
    });


var pp = false
let listTeamA = []
let listTeamB = []
let index = 1
let teamID = []
let teamType = []

const ScenarioEditor = () => {
    // Get User info from local storage
    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    // Get jsonwebtoken from browser storage
    const token = userInfo ? userInfo.token : null

    const { t } = useTranslation('common')
    const history = useHistory()
    const { id } = useParams()
    const [isLoading, setIsLoading] = useState(false)

    // KonvaJs Related Variable and Function
    const [isDrag, setIsDrag] = useState(false)
    const [isPlaying, setIsPlaying] = useState(false)
    const [teamA, setTeamA] = useState({})
    const [teamB, setTeamB] = useState({})
    const [ball, setBall] = useState({})
    const [frameNumber, setFrameNumber] = useState(0)
    const [frameLength, setFrameLength] = useState([])
    const [stageHeight, setStageHeight] = useState(0)
    const [stageWidth, setStageWidth] = useState(0)
    const [pause, setPause] = useState(false)
    const [henti, setHenti] = useState(false)
    const [showEditObject, setShowEditObject] = useState(false)
    const [isPositionUpdated, setIsPositionUpdated] = useState(false)
    const [activeFrameButton, setActiveFrameButton] = useState({ 'button_0': true })
    const [editType, setEditType] = useState('drag')
    const [teamObject, setTeamObject] = useState({})
    const editOption = {
        position: 'position',
        edit: 'edit',
        add: 'add',
        delete: 'delete'
    }

    const configJson = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    }

    // Canvas Konva
    var width = 1060
    var height = 692

    let stage

    var layer = new Konva.Layer()

    useEffect(() => {
        teamID = []

        teamType = []

        const getScenario = async () => {

            const scenarios = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/scenarios/get?id=${id}`, configJson)

            const tempA = scenarios.data.sceneObjects.filter(obj => {
                if (obj.team) {
                    return obj.team === 2
                }
                if (obj.shirt) {
                    return obj.shirt === 2
                }
            })

            const tempB = scenarios.data.sceneObjects.filter(obj => {
                if (obj.team) {
                    return obj.team === 1
                }
                if (obj.shirt) {
                    return obj.shirt === 1
                }
            })

            const ball = scenarios.data.sceneObjects.filter(obj => {
                return obj.type === 'ball_01'
            })

            scenarios.data.sceneObjects.map(item => {
                if (!teamID.some(it => it == item.team_ids) && item.id !== 'ball') {
                    teamID.push(item.team_ids)
                }

                if (!teamType.some(it => it == item.type) && item.id !== 'ball') {
                    teamType.push(item.type)
                }
            })

            setBall(ball)
            setFrameLength(Array(scenarios.data.info.sceneCount).fill(0))
            setTeamB(tempB)
            setTeamA(tempA)
        }
        getScenario()

    }, [isPositionUpdated])

    useEffect(() => {
        stage = new Konva.Stage({
            container: 'konva-container',
            width: width,
            height: height,
        });

        listTeamA = []
        listTeamB = []

        setStageHeight(stage.height())
        setStageWidth(stage.width())

        if (pause) { setHenti(true) }

        if (!_.isEmpty(teamA)) {
            let indexa = 0;

            teamA.map(team => {
                const converted = convert_coordinate(team.transitions[frameNumber].position.y, team.transitions[frameNumber].position.x, stage.width(), stage.height())

                window["teama_" + (indexa + 1)] = new Konva.Circle({
                    x: converted.x,
                    y: converted.y,
                    radius: 7,
                    fill: 'red',
                    name: "teama_" + (indexa + 1),
                    draggable: true,
                    id: team.id,
                    team: team.team,
                    team_ids: team.team_ids,
                    type: team.type
                });

                window["teamatext_" + (indexa + 1)] = new Konva.Text({
                    x: converted.x,
                    y: converted.y,
                    text: "Indexa_" + indexa
                });

                listTeamA.push(window["teama_" + (indexa + 1)])

                layer.add(window["teama_" + (indexa + 1)])

                window["teama_" + (indexa + 1)].zIndex(1)

                window["teama_" + (indexa + 1)].on('mouseenter', (e) => {
                    stage.container().style.cursor = 'pointer';
                })

                window["teama_" + (indexa + 1)].on('mouseleave', (e) => {
                    stage.container().style.cursor = 'default';
                })

                window["teama_" + (indexa + 1)].on('dragend', (e) => {
                    setPosition(e.target.attrs)
                    setEditType(editOption.position)
                })

                window["teama_" + (indexa + 1)].on('dblclick', (e) => {
                    setEditType(editOption.edit)
                    setTeamObject({ id: e.target.attrs.id, type: e.target.attrs.type, team: e.target.attrs.team_ids })
                    setShowEditObject(true)
                })

                indexa++;
            })

            let indexb = 0

            teamB.map(team => {
                const converted = convert_coordinate(team.transitions[frameNumber].position.y, team.transitions[frameNumber].position.x, stage.width(), stage.height())
                window["teamb_" + (indexb + 1)] = new Konva.Circle({
                    x: converted.x,
                    y: converted.y,
                    radius: 7,
                    fill: 'blue',
                    name: 'teamb_' + (indexb + 1),
                    draggable: true,
                    id: team.id,
                    team: team.team,
                    team_ids: team.team_ids,
                    type: team.type
                });
                listTeamB.push(window['teamb_' + (indexb + 1)])

                layer.add(window["teamb_" + (indexb + 1)])

                window["teamb_" + (indexb + 1)].zIndex(1)

                window["teamb_" + (indexb + 1)].on('mouseenter', (e) => {
                    stage.container().style.cursor = 'pointer';
                })

                window["teamb_" + (indexb + 1)].on('mouseleave', (e) => {
                    stage.container().style.cursor = 'default';
                })

                window["teamb_" + (indexb + 1)].on('dragend', (e) => {
                    setPosition(e.target.attrs)
                    setEditType(editOption.position)
                })

                window["teamb_" + (indexb + 1)].on('dblclick', (e) => {
                    setEditType(editOption.edit)
                    setTeamObject({ id: e.target.attrs.id, type: e.target.attrs.type, team_ids: e.target.attrs.team_ids })
                    setShowEditObject(true)
                })

                indexb++;
            })

            const ball_converted = convert_coordinate(ball[0].transitions[frameNumber].position.y, ball[0].transitions[frameNumber].position.x, stage.width(), stage.height())

            window["ballTeam"] = new Konva.Circle({
                x: ball_converted.x,
                y: ball_converted.y,
                radius: 6,
                fill: 'white',
                name: 'ball',
                draggable: true
            })
            layer.add(ballTeam)
        }
        fitStage()
        stage.add(layer)

    }, [teamA, teamB, frameNumber, isDrag])

    const fitStage = () => {
        var container = document.querySelector('#stage-parent');

        if (container) {
            var containerWidth = container.offsetWidth;

            if (containerWidth === 0) {
                containerWidth = window.innerWidth / 1.45
            }

            var scale = containerWidth / width;

            stage.width(width * scale);
            stage.height(height * scale);
            stage.scale({ x: scale, y: scale });
        }
    }

    const setPosition = (attribute) => {
        window[attribute.name].attrs.x = attribute.x
        window[attribute.name].attrs.y = attribute.y
        if (attribute.name.split("_")[0] === 'teama') {
            teamA.map(item => {
                if (item.id === attribute.id) {
                    const converted = reverseConvert(attribute.x, attribute.y, stageWidth, stageHeight)
                    const indexTeam = teamA.findIndex(it => it.id === item.id)
                    teamA[indexTeam].transitions[frameNumber].position.x = converted.y
                    teamA[indexTeam].transitions[frameNumber].position.y = converted.x
                }
            })
        } else if (attribute.name.split("_")[0] === 'teamb') {
            teamB.map(item => {
                if (item.id === attribute.id) {
                    const converted = reverseConvert(attribute.x, attribute.y, stageWidth, stageHeight)
                    const indexTeam = teamB.findIndex(it => it.id === item.id)
                    teamB[indexTeam].transitions[frameNumber].position.x = converted.y
                    teamB[indexTeam].transitions[frameNumber].position.y = converted.x
                }
            })
        }
    }

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const reverseConvert = (x, y, dim_x, dim_y) => {
        const xmin_A = 52.5
        const xmax_A = -52.5
        const ymin_A = -34
        const ymax_A = 34

        const xmin_B = 0
        const xmax_B = dim_x
        const ymin_B = dim_y
        const ymax_B = 0

        const x_range_A = xmax_A - xmin_A
        const x_range_B = xmax_B - xmin_B

        //const converted_x = ((x - xmin_A) / x_range_A) * x_range_B + xmin_B
        const converted_x = (((x - xmin_B) / x_range_B) * x_range_A) + xmin_A

        const y_range_A = ymax_A - ymin_A
        const y_range_B = ymax_B - ymin_B

        //const converted_y = ((y - ymin_A) / y_range_A) * y_range_B + ymin_B
        const converted_y = (((y - ymin_B) / y_range_B) * y_range_A) + ymin_A

        return {
            x: converted_x,
            y: converted_y
        }
    }

    const convert_coordinate = (x, y, dim_x, dim_y) => {
        const xmin_A = 52.5
        const xmax_A = -52.5
        const ymin_A = -34
        const ymax_A = 34

        const xmin_B = 0
        const xmax_B = dim_x
        const ymin_B = dim_y
        const ymax_B = 0

        const x_range_A = xmax_A - xmin_A
        const x_range_B = xmax_B - xmin_B

        const converted_x = ((x - xmin_A) / x_range_A) * x_range_B + xmin_B

        const y_range_A = ymax_A - ymin_A
        const y_range_B = ymax_B - ymin_B

        const converted_y = ((y - ymin_A) / y_range_A) * y_range_B + ymin_B

        return {
            x: converted_x,
            y: converted_y
        }
    }

    const scroll = document.querySelector('.canvas .control .two ul')

    const changeFrameNumber = (number, e) => {
        e.preventDefault()
        setActiveFrameButton({ [`button_${number}`]: true })
        setFrameNumber(number)
    }

    const pauseScenario = () => {
        pp = true
        setPause(true)
        setIsPlaying(false)
    }

    const playScenario = async () => {
        setIsPlaying(true)
        let i = frameNumber

        index = 1

        pp = false

        while (i < frameLength.length && pp === false) {
            setActiveFrameButton({ [`button_${i}`]: true })
            setFrameNumber(i)
            if (i > 0 && i % 10 === 0) {
                scroll.scrollLeft += index * 200
                index++
            }
            await sleep(200)
            i++
        }

        if (i === 56) {
            setFrameNumber(0)
            setActiveFrameButton({ [`button_0`]: true })
            scroll.scrollLeft = 0
        }
    }

    const backToFirstFrame = () => {
        setFrameNumber(0)
        setActiveFrameButton({ 'button_0' : true})
        scroll.scrollLeft = 0
    }


    const getPosition = async () => {
        if (editType === editOption.position) {
            const iteam = listTeamA.map(item => {
                const converted = reverseConvert(item.attrs.x, item.attrs.y, stageWidth, stageHeight)
                return {
                    x: converted.x,
                    y: converted.y,
                    id: item.attrs.id,
                    team: item.attrs.team
                }
            })

            const itebm = listTeamB.map(item => {
                const converted = reverseConvert(item.attrs.x, item.attrs.y, stageWidth, stageHeight)
                return {
                    x: converted.x,
                    y: converted.y,
                    id: item.attrs.id,
                    team: item.attrs.team
                }
            })

            const ball = {
                x: window["ballTeam"].attrs.x,
                y: window["ballTeam"].attrs.y,
            }

            const res = {
                scenario_id: id,
                frame: frameNumber,
                teamA: iteam,
                teamB: itebm,
                ball,
            }

            try {
                setIsLoading(true)
                const edit = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/scenarios/edit/${id}`, res, configJson)
                setIsLoading(false)
                setIsPositionUpdated(!isPositionUpdated)
            } catch (error) {
                console.log(error)
            }
        } else if (editType === editOption.edit) {
            console.log('edit')
        }
    }

    useEffect(() => {
        setTimeout(() => {
            fitStage()
        }, 200);
        window.addEventListener('resize', fitStage)
    }, [teamA, teamB])

    return (
        <BasicContent
            title="Groups"
            breadcrumb={
                [
                    { link: '/', name: t('ADMIN_HOME') },
                    { link: '/users/group', name: 'Group' }
                ]
            }
        >
            <ToastContainer />
            <Col sm={12}>
                <CardWithoutHead>
                    <div className="canvas">
                        <div className="canvas-rotating">
                            <div className="rotating-lines">
                                <RotatingLines
                                    strokeColor="red"
                                    strokeWidth="5"
                                    animationDuration="0.75"
                                    width="150"
                                    visible={isLoading}
                                />
                            </div>
                        </div>
                        <div style={{ width: "100%" }} id="stage-parent">
                            <div id="konva-container"></div>
                        </div>
                        <div className="control">
                            <div className="one">
                                <button disabled={isPlaying ? true : false} onClick={backToFirstFrame} className="start button"><i className="fa fa-backward"></i></button>
                                <button onClick={playScenario} className="start button"><i className="fa fa-play"></i></button>
                                <button onClick={pauseScenario} className="pause button"><i className="fa fa-pause"></i></button>
                            </div>
                            <div className="two">
                                <ul>
                                    {frameLength.length > 0 && frameLength.map((item, index) => (
                                        <li><a style={{ background: activeFrameButton[`button_${index}`] ? "blue" : "", color: activeFrameButton[`button_${index}`] ? "white" : "" }} onClick={(e) => changeFrameNumber(index, e)} href="">{index + 1}</a></li>
                                    ))}
                                </ul>
                            </div>
                            {/* <div className="three">
                                <button onClick={getPosition} className="button button-right btn-save"><i className="fa fa-save"></i></button>
                            </div> */}
                        </div>
                    </div>
                </CardWithoutHead>
            </Col>
            <ConfirmDialog />
            <Modal
                size="sm"
                show={showEditObject}
                onHide={() => setShowEditObject(false)}
            >
                <Modal.Body>
                    <div className="form-group">
                        <label htmlFor="" className="label-control">ID</label>
                        <input value={teamObject.id} type="text" className="form-control" />
                    </div>
                    <div style={{ display: "flex" }} className="form-group row">
                        <div className="group col-md-6">
                            <label htmlFor="" className="label-control">Type</label>
                            <select value={teamObject.type} name="type" id="type" className="form-control">
                                {teamType.map(item => (
                                    <option value={item}>{item}</option>
                                ))}
                            </select>
                        </div>
                        <div className="group col-md-6">
                            <label htmlFor="" className="label-control">Team</label>
                            <select value={teamObject.team_ids} name="team_ids" id="team_ids" className="form-control">
                                {teamID.map(item => (
                                    <option value={item}>{item}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </BasicContent>
    )
}

export default ScenarioEditor