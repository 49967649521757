import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { Modal, Button } from 'react-bootstrap'
import parse from 'html-react-parser'
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import { useDispatch } from "react-redux";
import { loginStatus } from "../../actions/userActions";
import { RotatingLines } from "react-loader-spinner";

const ViewTasks = () => {
  // Get jsonwebtoken from browser storage
  // State for how many page on the table list
  const [pageNumber, setPageNumber] = useState([]);
  // State to show which page of table we are now
  const [currentPage, setCurrentPage] = useState(1);
  // State for activate which page is active and will get blue background
  const [isActive, setIsActive] = useState({ ods_1: true });
  // Pagination setting will send to backend as url query parameter to get club data
  const [pagination, setPagination] = useState({
    search: "",
    page: 1,
    limit: 10,
  });
  // Pagination
  const { search, page, limit } = pagination;

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const uid = userInfo !== null ? userInfo.user_id : null;
  const token = userInfo !== null ? userInfo.token : null
  const dispatch = useDispatch()
  const [tasks, setTasks] = useState([]);
  const [image, setImage] = useState();

  const status = {
    0: "Waiting",
    1: "Pending",
    2: "Processing",
    3: "Failed",
    4: "Finished",
    5: "Server Error",
    6: "Video File Converted"
  };

  // Modal
  const [show, setShow] = useState(false)
  const handleClose = () => {
    setShow(false)
  }

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

    // Data Loading Status State
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const getTasks = async () => {
      try {
        setIsLoading(true)
        const tasks = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/v1/videotrackingai/uid/${uid}?search=${search}&page=${page}&limit=${limit}`, config
        );
        setPageNumber(Array(Math.ceil(tasks.data.count / limit)).fill(0));
        setTasks(tasks.data.rows);
        setIsLoading(false)
      } catch (error) {
        if(error.response.status === 401){
          dispatch(loginStatus())
        }
      }
    };
    getTasks();
  }, [search, currentPage]);

  const pageOnClick = (val) => {
    setCurrentPage(val);
    setPagination({ ...pagination, page: val });
    setIsActive({ [`ods_${val}`]: true });
  };

  // This function is for move forward when we click Next on pagination
  const onNextPage = () => {
    if (currentPage === pageNumber.length) {
      return;
    }
    setCurrentPage(currentPage + 1);
    setPagination({ ...pagination, page: currentPage + 1 });
    setIsActive({ [`ods_${currentPage + 1}`]: true });
  };

  // This function is for move backforward when we click Previous on pagination
  const onPrevPage = () => {
    if (currentPage === 1) {
      return;
    }
    setCurrentPage(currentPage - 1);
    setPagination({ ...pagination, page: currentPage - 1 });
    setIsActive({ [`ods_${currentPage - 1}`]: true });
  };

  const onChange = (e) => {
    setCurrentPage(1);
    setIsActive({ ods_1: true });
    setPagination({ ...pagination, page: 1, search: e.target.value });
  };

  Fancybox.bind("[data-fancybox]", {
    
  });

  const getErrorMessage = (id) => {

  }

  return (
    <div className="container-fluid loaded" style={{ display: "block" }}>
      <div className="rotating-lines">
        <RotatingLines
          strokeColor="grey"
          strokeWidth="5"
          animationDuration="0.75"
          width="82"
          visible={isLoading}
        />
      </div>
      <div className="row">
        <div className="col-12">
          <div className="page-title-box">
            <div className="page-title-right">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Dashboard</Link>
                </li>
                <li className="breadcrumb-item">Video Tracking AI</li>
                <li className="breadcrumb-item active">View tasks</li>
              </ol>
            </div>
            <h4 className="page-title">View tasks</h4>
          </div>
        </div>
      </div>
      <div className="form-messages"></div>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="app-search search-form float-right">
                <form
                  name="form0"
                  action="/en/videotracking/tasks"
                  method="post"
                  className="form-horizontal"
                >
                  <div className="form-group position-relative">
                    <input
                      type="text"
                      name="search"
                      className="form-control submittable"
                      value={search}
                      onChange={onChange}
                      placeholder="Search tasks..."
                    />
                    <span className="fa fa-search search-icon"></span>
                  </div>
                  <input type="hidden" name="op" value="" />
                </form>
              </div>
              <h4 className="header-title mb-3 mt-1">Active tasks</h4>
              <table id="tbl0" className="table">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>File</th>
                    <th>Server</th>
                    <th width="15%">Time</th>
                    <th width="5%">Length</th>
                    <th width="15%">Status</th>
                    <th width="80">Result</th>
                    <th width="68" nowrap="">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {tasks.map((task) => (
                    <tr>
                      <td>{task.task_id}</td>
                      <td>{JSON.parse(task.data).name}</td>
                      <td>{task.vt_server.name}</td>
                      <td>
                        {moment.unix(task.adddt).format("YYYY-MM-DD HH:mm")}
                      </td>
                      <td>{task.duration}s</td>
                      <td>{status[task.status]}</td>
                      {task.status === 4 && <td>
                        <a
                          data-fancybox="image"
                          data-type="iframe"
                          href={`${process.env.REACT_APP_BACKEND_URL}/api/v1/videotrackingai/image/${task.task_id}`}
                          title="ImageResult"
                        >
                          <i className="icon-button fa fa-border fa-image color-green-bg"></i>
                        </a>
                        <a
                          data-fancybox="data"
                          data-type="iframe"
                          href={`${process.env.REACT_APP_BACKEND_URL}/api/v1/videotrackingai/data/${task.task_id}?show="event"`}
                          title="DataResult"
                        >
                          <i className="icon-button fa fa-border fa-list color-gray-bg ml-1"></i>
                        </a>
                      </td>}
                      {(task.status === 3 || task.status === 5) && <td>
                        <a
                          href=""
                          onClick={getErrorMessage}
                        >
                          <i class="icon-button fa fa-border fa-exclamation-circle color-red-bg"></i>
                        </a>
                      </td>}
                      <td>
                        <a
                          href="#!"
                          title="Info"
                        >
                          <i className="icon-button fa fa-border fa-info color-blue-bg"></i>
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <ul className="pagination">
                <li
                  className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
                >
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={onPrevPage}
                    className="page-link"
                    tabIndex="-1"
                  >
                    Previous
                  </a>
                </li>
                {pageNumber.map((item, index) => (
                  <li
                    key={index}
                    className={`page-item ${isActive[`ods_${index + 1}`] ? "active" : ""
                      }`}
                  >
                    <a
                      style={{ cursor: "pointer" }}
                      className="page-link"
                      onClick={() => pageOnClick(index + 1)}
                    >
                      {index + 1}
                    </a>
                  </li>
                ))}
                <li
                  className={`page-item ${currentPage === pageNumber.length ? "disabled" : ""
                    }`}
                >
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={onNextPage}
                    className="page-link"
                  >
                    Next
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Modal size="lg" show={show} onHide={handleClose} animation={false}>
        <Modal.Body>
          {image && parse(image)}
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ViewTasks;
