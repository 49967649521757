import React, { useEffect, useState, useRef } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import axios from 'axios'
import _ from 'lodash'
import { useDispatch } from "react-redux";
import { HexColorPicker } from "react-colorful"
import { toast, ToastContainer } from 'react-toastify'
import { loginStatus } from "../../../actions/userActions";
import {InputWithErrors} from "../../../components/Basic/Input/InputWithErrors";

const EditClub = () => {
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  const token = userInfo !== null ? userInfo.token : null
  const history = useHistory()
  const dispatch = useDispatch()
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
  const configPut = {
    headers: {
      'Accept': 'multipart/form-data',
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`
    }
  }
  const [club, setClub] = useState({ status: "", name: "", member_licenses: "", notes: "" })
  const [userNonMember, setUserNonMember] = useState([])
  const [previewUrl, setPreviewUrl] = useState()
  const [previewSideboard, setPreviewSideBoard] = useState()
  const [file, setFile] = useState()
  const [sideboard, setSideboard] = useState()
  const [originalLogo, setOriginalLogo] = useState()
  const [additionalField, setAdditionalField] = useState({ uid: '', invite: '' })
  const [infoTabActive, setInfoTabActive] = useState(true)
  const [errors, setErrors] = useState([])
  const { id } = useParams()
  const [seatColor, setSeatColor] = useState("#000000")

  useEffect(() => {
    dispatch(loginStatus())
  }, [])

  useEffect(() => {
    if (!file) {
      return
    }
    const fileReader = new FileReader()

    fileReader.onload = () => {
      setOriginalLogo(previewUrl)
      setPreviewUrl(fileReader.result)
    }
    fileReader.readAsDataURL(file)
  }, [file])

  useEffect(() => {
    const getClub = async () => {
      const club = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/club/${id}`, config)

      console.log(club.data)
      setPreviewUrl(process.env.REACT_APP_BACKEND_URL + "" + club.data.club.images)
      setPreviewSideBoard(process.env.REACT_APP_BACKEND_URL + "" + club.data.club.sideboard)
      setSeatColor(club.data.club.seatColor)
      setClub(club.data.club)
      setUserNonMember(club.data.userNonMember)
    }
    getClub()
  }, [])

  const uploadLogo = (e) => {
    if (e.target.files && e.target.files.length === 1) {
      setFile(e.target.files[0])
    }
  }

  const uploadSideboard = (e) => {
    if (e.target.files && e.target.files.length === 1) {
      setSideboard(e.target.files[0])
    }
  }

  const getOriginalLogo = () => {
    setFile()
    setPreviewUrl(originalLogo)
    setOriginalLogo()
    document.querySelector('input[type="file"]').value = null
  }

  const updateClub = async (e) => {
    e.preventDefault();
    const formData = new FormData()

    formData.append('file', file)
    formData.append('sideboard', sideboard)
    formData.append("seatColor", seatColor)
    formData.append('status', club.status ? 1 : 0)
    formData.append('name', club.name)
    formData.append('member_licenses', club.member_licenses)
    formData.append('add_member', additionalField.uid)
    formData.append('email', additionalField.email)

    try {
      const updateClub = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/v1/club/${id}`, formData, configPut)

      toast.success('Update Club Success !', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 200,
        onClose: () => {
          history.push("/manageclub/clubs")
        }
      })
    } catch (error) {
      setErrors(error.response.data)
    }
  }

  const onChange = (e) => {
    if (e.target.name === "status") {
      setClub({ ...club, status: !club.status })
      return
    }
    setClub({ ...club, [e.target.name]: e.target.value })
  }

  const changeAdditonalField = (e) => {
    setAdditionalField({ ...additionalField, [e.target.name]: e.target.value })
  }

  const onClickTab = (tab) => {
    tab === "tab-1" ? setInfoTabActive(true) : setInfoTabActive(false)
  }
  return (
    <div className="container-fluid loaded" style={{ display: "block" }}>
      <ToastContainer />
      <div className="row">
        <div className="col-12">
          <div className="page-title-box">
            <div className="page-title-right">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Dashboard</Link>
                </li>
                <li className="breadcrumb-item">Manage Clubs</li>
                <li className="breadcrumb-item active">Clubs</li>
              </ol>
            </div>
            <h4 className="page-title">Clubs</h4>
          </div>
        </div>
      </div>

      <div className="form-messages"></div>
      <div className="row">
        <div className="col-12 col-sm-9">
          <div className="card">
            <div className="card-body">
              <h4 className="header-title mb-3">Edit</h4>
              <form
                onSubmit={updateClub}
                className="form-horizontal"
              >
                <ul className="nav nav-tabs nav-bordered">
                  <li className="nav-item">
                    <a style={{ cursor: "pointer" }} onClick={() => onClickTab("tab-1")} data-toggle="tab" className={`nav-link ${infoTabActive ? "active" : ""}`}>
                      <span>Club info</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a style={{ cursor: "pointer" }} onClick={() => onClickTab("tab-2")} data-toggle="tab" className={`nav-link ${!infoTabActive ? "active" : ""}`}>
                      <span>Notes</span>
                    </a>
                  </li>
                </ul>
                <div className="tab-content">
                  <div id="tab-1" className={`tab-pane ${infoTabActive ? "active" : ""}`}>
                    <p> </p>
                    <fieldset>
                      <div className="form-group row">
                        <label className="col-4 col-form-label">Status:</label>
                        <div className="col-8">
                          <input
                            className="form-control"
                            type="checkbox"
                            name="status"
                            value={club.status}
                            onChange={onChange}
                            checked={club.status}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-4 col-form-label">Name:</label>
                        <div className="col-8">
                          <InputWithErrors errors={errors} value={club.name} name="name" onChange={onChange} />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-4 col-form-label">
                          Member licenses:
                        </label>
                        <div className="col-8">
                          <input
                            className="form-control"
                            min="0"
                            max="100"
                            onChange={onChange}
                            name="member_licenses"
                            type="number"
                            value={club.member_licenses}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-4 col-form-label">
                          Add club member:
                        </label>
                        <div className="col-8">
                          <select
                            className="custom-select"
                            name="uid"
                            onChange={changeAdditonalField}
                            tabIndex="-1"
                            aria-hidden="true"
                          >
                            <option
                              value="na"
                              data-select2-id="3"
                            ></option>
                            {userNonMember.map(user => (
                              <option key={user.uid} value={user.uid}>{user.name}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-4 col-form-label">
                          Invite member:
                        </label>
                        <div className="col-8">
                          <input
                            className="form-control"
                            maxLength="64"
                            name="invite"
                            type="text"
                            onChange={changeAdditonalField}
                            value={additionalField.invite}
                            placeholder="Email..."
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-4 col-form-label">
                          Change club logo:
                        </label>
                        <div className="col-8">
                          <input onChange={uploadLogo} type="file" name="image" />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-4 col-form-label">
                          Seat colours:
                        </label>
                        <div className="col-8">
                          <HexColorPicker color={seatColor} onChange={setSeatColor} />
                          <input style={{ width: "200px" }} value={seatColor} type="text" className="form-control mt-2" />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-4 col-form-label">
                          Sideboards:
                        </label>
                        <div className="col-8">
                          <input onChange={uploadSideboard} type="file" name="image" />
                          <div>
                            <img className="mt-3" width={350} src={previewSideboard} />
                          </div>
                        </div>
                      </div>
                    </fieldset>
                    <p></p>
                  </div>
                  <div id="tab-2" className={`tab-pane ${!infoTabActive ? "active" : ""}`}>
                    <p> </p>
                    <fieldset>
                      <div className="form-row">
                        <textarea name="notes" value={club.notes} onChange={onChange} style={{ outline: "none", borderColor: "#cbd5e1", resize: "none" }} rows={15} cols={100} />
                      </div>
                    </fieldset>
                  </div>
                </div>
                <button type="submit" className="btn button">
                  <i className="fa fa-fw fa-floppy-o"></i> Save
                </button>
                <Link to="/manageclub/clubs" className="button-cancel">
                  <i className="fa fa-fw fa-step-backward"></i>&nbsp;Cancel
                </Link>
              </form>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-3 d-none d-sm-block">
          <div className="card">
            <div className="card-body">
              <img
                src={previewUrl}
                className="img-fluid rounded"
              />
            </div>
          </div>
          {!_.isEmpty(originalLogo) && <button onClick={getOriginalLogo} style={{ borderRadius: "50%", padding: "5px 10px", position: "absolute", top: "218px" }} classNameName="btn button-cancel btn-sm"><i classNameName="fa fa-times"></i></button>}
        </div>
      </div>
    </div>
  );
};

export default EditClub;
