import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";
import { RotatingLines } from "react-loader-spinner";
import { useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import { logout, loginStatus } from "../../../actions/userActions";
import "./index.css"

const ListClubs = () => {
  // Get User Info from local storage
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  // Get jsonwebtoken from browser storage
  const token = userInfo !== null ? userInfo.token : null
  // State for how many page on the table list
  const [pageNumber, setPageNumber] = useState([])
  // State to show which page of table we are now
  const [currentPage, setCurrentPage] = useState(1)
  // State for activate which page is active and will get blue background
  const [isActive, setIsActive] = useState({ ods_1: true })
  // Pagination setting will send to backend as url query parameter to get club data
  const [pagination, setPagination] = useState({
    search: '',
    page: 1,
    limit: 20
  })

  const [isUpdate, setIsUpdate] = useState(false)

  const { search, page, limit } = pagination

  const dispatch = useDispatch()

  const history = useHistory()

  // State as container or variable to receipt the club data from backend
  const [clubs, setClubs] = useState([])

  // Config headers for send to backend
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }

  // Delete Club State
  const [deleteConfirmation, setDeleteConfirmation] = useState(false)

  // Club Data State
  const [clubData, setClubData] = useState({ clubId: '', name: '' })

  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const getClubs = async () => {
      try {
        setIsLoading(true)
        const clubs = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/club?search=${search}&page=${page}&limit=${limit}`, config)
        setPageNumber(Array(Math.ceil(clubs.data.data.count / limit)).fill(0))
        setClubs(clubs.data.data.clubs)
        setTimeout(() => {
          setIsLoading(false)
        }, 100);
      } catch (error) {
        if (error.response.status === 401) {
          dispatch(loginStatus())
        }
      }
    }
    getClubs()
  }, [search, currentPage])

  const pageOnClick = (val) => {
    setCurrentPage(val)
    setPagination({ ...pagination, page: val })
    setIsActive({ [`ods_${val}`]: true })
  }

  // This function is for move forward when we click Next on pagination
  const onNextPage = () => {
    if (currentPage === pageNumber.length) {
      return
    }
    setCurrentPage(currentPage + 1)
    setPagination({ ...pagination, page: currentPage + 1 })
    setIsActive({ [`ods_${currentPage + 1}`]: true })
  }

  // This function is for move backforward when we click Previous on pagination
  const onPrevPage = () => {
    if (currentPage === 1) {
      return
    }
    setCurrentPage(currentPage - 1)
    setPagination({ ...pagination, page: currentPage - 1 })
    setIsActive({ [`ods_${currentPage - 1}`]: true })
  }

  const onChange = (e) => {
    setCurrentPage(1);
    setIsActive({ "ods_1": true })
    setPagination({ ...pagination, page: 1, search: e.target.value })
  }

  const deleteClub = async (e) => {
    const club = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/v1/club/${clubData.clubId}`, config)
    toast.success('Delete Club Success !', {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 200
    })
    setPagination({ page: 1, limit: 20, search: '' })
    setDeleteConfirmation(false)
  }

  const deleteClubConfirmation = async (clubId, name) => {
    setClubData({ clubId, name })
    setDeleteConfirmation(true)
  }

  return (
    <div className="container-fluid loaded" style={{ display: "block" }}>
      <ToastContainer />
      <div className="rotating-lines">
        <RotatingLines
          strokeColor="grey"
          strokeWidth="5"
          animationDuration="0.75"
          width="82"
          visible={isLoading}
        />
      </div>
      <div className="row">
        <div className="col-12">
          <div className="page-title-box">
            <div className="page-title-right">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Dashboard</Link>
                </li>
                <li className="breadcrumb-item">Manage Clubs</li>
                <li className="breadcrumb-item active">Clubs</li>
              </ol>
            </div>
            <h4 className="page-title">Clubs</h4>
          </div>
        </div>
      </div>
      <div className="form-messages"></div>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="app-search search-form float-right">
                <div className="form-group position-relative">
                  <input
                    type="text"
                    name="edit[q]"
                    className="form-control submittable"
                    onChange={onChange}
                    value={search}
                    placeholder="Search by name..."
                  />
                  <span className="fa fa-search search-icon"></span>
                </div>
                <input type="hidden" name="op" value="" />
              </div>
              <h4 className="header-title mb-3 mt-1">Clubs</h4>
              <table id="tbl0" className="table">
                <thead>
                  <tr>
                    <th width="32"></th>
                    <th>Name</th>
                    <th>Member licenses</th>
                    <th>Staff licenses</th>
                    <th width="72">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {clubs.map(club => (
                    <tr key={club.club_id}>
                      <td>
                        <img
                          src={process.env.REACT_APP_BACKEND_URL + "" + club.image}
                          alt="user-image"
                          style={{ width: "32px" }}
                        />
                      </td>
                      <td>{club.name}</td>
                      <td>{club.member_licenses}</td>
                      <td>{club.staff_licenses}</td>
                      <td>
                        <Link
                          to={`/manageclub/clubs/${club.club_id}`}
                          title="Edit"
                        >
                          <i className="icon-button fa fa-border fa-pencil pencil-alt color-green-bg"></i>
                        </Link>
                        <a onClick={() => deleteClubConfirmation(club.club_id, club.name)} style={{ cursor: "pointer" }}>
                          <i className="icon-button fa fa-border fa-trash trash-alt color-red-bg"></i>
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <ul className="pagination">
                <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
                  <a style={{ cursor: 'pointer' }} onClick={onPrevPage} className="page-link" tabIndex="-1">Previous</a>
                </li>
                {pageNumber.map((item, index) => (
                  <li key={index} className={`page-item ${isActive[`ods_${index + 1}`] ? "active" : ""}`}><a style={{ cursor: 'pointer' }} className="page-link" onClick={() => pageOnClick(index + 1)} >{index + 1}</a></li>
                ))}
                <li className={`page-item ${currentPage === pageNumber.length ? "disabled" : ""}`}>
                  <a style={{ cursor: 'pointer' }} onClick={onNextPage} className="page-link">Next</a>
                </li>
              </ul>
              <Link to="/manageclub/clubs/add" className="btn button">
                <i className="fa fa-fw fa-plus"></i> Add
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Modal
        size="md"
        show={deleteConfirmation}
        onHide={() => setDeleteConfirmation(false)}
      >
        <Modal.Header closeButton>
          <h4>Club</h4>
        </Modal.Header>
        <Modal.Body>
          <div className="row p-2">
            <div className="col-12">
              <h4 className="">Are you sure you want to delete club : {clubData.name} ?</h4>
            </div>

          </div>
          <div className="row p-2">
            <button onClick={deleteClub} className="btn btn-sm btn-success mr-2">Confirm</button>
            <button onClick={() => setDeleteConfirmation(false)} className="btn btn-sm btn-danger">Cancel</button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ListClubs;
