export const InputWithErrors = ({placeholder, errors, value, onChange, name, type="text"}) => {
    return (
        <>
            <input
                className="form-control"
                maxLength="64"
                name={name}
                onChange={onChange}
                type={type}
                value={value}
                placeholder={placeholder}
                style={{ border: errors.length > 0 ? errors.some(val => { return val.path[0] === name }) ? "1px red solid" : "" : "" }}
            />
            {errors.length > 0 ? errors.some((val) => { return val.path[0] === name }) && <p class="mt-1" style={{ color: "red", fontWeight: 600, fontSize: "14px" }}><i>{errors.find(val => val.path[0] === name).message}</i></p> : ""}
        </>
    )
}

export const Input = ({ placeholder, value, onChange, name, className="", type="text"}) => {
    return (
        <>
        <input
                className={`form-control ${className}`}
                maxLength="64"
                name={name}
                onChange={onChange}
                type={type}
                value={value}
                placeholder={placeholder}
            />
        </>
    )
}