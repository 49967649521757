import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import BasicContent from "../../../components/Basic/Content/partikel/BasicContent";
import { Col } from "react-bootstrap";
import { Card } from "../../../components/Basic/Content/molekul/Card";
import i18n from "i18next";
import axios from "axios";
import { useTranslation, initReactI18next } from "react-i18next";
import common_en from "../../../translations/en/common.json"
import common_de from "../../../translations/de/common.json"
import common_nl from "../../../translations/nl/common.json"
import { InputWithErrors } from "../../../components/Basic/Input/InputWithErrors";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import "primereact/resources/themes/lara-light-indigo/theme.css";  //theme
import "primereact/resources/primereact.min.css";                  //core css
import "primeicons/primeicons.css";

i18n
    .use(initReactI18next)
    .init({
        interpolation: { escapeValue: false },  // React already does escaping
        lng: 'en',                              // language to use
        resources: {
            en: {
                common: common_en
            },
            de: {
                common: common_de
            },
            nl: {
                common: common_nl
            }
        },
    });

const EditGroup = () => {
    const { t } = useTranslation('common')
    const { id } = useParams()
    const history = useHistory()

    const [group, setGroup] = useState({ group: '' })
    const [errors, setErrors] = useState([])

    useEffect(() => {
        const getGroupById = async () => {
            const group = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/group/${id}`)
            setGroup(group.data)
        }
        getGroupById()
    }, [])

    const onChange = (e) => {
        setGroup({ ...group, [e.target.name]: e.target.value })
    }

    const accept = async () => {
        try {
            await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/v1/group/${id}`)
            history.push('/users/group')
        } catch (error) {
            setErrors(error.response.data)
        }
    }

    const reject = () => {

    }

    const confirm2 = () => {
        confirmDialog({
            message: 'Do you want to delete this record?',
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept,
            reject
        });
    };

    const onClick = async (e) => {
        switch (e.target.name) {
            case 'save':
                try {
                    await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/v1/group/${id}`, group)
                    history.push('/users/group')
                } catch (error) {
                    setErrors(error.response.data)
                }
                break
            case 'delete':
                try {
                    await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/v1/group/${id}`)
                    history.push('/users/group')
                } catch (error) {
                    setErrors(error.response.data)
                }
                break
            default:
                history.push('/users/group')
                break;
        }
    }
    return (
        <BasicContent
            title="Groups"
            breadcrumb={
                [
                    { link: '/', name: t('ADMIN_HOME') },
                    { link: '/users/group', name: 'Group' },
                    { link: '/users/group', name: 'Edit Group' }
                ]
            }
        >
            <Col sm={12}>
                <Card title="Edit Group">

                    <div className="form-group row">
                        <label className="col-4">Group</label>
                        <div className="col-8">
                            <InputWithErrors
                                name="group"
                                value={group.group}
                                onChange={onChange}
                                errors={errors}
                            />
                        </div>
                    </div>
                    <button onClick={onClick} name="save" className="btn button"><i className="fa fa-fw fa-floppy-o"></i>Save</button>
                    <button onClick={onClick} name="cancel" className="button-cancel"><i className="fa fa-fw fa-step-backward"></i>Cancel</button>
                    <button onClick={confirm2} name="delete" className="btn button button-cancel button-right"><i className="fa fa-fw fa-trash-o"></i>Delete</button>

                </Card>
            </Col>
            <ConfirmDialog />
        </BasicContent>
    )
}

export default EditGroup