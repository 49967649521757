import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { loginStatus } from '../../actions/userActions'

const Download = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(loginStatus())
  })
  return (
    <div class="container-fluid loaded" style={{ display: 'block' }}>
      <div class="row">
        <div class="col-12">
          <div class="page-title-box">
            <div class="page-title-right">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><Link to="/">Dashboard</Link>
                </li>
                <li class="breadcrumb-item">Support
                </li>
                <li class="breadcrumb-item active">Downloads
                </li>
              </ol>
            </div>
            <h4 class="page-title">Downloads</h4>
          </div>
        </div>
      </div>
      <div class="row"><div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="header-title mb-3">Downloads</h4>
            On this page you can find tools and downloads for the SAIVA platform.<br />
            <br />
            <b><u>EasyAnimation</u></b><br />
            <a href={`${process.env.REACT_APP_BACKEND_URL}/download/easyanimation/easy-animation-4.0.58.exe`} download>Windows (4.0.58)</a><br />
            <a href={`${process.env.REACT_APP_BACKEND_URL}/download/easyanimation/easy-animation-4.0.58.dmg`} download>MacOS (4.0.58)</a><br />
          </div>
        </div>
      </div>
      </div>
    </div>
  )
}
export default Download