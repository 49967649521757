import React from "react";

export const Card = ({ title, children }) => {
    return (
        <div className="card">
            <div className="card-header">
                <h4 className="mb-1 mt-1">{title}</h4>
            </div>
            <div className="card-body">
                {children}
            </div>
        </div>
    )
}

export const CardWithoutHead = ({ children }) => {
    return (
        <div style={{height:"100vh"}} className="card">
            <div className="card-body">
                {children}
            </div>
        </div>
    )
}

