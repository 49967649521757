import React, { useState, useEffect, Suspense, useContext } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login, loginStatus, loginRequest } from "../../actions/userActions";
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import common_en from "../../translations/en/common.json";
import common_de from "../../translations/de/common.json";
import common_nl from "../../translations/nl/common.json";
import LogoLogin from "../../assets/images/logo-login.png";
import { LangContext } from "../../app";
import axios from "axios";

i18n.use(initReactI18next).init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: "en", // language to use
  resources: {
    en: {
      common: common_en,
    },
    de: {
      common: common_de,
    },
    nl: {
      common: common_nl,
    },
  },
});

const UserRegistration = () => {
  const { t } = useTranslation("common");
  const [form, setForm] = useState({
    password: "",
    confirmPassword: "",
    email: "",
    name: "",
    theme: "saiva",
    timezone: "Europe/Berlin",
    language: "en",
    pgid: 2
  });

  const [clubData, setClubData] = useState({
    club_id:0,
    uid:0,
    access_level:0
  })

  const { token } = useParams();

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  const { isAuthenticated, error } = auth;

  const { password, confirmPassword, email, name } = form;
  const [errors, setErrors] = useState();
  const [isShow, setIsShow] = useState();
  const [lango, setLango] = useContext(LangContext);

  const history = useHistory();

  const redirect = location.search ? location.search.split("=")[1] : "/";

  useEffect(() => {
    const getInvitedUser = async () => {
      try {
        const invitedUser = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/v1/urlactions/${token}`
        );
        setForm({ ...form, email: JSON.parse(invitedUser.data.data).emailto });
        setClubData({...clubData, club_id: JSON.parse(invitedUser.data.data).clubid})
      } catch (error) {
        setErrors(error.response.data);
      }
    };
    getInvitedUser();
  }, []);

  if (isAuthenticated) {
    history.push("/club");
  }

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const user = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/auth/signup`,
        form
      );
      const uid = user.data.uid
      const data = {
        club_id: clubData.club_id,
        uid,
        access_level: 0
      }

      const clubMember = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/club/member`, data)
      history.push('/')
    } catch (error) {
      setErrors(error.response.data)
    }
  };
  const onChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };
  const closeAlert = () => {
    dispatch(loginRequest());
  };

  const showLanguage = (e) => {
    e.preventDefault();
    setIsShow(!isShow);
  };

  const getLanguage = (e, lng) => {
    e.preventDefault();
    i18n.changeLanguage(lng);
    setLango({ title: e.target.innerText, flag: lng });
    setIsShow(!isShow);
  };

  return (
    <div className="auth-fluid">
      <div className="auth-fluid-form-box">
        <div className="align-items-center d-flex h-100">
          <div className="card-body">
            <div className="auth-brand text-center text-lg-left">
              <Link to="/" className="logo-dark">
                <span>
                  <img src={LogoLogin} alt="" width="320" />
                </span>
              </Link>
            </div>
            <h4 className="mt-0 text-capitalize">{t("User Registration")}</h4>

            <form onSubmit={onSubmit} style={{ clear: "both" }}>
              <div className="form-group">
                <label htmlFor="name">{t("Name")}</label>
                <input
                  onChange={onChange}
                  className="form-control"
                  type="text"
                  name="name"
                  value={name}
                  id="name"
                />
              </div>
              <div className="form-group">
                <label htmlFor="password">{t("Email")}</label>
                <input
                  onChange={onChange}
                  className="form-control"
                  type="text"
                  name="email"
                  value={email}
                  id="email"
                  disabled
                />
              </div>
              <div className="form-group">
                <label htmlFor="password">{t("Password")}</label>
                <input
                  onChange={onChange}
                  className="form-control"
                  type="password"
                  name="password"
                  value={password}
                  id="password"
                  required=""
                  placeholder={t("Enter your password")}
                />
              </div>
              <div className="form-group">
                <label htmlFor="confirmPassword">{t("Confirm Password")}</label>
                <input
                  onChange={onChange}
                  value={confirmPassword}
                  className="form-control"
                  type="password"
                  name="confirmPassword"
                  required=""
                  id="confirmPassword"
                  placeholder={t("Confirm your new password")}
                />
              </div>
              {errors && (
                <div
                  className="alert alert-dismissable fade show alert-danger"
                  role="alert"
                  style={{paddingLeft:0, paddingBottom:0}}
                >
                  <button
                    type="button"
                    onClick={closeAlert}
                    class="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                  <ul>
                  {errors.length > 0 && errors.map(error => (
                    <li>{error.message}</li>
                  ))}
                  </ul>
                </div>
              )}

              <div className="form-group mb-0 text-center">
                <button
                  className="btn btn-primary btn-block"
                  name="op"
                  value="Login"
                  type="submit"
                  style={{ marginTop: "20px" }}
                >
                  <i className="fa fa-sign-in"></i> {t("Register")}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserRegistration;
